<template>
    <div v-show="displayHeader">
        <header id="global-header-container" :aa-region="aaRegion">
            <div class="header__wrap padded-container">
                <nav class="header__nav">
                    <span v-if="isShowPickerRoute" :class="logoClassList"></span>
                    <a
                        v-else
                        :href="baseURL"
                        :class="logoClassList"
                        :aria-label="getSiteName"
                        tabindex="0"
                        :aa-link="
                            [aaRegion, '', 'cbs logo', activityMapBrandLogo] | aaLinkFilter
                        "></a>
                </nav>

                <UserEntry
                    v-if="!minimal"
                    :aaRegion="aaRegion"
                    :showSignIn="showSignIn"></UserEntry>
            </div>

            <NotificationBelowHeader></NotificationBelowHeader>
        </header>
        <NotificationBelowHeaderSpacer></NotificationBelowHeaderSpacer>
    </div>
</template>

<script>
    import NotificationBelowHeader from 'aa/vue/components/NotificationBelowHeader';
    import NotificationBelowHeaderSpacer from 'aa/vue/components/NotificationBelowHeaderSpacer';
    import UserEntry from 'aa/vue/components/UserEntry.vue';

    import { TVE_RENDEZVOUS_BASE_PATH } from 'aa/vue/constants/aaRoutes';
    import { tracking } from 'services/Tracking';

    export default {
        name: 'svod-header',

        components: {
            NotificationBelowHeader,
            NotificationBelowHeaderSpacer,
            UserEntry,
        },

        props: {
            aaRegion: {
                type: String,
            },
            logoClass: {
                type: String,
                required: true,
                default: '',
            },
            minimal: {
                type: Boolean,
                default: false,
            },
            showSignIn: {
                type: Boolean,
                default: true,
            },
        },

        data: function () {
            return {
                currentRouteName: this.$router.currentRoute.name,
                baseURL: this.$root.serverData.regionBaseUrl + '/',
            };
        },

        computed: {
            isShowPickerRoute() {
                return this.currentRouteName === 'ShowsPicker';
            },
            logoClassList() {
                return [
                    'icon',
                    this.logoClass,
                    this.useAllAccessLogo() ? 'siteLogo' : 'cbsLogoBG',
                    'qt-cbslogo',
                ];
            },
            isTVERendezvous() {
                return this.currentRouteName === TVE_RENDEZVOUS_BASE_PATH;
            },
            displayHeader() {
                return !this.$route?.meta?.hideHeader;
            },
            getSiteName() {
                return CBS.Registry.brand.name === 'PPLUS' ? 'Paramount+ Home' : 'CBS Home';
            },
            activityMapBrandLogo() {
                return CBS.Registry.brand.name == 'PPLUS'
                    ? 'paramountplus logo'
                    : 'cbs-digital logo';
            },
        },

        watch: {
            $route(to, from) {
                this.currentRouteName = to.name;
            },
        },

        filters: {
            aaLinkFilter: function (val) {
                return tracking.aaLinkFilter(val);
            },
        },

        methods: {
            useAllAccessLogo() {
                return !this.isTVERendezvous;
            },
        },
    };
</script>
