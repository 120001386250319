// handles disclaimer/disclaimer modal on aa upsell

export default class AAUpsellDisclaimer {
    constructor() {
        this.classOpenDisclaimer = 'upsell-additional-disclaimer--open';
        this.disclaimerHandle = document.getElementById('js-upsell-additional-disclaimer-handle');
        this.disclaimerModalContainer = document.querySelector('.upsell-additional-disclaimer');
        this.disclaimerModal = document.getElementById('js-upsell-disclaimer-modal');
        this.disclaimerCloseModalHandle = document.querySelector(
            '.upsell-additional-disclaimer .button__close',
        );

        if (this.disclaimerHandle) {
            this.init();
        }
    }

    init() {
        this.openModalListener();
        this.closeModalListener();
    }

    openModalListener() {
        this.disclaimerHandle.addEventListener(
            'click',
            () => {
                this.openModal();
            },
            false,
        );
    }

    closeModalListener() {
        this.disclaimerCloseModalHandle.addEventListener(
            'click',
            () => {
                this.closeModal();
            },
            false,
        );

        this.disclaimerModalContainer.addEventListener(
            'click',
            (event) => {
                if (event.target !== this.disclaimerModal) {
                    this.closeModal();
                }
            },
            false,
        );

        document.addEventListener(
            'keyup',
            (event) => {
                if (event.key === 'Escape') {
                    this.closeModal();
                }
            },
            false,
        );
    }

    openModal() {
        this.disclaimerModalContainer.classList.add(this.classOpenDisclaimer);
        this.disclaimerCloseModalHandle.focus();
    }

    closeModal() {
        this.disclaimerModalContainer.classList.remove(this.classOpenDisclaimer);
    }
}
