import Feature from 'helpers/feature';
import Breakpoints from 'helpers/breakpoints';
import { PICK_PLAN_URL } from '../vue/constants/aaRoutes';
(function () {
    class FooterAllAccess {
        constructor() {
            this.elClick = null;
            this.elSiteIndexList = null;
            this.isOpen = null;
            this.listenerOptions = Feature.supportsPassive()
                ? {
                      capture: true,
                      passive: true,
                  }
                : false;

            this.breakpoints = new Breakpoints();

            window.addEventListener('DOMContentLoaded', () => {
                this.init();
                this.setShowtimeBundlePadding();
            });

            window.addEventListener('resize', () => {
                this.setShowtimeBundlePadding();
            });
        }

        init() {
            this.elClick = document.querySelector('#js-site-trigger');
            this.elSiteIndexList = document.querySelector('#js-site-index');
            this.setupClickHandler();
            this.__closeFooter();
        }

        setShowtimeBundlePadding() {
            try {
                let footer = document.getElementsByClassName('global-footer')[0];
                let showtimeBundleFooterPaddingThreshold = this.breakpoints.getMin('desktop');

                if (footer === undefined) {
                    return;
                }

                if (
                    window.innerWidth <= showtimeBundleFooterPaddingThreshold &&
                    this.isPapShowtimeBundleUrl()
                ) {
                    footer.classList.add('showtime-bundle-footer');
                } else {
                    footer.classList.remove('showtime-bundle-footer');
                }
            } catch (e) {}
        }

        isPapShowtimeBundleUrl() {
            try {
                let currentUrl = window.location.pathname;

                if (currentUrl === PICK_PLAN_URL) {
                    return true;
                } else {
                    return false;
                }
            } catch (e) {}
        }
        setupClickHandler() {
            if (this.elClick) {
                this.elClick.addEventListener(
                    'click',
                    () => {
                        this.toggleSiteIndex();
                    },
                    this.listenerOptions,
                );
            }
        }

        toggleSiteIndex() {
            if (this.isOpen) {
                this.__closeFooter();
            } else {
                this.__openFooter();
            }
        }

        __openFooter() {
            this.isOpen = true;
            if (this.elClick) {
                this.elClick.classList.add('open');
            }
            if (this.elSiteIndexList) {
                this.elSiteIndexList.classList.add('open');
                this.elSiteIndexList.scrollIntoView({
                    behavior: 'smooth',
                    block: 'end',
                });
            }
        }

        __closeFooter() {
            this.isOpen = false;
            if (this.elClick) {
                this.elClick.classList.remove('open');
            }
            if (this.elSiteIndexList) {
                this.elSiteIndexList.classList.remove('open');
            }
        }
    }

    new FooterAllAccess();
})();
