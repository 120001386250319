import MVPDAuthService from 'aa/vue/services/MVPDAuthService';
import { getMVPDAuthZObj } from 'helpers/mvpdStorage';

export default {
    data: function () {
        return {
            userIsGhost: false,
        };
    },

    methods: {
        async bindMVPD() {
            let httpData = {
                tk_trp: this.$store.state.token,
            };

            try {
                let res = await MVPDAuthService.bindUser(httpData);

                return;
            } catch (e) {
                this.onBindFail(e);
                throw e;
            }
        },

        async unbindMVPDIfNeeded() {
            if (getMVPDAuthZObj()) {
                let needsUnbind = await this.needsUnbind();

                if (needsUnbind) {
                    let httpData = {
                        tk_trp: this.$store.state.token,
                    };

                    try {
                        let res = await MVPDAuthService.unbindUser(httpData);
                    } catch (e) {
                        this.onBindFail(e);
                        throw e;
                    }
                }
            }
        },

        async needsUnbind() {
            if (this.$store.state?.user?.isGhost) {
                return true;
            }

            let isBound = await MVPDAuthService.checkMVPDIsBound({
                tk_trp: this.$store.state.token,
            });

            return isBound;
        },

        onBindFail(e) {},
    },

    mounted() {
        MVPDAuthService.setHttpService(this.$store.$http);
        this.userIsGhost = this.$store.state?.user?.isGhost ?? false;
    },
};
