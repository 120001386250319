export default {
    namespaced: true,

    state: {
        intcid: null,
    },

    mutations: {
        setIntcid(state, intcid) {
            state.intcid = intcid;
        },
    },
};
