<template>
    <div
        v-show="isOpen"
        ref="notificationBelowHeaderSpacer"
        :class="cssClasses"
        :style="{ height: spacerHeight + 'px' }"></div>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name: 'NotificationBelowHeaderSpacer',
        methods: {
            doCollapse() {
                let headerSpacerElement = this.$refs['notificationBelowHeaderSpacer'];
                headerSpacerElement.style.height = 0;
            },
        },
        computed: {
            ...mapState('notification', {
                isClosing: (state) => state.isClosing,
                isOpen: (state) => state.isOpen,
                spacerHeight: (state) => state.spacerHeight,
            }),

            cssClasses() {
                return {
                    closing: this.isClosing,
                    'notification-below-spacer': true,
                };
            },
        },
        watch: {
            isClosing: function (newValue, oldValue) {
                if (newValue) {
                    this.doCollapse();
                }
            },
        },
    };
</script>
