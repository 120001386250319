import pageAttributeToImageSource from 'aa/vue/plugins/purchaseFunnel/PageAttributeToImageSource';
import getLocaleValue from 'services/LocalizationService';

export default {
    computed: {
        getCopy() {
            return function (key) {
                if (!this.pageAttributes?.[key]) {
                    return null;
                }

                return getLocaleValue(this.pageAttributes).apply(null, arguments);
            };
        },
    },
    methods: {
        pageAttributeToImageSource(pageAttribute) {
            return pageAttributeToImageSource(pageAttribute, this.$store.state.imageHost);
        },
    },
};
