<template>
    <h1
        :class="classes"
        class="steps-header"
        data-ci="steps-header"
        ref="pageHeading"
        :tabindex="pageHeadingTabIndex"
        @blur="removeHeadingTabIndex">
        <div class="steps-header__section" :style="{ width: hasContent ? '100%' : 'initial' }">
            <span v-if="getHeader">{{ getHeader }}</span>
            <span v-if="hasContent && shouldDisplaySteps" class="line"></span>
        </div>

        <span v-if="!hasContent" class="line"></span>

        <slot></slot>

        <div class="steps-header__section" :style="{ width: hasContent ? '100%' : 'initial' }">
            <span v-if="hasContent && shouldDisplaySteps" class="line"></span>
            <span :class="stepsClasses" v-if="shouldDisplaySteps">{{ getStepString }}</span>
        </div>
    </h1>
</template>

<script>
    import { mapState, mapGetters } from 'vuex';
    import accesabilityMixins from 'aa/vue/plugins/account/accesabilityMixins';
    import { sprintf } from 'sprintf-js';

    export default {
        name: 'steps-header',
        props: {
            hideStepsProp: {
                type: Boolean,
                default: false,
            },
            stepsHeader: {
                type: String,
                default: null,
            },
            stepsCounter: {
                type: String,
                default: null,
            },
            hasContent: {
                type: Boolean,
                default: false,
            },
            classes: {
                type: Object,
                default() {
                    return {};
                },
            },
            overrideShouldDisplaySteps: {
                type: Boolean,
                default: false,
            },
            overrideStepNumber: {
                type: Number,
                default: undefined,
            },
            overrideCountedSteps: {
                type: Number,
                default: undefined,
            },
        },
        mixins: [accesabilityMixins],
        computed: {
            shouldDisplaySteps() {
                return (
                    (this.displaySteps || this.overrideShouldDisplaySteps) && !this.hideStepsProp
                );
            },
            getHeader() {
                if (this.stepsHeader) {
                    return this.stepsHeader;
                }
                return this.pageAttributes?.aa_steps_header;
            },
            stepsClasses() {
                return 'steps-count qt-stepcount' + (this.stepNumber || '');
            },
            getStepString() {
                if (!this.stepNumber || !this.totalStepsCount) {
                    return '';
                }

                try {
                    return sprintf(
                        this.stepsCounter ?? this.pageAttributes.aa_steps_counter,
                        this.stepNumber,
                        this.totalStepsCount,
                    );
                } catch (e) {
                    return '';
                }
            },

            stepNumber() {
                if (this.overrideStepNumber) {
                    return this.overrideStepNumber;
                }

                if (this.getCurrentStep && this.getCurrentStep.countStep) {
                    // get index of current counted step
                    for (const [i, step] of this.countedSteps.entries()) {
                        if (step.stepIndex === this.getCurrentStep.stepIndex) {
                            // removed sign up from steps count and step is after sign up
                            if (
                                this.excludeSignUpFromStepsCount &&
                                step.stepIndex > this.signUpStep.stepIndex
                            ) {
                                return i;
                            }
                            return i + 1;
                        }
                    }
                }

                return false;
            },

            totalStepsCount() {
                if (this.excludeSignUpFromStepsCount) {
                    return this.countedSteps.length - 1;
                }

                return this.countedSteps?.length || this.overrideCountedSteps;
            },

            excludeSignUpFromStepsCount() {
                return this.signUpStep && this.$store.state.flow.didCompleteSignIn;
            },

            signUpStep() {
                return (
                    !this.overrideStepNumber &&
                    this.countedSteps?.find((step) => {
                        return step.name === 'SIGNUP';
                    })
                );
            },

            ...mapGetters('flow', ['displaySteps', 'countedSteps', 'getCurrentStep']),
            ...mapGetters({
                featureIsActive: 'featureFlags/isActive',
            }),
            ...mapState('pageAttributes', {
                pageAttributes: (state) => state.attributes,
            }),
        },
    };
</script>
