<template>
    <div class="reseller-landing-modal-wrapper">
        <BackgroundPicture :backgroundImageSet="backgroundImageSet"></BackgroundPicture>
        <div class="reseller-landing-modal">
            <div class="reseller-landing-content">
                <div>
                    <div class="landing-logo">
                        <img :src="landingLogo" alt="Paramount+ | Delta" />
                    </div>
                    <h1 v-html="headerText" class="header"></h1>
                    <p v-html="subtitleText" class="subtitle"></p>

                    <FeatureList :features="featureList" :plan="plan"></FeatureList>

                    <div class="cta">
                        <ButtonCta :buttonConfig="ctaConfig"></ButtonCta>
                    </div>
                </div>
            </div>
            <div class="reseller-landing-footer">
                <p v-html="disclaimerText" class="disclaimer"></p>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import BackgroundPicture from 'aa/vue/atoms/BackgroundPicture';
    import ButtonCta from 'atoms/ButtonCta';
    import ButtonConfig from 'models/ButtonConfig';
    import Breakpoints from 'helpers/breakpoints';
    import FeatureList from 'aa/vue/atoms/FeatureList';
    import { sanitizer } from 'apps/plugins/Sanitizer';
    import { tracking } from 'services/Tracking';
    import Plan from 'aa/vue/models/Plan';

    export default {
        name: 'ResellerLandingModal',

        data: function () {
            return {
                plan: new Plan(),
            };
        },

        components: {
            BackgroundPicture,
            ButtonCta,
            FeatureList,
        },

        computed: {
            landingLogo() {
                return this.pageAttributeToImageSource(this.pageAttributes.reseller_landing_logo);
            },
            backgroundImageSet() {
                return {
                    landscape: this.pageAttributeToImageSource(
                        this.pageAttributes.hero_background_desktop,
                    ),
                    portrait: this.pageAttributeToImageSource(
                        this.pageAttributes.hero_background_mobile,
                    ),
                };
            },

            ...mapState('pageAttributes', {
                pageAttributes: (state) => state.attributes,
            }),

            ...mapState('flow', {
                flow: (state) => state.config,
                oauth_login_url: (state) => state.config.oauth_login_url,
            }),

            ctaConfig() {
                return new ButtonConfig({
                    displayText: this.getCopy('cta_title'),
                    event: this.goToOAuthLogin,
                    styleClass: 'button',
                });
            },

            headerText() {
                return sanitizer(this.getCopy('aa_steps_header'));
            },

            subtitleText() {
                return sanitizer(this.getCopy('assistive_text'));
            },

            featureList() {
                return [
                    sanitizer(this.getCopy('reseller_landing_feature_item_1')),
                    this.getCopy('reseller_landing_feature_item_2'),
                    sanitizer(this.getCopy('reseller_landing_feature_item_3')),
                ];
            },

            disclaimerText() {
                return sanitizer(this.getCopy('description_text'));
            },
        },

        methods: {
            loginWindowFeatures() {
                if (window.innerWidth > Breakpoints().getMax('mobileLandscape')) {
                    let h = screen.height / 2;
                    let w = screen.width / 2;

                    return `width=${w}, height=${h}`;
                }

                return `width=auto, height=auto`;
            },

            goToOAuthLogin(e) {
                if (e) {
                    e.stopPropagation();
                    e.preventDefault();
                }

                this.trackCTAClick();

                if (typeof this.oauth_login_url === 'string') {
                    window.open(
                        this.oauth_login_url,
                        this.loginWindowName,
                        this.loginWindowFeatures(),
                    );
                }
            },

            trackCTAClick() {
                try {
                    tracking.trackAction('trackUpsellContinueButtonSelect', {
                        ctaText: this.ctaConfig.displayText,
                    });
                } catch (e) {}
            },

            trackState() {
                try {
                    tracking.trackState({
                        basePath: this.$router.options.base,
                        pageType: 'upsell_partner',
                        path: this.$route.path,
                    });
                } catch (e) {}
            },
        },

        created() {
            this.$store.commit('modal/setAdditionalClasses', 'reseller-landing');
            this.trackState();
        },
    };
</script>
