import mutations from 'aa/vue/stores/account/mutations';

export default {
    namespaced: true,
    mutations,
    actions: {
        loadServerData({ commit, rootGetters }) {
            let pageAttributes = rootGetters.getServerDataByKey('pageAttributes');
            if (pageAttributes) {
                commit('parseData', pageAttributes);
            }
        },
        mapValues({ commit }, map) {
            if (!map || typeof map !== 'object') return;

            commit('parseData', map);
        },
    },
};
