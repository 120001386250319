<template>
    <picture class="aa-background-picture" v-if="imageSource">
        <source v-if="srcsetLandscape" :media="sourceMediaLandscape" :srcset="srcsetLandscape" />
        <source v-if="srcsetPortrait" :srcset="srcsetPortrait" :key="renderKey" />
        <img :src="imageSource" />
    </picture>
</template>

<script>
    import Breakpoints from 'helpers/breakpoints';

    export default {
        name: 'BackgroundPicture',
        props: {
            backgroundImageSet: {
                type: Object,
            },
            useThumbnailer: {
                type: Boolean,
                default: () => {
                    return !window.location.host.match(/^local\..*\.com/);
                },
            },
        },

        data: function () {
            return {
                breakpoints: Breakpoints(),
                isSafariIOS: CSS.supports('-webkit-touch-callout', 'none'),
                renderKey: 1,
            };
        },

        computed: {
            imageSource() {
                const src = this.backgroundImageSet.landscape || this.backgroundImageSet.portrait;

                return this.assetPath(src, 1920);
            },

            sourceMediaLandscape() {
                return `(min-width: ${this.breakpoints.getMin('tabletLandscape')}px)`;
            },

            srcsetLandscape() {
                if (this.useThumbnailer) {
                    return this.thumbnailer(
                        this.backgroundImageSet.landscape,
                        [1024, 1440, 1920, 2880, 3200],
                    );
                }

                return this.backgroundImageSet.landscape;
            },

            srcsetPortrait() {
                if (this.useThumbnailer) {
                    return this.thumbnailer(
                        this.backgroundImageSet.portrait,
                        [750, 828, 1500, 1656],
                    );
                }

                return this.backgroundImageSet.portrait;
            },
        },

        methods: {
            assetPath(nonThumbnailSrc, size) {
                if (!nonThumbnailSrc) {
                    return null;
                }

                if (this.useThumbnailer) {
                    const thumbnailPath = `/thumbnails/photos/w${size}-q80/`;
                    const path = nonThumbnailSrc.replace(/\/base\/files\//, thumbnailPath);

                    return path;
                }

                return nonThumbnailSrc;
            },

            thumbnailer(nonThumbnailSrc, sizes) {
                if (typeof nonThumbnailSrc !== 'string') {
                    return null;
                }

                return sizes
                    .map((size) => {
                        return `${this.assetPath(nonThumbnailSrc, size)} ${size}w`;
                    })
                    .join(',');
            },

            triggerRerender() {
                this.renderKey = this.renderKey ? 0 : 1;
            },
        },

        created() {
            if (this.isSafariIOS) {
                window.addEventListener('resize', this.triggerRerender);
            }
        },

        beforeDestroy() {
            if (this.isSafariIOS) {
                window.removeEventListener('resize', this.triggerRerender);
            }
        },
    };
</script>
