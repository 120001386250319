// START TEST PLOCTOPLUS-2036 Pick a Plan: Plan Tiles Above Fold
import { mapGetters, mapState } from 'vuex';
import { tracking } from 'services/Tracking';
import Breakpoints from 'helpers/breakpoints';
export default {
    mounted() {
        this.mixinSetupBreakpointListener();
    },
    data() {
        return {
            mixinBreakpoints: new Breakpoints(),
            currentBreakpointName: null,
        };
    },
    methods: {
        mixinSetupBreakpointListener() {
            window.addEventListener('resize', this.mixinHandleResize);
            this.mixinHandleResize();
        },
        mixinHandleResize() {
            this.currentBreakpointName = this.mixinBreakpoints.getCurrentBreakpointName();
        },
        abTestReturnHollow() {
            const bp = this.currentBreakpointName;
            return (
                (bp === 'mobilePortrait' || bp === 'mobileLandscape') &&
                (this.isNativeAppView || this.isIntlLikeView) &&
                this.plan.isLCP
            );
        },
    },
    computed: {
        ...mapState('variant', {
            highlightedPlanIdx: (state) => {
                return state.highlightedPlanIdx;
            },
        }),
        ...mapGetters({
            isNativeAppView: 'variant/isPlanTilesAboveFoldNativeAppView',
            isIntlLikeView: 'variant/isPlanTilesAboveFoldIntlLikeView',
        }),
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.mixinHandleResize);
    },
};
// END TEST PLOCTOPLUS-2036 Pick a Plan: Plan Tiles Above Fold
