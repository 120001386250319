export default class AAUpsellMarketing {
    constructor() {
        this.setAAAbandonCookie();
    }

    setAAAbandonCookie() {
        try {
            let l = window.location;
            if (!$.cookie('aa-abandon-ovl') || $.cookie('aa-abandon-ovl') != 'done') {
                $.cookie('aa-abandon-ovl', l['pathname'] + l['search'], {
                    path: '/',
                });
            }
        } catch (e) {}
    }
}
