import ResetPasswordComponent from 'aa/vue/components/ResetPassword';
import Step from 'models/steps/Step';

export default class GlobalResetPassword extends Step {
    path = '/global/resetpassword/:token/';
    name = 'RESET_PASSWORD';
    component = ResetPasswordComponent;
    requireAuth = false;
    requireNonAuth = false;
    requireSub = true;
}
