export default {
    namespaced: true,
    state: {
        programId: '',
        verificationId: '',
    },
    getters: {
        programId(state) {
            return state.programId;
        },
        verificationId(state) {
            return state.verificationId;
        },
    },
    mutations: {
        setProgramId(state, programId) {
            state.programId = programId;
        },
        setVerificationId(state, verificationId) {
            state.verificationId = verificationId;
        },
    },
    actions: {
        setProgramId(context, payload) {
            context.commit('setProgramId', payload);
        },
        setVerificationId(context, payload) {
            context.commit('setVerificationId', payload);
        },
    },
};
