import Vue from 'vue';
import VueRouter from 'vue-router';
import Vuex from 'vuex';
import 'es6-promise/auto';

import Store from 'aa/vue/stores/Store';
import HttpService from 'services/HttpService';
import localeBundlePlugin from 'plugins/localeBundle';
import { getBundleFromJSON, getLocale } from 'services/LocalizationService';

const UPSELL_APP_SELECTOR = '#app';
import { getAppConfig } from 'aa/vue/App';
import UpsellPlans from 'aa/vue/components/upsell/UpsellPlans';
import { ValueProp } from 'aa/vue/models/upsell/ValueProp';
import { LAST } from 'aa/vue/constants/shared';
import Product from 'aa/vue/models/Product';

export default class AAUpsellPlansApp {
    constructor() {
        window.addEventListener('DOMContentLoaded', () => {
            if (this.hasUpsellPlansBucket()) {
                this.init();
            }
        });
    }

    configValueProps(pageAttributes) {
        let valueProps = pageAttributes.upsell_value_prop ?? [];

        // sort value props by position
        this.sortValueProps(valueProps);

        // adding new value prop for price only
        valueProps.push(new ValueProp(this.getPriceValueProp()));

        return pageAttributes;
    }

    sortValueProps(valueProps) {
        valueProps.sort((a, b) => a.position - b.position);
        return valueProps;
    }

    getPriceValueProp() {
        return {
            value_prop: getLocale('price'),
            position: LAST,
        };
    }

    init() {
        // config vue
        Vue.config.productionTip = false;
        Vue.use(VueRouter);
        Vue.use(Vuex);
        Vue.use(localeBundlePlugin);

        // store
        const store = Store();
        store.$http = new HttpService();

        // preload store with data
        let serverData = getAppConfig();

        //locale
        const localeBundle = getBundleFromJSON();

        // set store values
        store.commit('setLocaleDictionary', localeBundle);
        store.commit('setFeatureFlags', serverData?.featureFlag);
        store.commit('user/parseUserData', serverData?.user);
        store.dispatch('payment/insertRecurlyScript', serverData);
        store.dispatch('plan/setPlans', serverData?.plans);
        store.dispatch('flow/loadStore', serverData?.flow);
        store.dispatch('plan/loadStore', {
            planType: Product.TYPE_MONTHLY,
            plans: serverData?.plans,
        });

        // sort upsell value props
        const pageAttributes = this.configValueProps(serverData.pageAttributes);
        store.commit('pageAttributes/setPageAttr', pageAttributes);

        // save cms campaign promo to store
        // fallback to query string promo
        // fallback to cookie promo
        let promo = null;
        const queryParams = new URLSearchParams(window.location.search);
        if (serverData?.promo) {
            promo = serverData?.promo;
        } else if (queryParams.has('promo')) {
            promo = queryParams.get('promo');
        } else {
            promo = this.getPromoFromCookie();
        }

        promo = promo ? encodeURIComponent(promo) : null;
        store.dispatch('updateCoupon', promo);

        //load a/b test variant from xhr
        store.commit('variant/setIsAA');
        store.dispatch('variant/loadVariantsXhr', CBS.AbTestsConfigs?.XhrABTests).then((res) => {
            if (res?.success && res?.variants) {
                this.setupAbTestsForPlansOnUpsell(res.variants, store);
            }
        });

        // mount app
        const app = new Vue({
            name: 'UpsellPlansApp',
            store,
            data: {
                serverData: serverData,
                debug: false,
            },
            components: {
                UpsellPlans,
            },
        }).$mount(UPSELL_APP_SELECTOR);
    }

    hasUpsellPlansBucket() {
        try {
            return document.querySelector(UPSELL_APP_SELECTOR) !== null;
        } catch (e) {
            console.warn('Failed to Validate hasUpsellPlansBucket: ', e);
            return false;
        }
    }

    getPromoFromCookie() {
        const cookie = CBS.CombinedCookie.get('graph', 'sv_campaign');
        return cookie?.promo ?? null;
    }

    setupAbTestsForPlansOnUpsell(variants, store) {
        // We are keeping this method as is for future a/b test checks
        // Added Below sample code how we should write a/b test checks here
        /*if (
            variants?.web_annual_plans_display_plans_on_upsell === 'annualplan' &&
            !store.state.promo
        ) {
            store.commit('featureFlags/setAnnualToggle', true);
        }*/
        return;
    }
}
