export const SHOWTIME_PRODUCT_CODE_REGEX = '(PPLUS_LCP|AA_CF)_SHO(_TRIAL)?_(ANNUAL|MONTHLY)';
export const CATAN_PRODUCT_CODE_REGEX = 'PPLUS_(LCP|CF)_CAT_(TRIAL|NOTRIAL)_(ANNUAL|MONTHLY)';

export function catanAddOnCode() {
    return CBS.Registry.catan_addon_code || 'CAT';
}

export function catanFlowType() {
    return `${catanAddOnCode().toLowerCase()}_bundle`;
}
