import FlowModel, { FLOW_TYPE } from 'models/FlowModel';

const CHECKOUT_OPTIONS_KEY = 'pplus-checkout-options';

export default class CheckoutOptions {
    constructor(options) {
        this.addonShowtime = Boolean(options?.addonShowtime);
        this.userID = options?.userID || CBS.Registry.user?.id;
        this.flowType = options?.flowType || FlowModel.getTypeFromCookie();
        this.selectedPlan = options?.selectedPlan || null;
    }

    save(options) {
        if (this.userID && options?.userID && this.userID !== options?.userID) {
            Object.assign(this, new CheckoutOptions());
        }

        if (options && typeof options === 'object') {
            const keys = Object.keys(this);

            keys.forEach((key) => {
                if (options.hasOwnProperty(key)) {
                    this[key] = options[key];
                }
            });
        }

        localStorage.setItem(CHECKOUT_OPTIONS_KEY, JSON.stringify(this));
    }

    static clear() {
        localStorage.removeItem(CHECKOUT_OPTIONS_KEY);
    }

    /**
     * @param userID
     * @returns {boolean}
     */
    static validateUserOptions(userID) {
        let options = JSON.parse(localStorage.getItem(CHECKOUT_OPTIONS_KEY));

        if (!options) {
            return true;
        }

        if (options.userID && options.userID !== userID) {
            CheckoutOptions.clear();
            return false;
        }

        return true;
    }

    /**
     * @returns {boolean}
     */
    static isStale(options) {
        try {
            // different flows/campaigns have different options
            return options.flowType !== FlowModel.getTypeFromCookie();
        } catch (e) {}

        return false;
    }

    /**
     * @returns {CheckoutOptions}
     */
    static get() {
        let options = JSON.parse(localStorage.getItem(CHECKOUT_OPTIONS_KEY));

        if (options) {
            if (CheckoutOptions.isStale(options)) {
                CheckoutOptions.clear();
                options = {};
            } else if (!CBS.Registry.Features.showtime_bundle) {
                options.addonShowtime = false;
            }
        }

        return new CheckoutOptions(options);
    }
}
