<template>
    <div class="success-create" aa-region="live tv">
        <div class="center-column">
            <span class="large-copy">{{ headline }}</span>
            <span class="medium-copy">{{ subheadline }}</span>
            <div class="logo" v-if="!isPartnerSubscription">
                <img :src="provider.logoUrl" :alt="altLogo(provider.name)" />
            </div>
            <div class="instructional-copy">
                <p v-if="!isPartnerSubscription">
                    {{ $getLocale('to_enjoy_brand_you_must', { brand: 'Paramount+' }) }}
                </p>
                <p>{{ $getLocale('theres_just_one_more_step') }}</p>
            </div>
            <ButtonCta :buttonConfig="createAccountConfig" />
            <div class="alternative-flow">
                <span class="deci">{{ $getLocale('already_have_an_account') }}</span>
                <ButtonCta :buttonConfig="bindAccountConfig" />
            </div>
        </div>
    </div>
</template>

<script>
    import ButtonCta from 'atoms/ButtonCta';
    import { ACTION_NAMES, tracking } from 'services/Tracking';

    export default {
        name: 'SuccessConfirmation',
        components: {
            ButtonCta,
        },
        props: {
            provider: {
                type: Object,
            },
            createAccountCallback: {
                type: Function,
            },
            bindAccountCallback: {
                type: Function,
            },
            isPartnerSubscription: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            altLogo: () => (providerName) => {
                return `logo ${providerName}`;
            },
            createAccountConfig() {
                return {
                    styleClass: ['button', 'primary'],
                    displayText: this.$getLocale('create_an_account'),
                    event: () => {
                        tracking.trackAction(ACTION_NAMES.CONFIRMATION_SIGNUP, {
                            clickLabel: this.$getLocale('create_an_account'),
                        });
                        this.createAccountCallback(this);
                    },
                };
            },
            bindAccountConfig() {
                return {
                    styleClass: ['button', 'secondary'],
                    displayText: this.$getLocale('sign_in'),
                    event: () => {
                        tracking.trackAction(ACTION_NAMES.CONFIRMATION_SIGNIN, {
                            clickLabel: this.$getLocale('sign_in'),
                        });
                        this.bindAccountCallback(this);
                    },
                };
            },
            partnerName() {
                return this.$parent.partner.partnerAuthAttributes.csp_display_name;
            },
            headline() {
                return this.isPartnerSubscription
                    ? this.$getLocale('partner_subscription_headline')
                    : this.$getLocale('youre_in');
            },
            subheadline() {
                return this.isPartnerSubscription
                    ? this.$getLocale('partner_subscription_subheadline', {
                          partner: this.partnerName,
                      })
                    : this.$getLocale('currently_connected');
            },
        },
    };
</script>
