import NotificationBarModel from 'aa/vue/models/NotificationBarModel';

export default {
    methods: {
        openNotification(nbm, redirect) {
            this.checkNotificationData(nbm);

            this.$store.dispatch('notification/open', nbm);

            if (redirect) {
                this.$router.push(redirect);
            }
        },

        /**
         * corresponds to NotificationBar.doHide
         */
        closeNotification() {
            this.$store.dispatch('notification/close');
        },

        /**
         * for gift flow purchase funnel
         */
        notifyGiftPurchaseError(redirect) {
            let pageAttr = this.$store.state.pageAttributes.attributes || {};
            let message =
                pageAttr.error_message_gift_purchase || 'Something went wrong. Please try again.';

            let nbm = new NotificationBarModel({
                success: false,
                message,
            });

            this.openNotification(nbm, redirect);
        },

        /**
         *
         * @returns {{data, computed, watch, methods, created, name, mounted, props}}
         */
        getNotificationBarPageAttributes() {
            let pageAttributeCollection = this.$store.state.notification;
            return pageAttributeCollection.dictionary;
        },

        checkNotificationData(obj) {
            if (typeof obj.autoClose === 'undefined') {
                console.warn(
                    'You did not pass an autoClose property. Try passing in a NotificationBarModel object.',
                );
            }

            if (typeof obj.success === 'undefined') {
                console.error(
                    'You did not pass a success property. Try passing in a NotificationBarModel object.',
                );
                return;
            }

            if (typeof obj.message === 'undefined' && typeof obj.messageKey === 'undefined') {
                console.error(
                    'You did not pass a message or messageKey property. Try passing in a NotificationBarModel object.',
                );
                return;
            }

            if (typeof obj.messageKey !== 'undefined' && obj.messageKey !== '') {
                obj.message = this.getNotificationBarPageAttributes()[obj.messageKey];
            }
        },
    },
};
