<template>
    <li
        v-if="displayAccountMenu"
        class="header__nav--withsubMenu"
        :class="{ active: openDropdown }"
        @mouseenter="showDropdown"
        @mouseleave="hideDropdown"
        @click="toggleDropdown">
        <a
            :class="accountMenuLinkClassList"
            tabindex="0"
            :aa-link="[aaRegion, 'user', 'my account'] | aaLinkFilter">
            {{ displayName }}
        </a>

        <ul class="header__subnav content">
            <li>
                <a
                    class="help"
                    :href="pageAttr.ap_help_faq_url"
                    target="_blank"
                    rel="noopener"
                    data-test="help"
                    tabindex="0"
                    :aa-link="[aaRegion, 'user', 'help'] | aaLinkFilter">
                    {{ $getLocale('help') }}
                </a>
            </li>
            <li>
                <a
                    class="js-user-logout"
                    tabindex="0"
                    @click="logout"
                    @keydown.enter.prevent="logout"
                    data-test="signout"
                    :aa-link="[aaRegion, 'user', 'sign out'] | aaLinkFilter">
                    {{ $getLocale('sign_out') }}
                </a>
            </li>
        </ul>
    </li>
    <li v-else-if="isShowPickerRoute">
        <span>{{ displayName }}</span>
    </li>
</template>

<script>
    import { mapState } from 'vuex';
    import UserMenu from 'components/userMenu';
    import { tracking } from 'services/Tracking';
    import { doLogout } from 'aa/vue/components/account/helpers';
    import { RESELLER_CONSENT } from 'aa/vue/constants/shared';

    export default {
        name: 'UserDropDown',
        props: {
            aaRegion: {
                type: String,
            },
        },
        data: function () {
            return {
                currentRouteName: this.$router.currentRoute.name,
                openDropdown: false,
            };
        },
        computed: {
            isTouchDevice() {
                return (
                    'ontouchstart' in window ||
                    navigator.maxTouchPoints > 0 ||
                    navigator.msMaxTouchPoints > 0
                );
            },
            accountMenuLinkClassList() {
                return ['filter', this.isPaymentPage ? 'mpayment' : ''];
            },
            isShowPickerRoute() {
                return this.currentRouteName === 'ShowsPicker';
            },
            isPaymentPage() {
                const paymentRoutes = ['PAYMENT', 'PAYMENT_SUMMARY'];
                return paymentRoutes.includes(this.currentRouteName);
            },
            displayAccountMenu() {
                return this.isLoggedIn && !this.isSignInPage;
            },
            isSignInPage() {
                return this.currentRouteName === 'SIGNIN';
            },

            ...mapState({
                authSuiteLoaded: (state) => state['authSuite']?.isLoaded,
                authorizationService: (state) =>
                    state['authSuite']?.authSuiteServices.authorizationService,
            }),
            ...mapState('pageAttributes', {
                pageAttr: (state) => state.attributes,
            }),
            ...mapState('user', {
                displayName: (state) => state.displayName,
                isLoggedIn: (state) => state.isLoggedIn,
            }),
        },
        watch: {
            $route(to, from) {
                this.currentRouteName = to.name;
            },
        },
        filters: {
            aaLinkFilter: function (val) {
                return tracking.aaLinkFilter(val);
            },
        },
        methods: {
            toggleDropdown() {
                this.openDropdown = !this.openDropdown;
            },
            showDropdown() {
                if (!this.isTouchDevice) {
                    this.openDropdown = true;
                }
            },
            hideDropdown() {
                this.openDropdown = false;
            },
            logout() {
                const flowBaseURL = this.$store.getters['flow/baseURL'];
                if (
                    this.$store.getters['flow/isResellerWalmart'] &&
                    this.$route?.name === RESELLER_CONSENT &&
                    flowBaseURL
                ) {
                    const code = this.$route?.query?.code ?? '';
                    doLogout(flowBaseURL, code);
                    return;
                }
                let userMenu = new UserMenu();
                if (this.authSuiteLoaded) {
                    userMenu.logoutWithAuthSuite(this.authorizationService);
                } else {
                    userMenu.logout();
                }
            },
        },
    };
</script>
