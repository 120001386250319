import SignInDefault from 'aa/vue/models/steps/SignIn';
import SignInComponent from 'aa/vue/components/SignIn';
import { ACTION_NAMES, tracking } from 'services/Tracking';

export default class SignIn extends SignInDefault {
    component = SignInComponent;
    props = {
        forgotPassCallback: (component, e) => {
            tracking.trackAction(ACTION_NAMES.SIGN_IN_FORGOT_PASSWORD);
            component.$router.push({ name: 'FORGOT_PASSWORD' });
        },
    };
}
