<template>
    <BaseModal v-if="showModal" :display-close-button="displayCloseButtonState">
        <component :is="currentModal" :data="currentModalData"></component>
    </BaseModal>
</template>

<script>
    import { mapState } from 'vuex';
    import BaseModal from 'atoms/BaseModal';
    import CancelSubModal from 'aa/vue/components/account/pages/accountMain/sections/parts/CancelSubModal';
    import ExitModal from 'aa/vue/components/account/pages/accountMain/sections/parts/ExitModal';
    import CancelTermsModal from 'aa/vue/components/account/pages/accountMain/sections/parts/CancelTermsModal';
    import SwitchToAnnualPromptModal from 'aa/vue/components/account/pages/accountSwitchPlan/parts/SwitchToAnnualPromptModal';
    import LegalOverlayModal from 'aa/vue/components/multisubplanpicker/LegalOverlayModal';
    import ResellerLandingModal from 'aa/vue/components/reseller/ResellerLandingModal';
    import DMADispute from 'aa/vue/components/DMADispute';
    import SwitchPlanPromptModal from 'aa/vue/components/multisubplanpicker/parts/SwitchPlanPromptModal';
    import BillingPlanLegalOverlayModal from 'aa/vue/components/BillingPlanLegalOverlayModal';

    export default {
        name: 'BaseModalWrapper',
        props: {
            displayCloseButton: {
                type: Boolean,
                default: true,
            },
        },
        mounted() {
            this.$store.dispatch('modal/setDisplayCloseButton', this.displayCloseButton);
        },
        computed: {
            ...mapState('modal', {
                currentModal: (state) => state.currentModal,
                showModal: (state) => state.isShown,
                currentModalData: (state) => state.currentModalData,
                displayCloseButtonState: (state) => state.displayCloseButton,
            }),
        },
        components: {
            BaseModal,
            CancelSubModal,
            CancelTermsModal,
            SwitchToAnnualPromptModal,
            ExitModal,
            ResellerLandingModal,
            DMADispute,
            SwitchPlanPromptModal,
            LegalOverlayModal,
            BillingPlanLegalOverlayModal,
        },
    };
</script>
