<template>
    <div class="BaseAccount">
        <pre v-text="$attrs" />
    </div>
</template>

<script>
    import commonMixins from 'aa/vue/plugins/account/commonMixins';

    export default {
        name: 'BaseAccount',
        mixins: [commonMixins],
    };
</script>
