import SignInComponent from 'aa/vue/components/SignIn';
import Step from 'models/steps/Step';

export default class SignInCBSSports extends Step {
    path = '/signin/oauth';
    name = 'SIGNIN_CBSSPORTS';
    component = SignInComponent;
    requireAuth = false;
    requireNonAuth = true;
    props = { oAuth: true };
}
