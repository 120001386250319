<template>
    <div class="toggle-btn-wrapper">
        <div class="toggle-btn-label" v-html="toggleLabelHTML"></div>
        <div class="toggle-btn-group">
            <button
                @click="togglePlanType"
                :class="{
                    'toggle-btn': true,
                    left: true,
                    active: activeSelected === toggleLeftLabel,
                }"
                :aria-pressed="activeSelected === toggleLeftLabel">
                <span>
                    {{ toggleLeftLabel }}
                </span>
            </button>
            <button
                @click="togglePlanType"
                :class="{
                    'toggle-btn': true,
                    right: true,
                    active: activeSelected === toggleRightLabel,
                }"
                :aria-pressed="activeSelected === toggleRightLabel">
                <span>
                    {{ toggleRightLabel }}
                </span>
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FormToggleButton',
        props: {
            // default active item in toggle
            defaultActive: {
                type: String,
                required: true,
            },
            // label displayed for toggle element
            toggleLabelHTML: {
                type: String,
                required: true,
            },
            // label displayed on left toggle button
            toggleLeftLabel: {
                type: String,
                required: true,
            },
            // label displayed on right toggle button
            toggleRightLabel: {
                type: String,
                required: true,
            },
        },
        data: function () {
            return {
                // set local variable to update upon toggle switch
                activeSelected: this.defaultActive,
            };
        },
        methods: {
            togglePlanType: function () {
                // toggle button to alternate active state (either left or right)
                if (this.activeSelected === this.toggleLeftLabel) {
                    this.activeSelected = this.toggleRightLabel;
                } else {
                    this.activeSelected = this.toggleLeftLabel;
                }

                // emit toggle switch change for event handling
                this.$emit('change', {
                    // returns current active item
                    value: this.activeSelected,
                });
            },
        },
    };
</script>
