<template>
    <div
        v-once
        class="disclaimer grid-item span-cols"
        v-if="userAcknowledgement()"
        v-html="userAcknowledgement()"></div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex';
    export default {
        name: 'UserAcknowledgement',
        props: {
            component: {
                type: String,
                default: 'signin',
            },
        },
        computed: {
            ...mapGetters('flow', {
                isUserAcknowledgementEnabled: 'isUserAcknowledgementEnabled',
                flowType: 'type',
            }),
            acknowledgementEnabledAndHasText: function () {
                if (
                    this.isUserAcknowledgementEnabled &&
                    this.$getLocale(this.acknowledgementKey()) !== this.acknowledgementKey()
                ) {
                    return true;
                }
                return false;
            },
        },
        methods: {
            nameAndType: function () {
                return this.component + '_' + this.flowType;
            },
            acknowledgementKey: function () {
                return 'aa_partner_acknowledgement_' + this.nameAndType();
            },
            ackInfoKey: function () {
                return 'aa_partner_ack_info_' + this.nameAndType();
            },
            userAcknowledgement: function () {
                if (this.flowType && this.acknowledgementEnabledAndHasText) {
                    let copyText = this.$getLocale(this.acknowledgementKey());
                    if (copyText !== this.acknowledgementKey()) {
                        return this.getReplacedText();
                    }
                }
                return '';
            },
            getReplacedText: function () {
                let acknowledgement = this.$getLocale(this.acknowledgementKey());
                let keysInfo = this.$getLocale(this.ackInfoKey());
                if (keysInfo && keysInfo !== this.ackInfoKey()) {
                    /*Sample Response Value Acknowledgement Info ackInfoKey
                     * [{"link0":"","text0":""},{"link1":"","text1":""}]
                     */
                    let parseJson = JSON.parse(keysInfo);
                    if (parseJson && typeof parseJson === 'object') {
                        for (let key in parseJson) {
                            let link = 'link' + key;
                            let text = 'text' + key;
                            if (
                                parseJson[key].hasOwnProperty(link) &&
                                parseJson[key].hasOwnProperty(text)
                            ) {
                                acknowledgement = acknowledgement.replaceAll(
                                    `{${link}}{${text}}`,
                                    this.getHref(parseJson[key][link], parseJson[key][text]),
                                );
                            }
                        }
                    }
                }
                return acknowledgement;
            },
            getHref: function (link, text) {
                return `<a href=\"${link}\" target=\"_blank\" >${text}</a>`;
            },
        },
        created() {
            if (this.userAcknowledgement()) {
                this.$store.commit('flow/setUserConsent', true);
            }
        },
    };
</script>
