import Breakpoints from 'helpers/breakpoints';

export default class AAUpsellThresholds {
    constructor() {
        this.breakpoints = new Breakpoints();
        this.thresholds = {
            mobilePortrait: 20,
            mobileLandscape: 20,
            tabletPortrait: 35,
            tabletLandscape: 35,
            desktop: 50,
            desktopWide: 50,
        };
    }

    getThreshold() {
        return this.thresholds[this.breakpoints.getCurrentBreakpointName()];
    }
}
