<template>
    <div :class="getClassName">
        <span>{{ getText }}</span>
    </div>
</template>

<script>
    export default {
        name: 'PillComponent',
        props: {
            className: {
                type: String,
            },
            textValue: {
                type: String,
                default: 'Pill Component',
            },
        },
        computed: {
            getText() {
                return this.textValue;
            },
            getClassName() {
                return ['generic-pill', this.className];
            },
        },
        methods: {},
    };
</script>
