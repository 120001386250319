// START TEST PLOCTOPLUS-2035: Simplified Plans Tiles for POPI
import { mapGetters } from 'vuex';

export default {
    data: function () {
        return {
            lcpFeaturesList: [
                'Tens of thousands of episodes & movies',
                'NFL on CBS & UEFA Champions League',
                'Limited ads',
                '24/7 live news with CBS News',
            ],
            cpFeaturesList: [
                'Tens of thousands of episodes & movies',
                'NFL on CBS & UEFA Champions League',
                '24/7 live news with CBS News',
                'No ads except live TV & a few shows',
                'SHOWTIME originals, movies & sports',
                'Live TV with CBS & college football',
                'Download shows to your mobile device',
            ],
        };
    },
    computed: {
        // For PickAPlan.vue PickAPlanBox.vue PillComponent.vue
        ...mapGetters({
            isSimplifiedPlansPOPI: 'variant/isSimplifiedPlansPOPI',
            plansWithValueProps: 'variant/plansWithValueProps',
            plansWithNoProps: 'variant/plansWithNoProps',
        }),

        planPriceValue() {
            const price = this.plan.price.toLowerCase();
            const res = price.split('/');
            return res[0];
        },

        planPriceSuffix() {
            const price = this.plan.price.toLowerCase();
            const res = price.split('/');
            return '/' + res[1] + ' after trial';
        },

        planHeadingPrefix() {
            return this.plan.isCF ? 'No Ads*' : 'Limited Ads';
        },

        simplifiedPlansTilesList() {
            const list = [];

            if (this.isSimplifiedPlansPOPI && this.plansWithValueProps) {
                if (this.plan.isCF) {
                    this.cpFeaturesList.forEach((feature) => {
                        list.push(feature);
                    });
                } else {
                    this.lcpFeaturesList.forEach((feature) => {
                        list.push(feature);
                    });
                }

                return list;
            }
        },
    },
};
// END TEST PLOCTOPLUS-2035: Simplified Plans Tiles for POPI
