/**
 * @param pageAttribute
 * @returns {string|null}
 */
export default function pageAttributeToImageSource(pageAttribute, imageHost = '') {
    if (typeof pageAttribute !== 'string') {
        return null;
    }

    return imageHost + '/base/' + pageAttribute.replace(/^\//, '');
}
