<template>
    <div id="modal-cancel-terms" class="modal--cta">
        <h3>
            {{ pageAttribute.get('cancel_terms_header', 'Terms of Cancellation') }}
        </h3>
        <p class="modal--body" v-html="explainer"></p>
    </div>
</template>

<script>
    import { sprintf } from 'sprintf-js';
    import BaseAccount from 'aa/vue/components/account/BaseAccount/BaseAccount';
    import { ACCOUNT_URL } from 'aa/vue/constants/aaRoutes';

    export default {
        extends: BaseAccount,
        name: 'CancelTermsModal',
        computed: {
            explainer() {
                let accountLinkTag = sprintf(
                    '<a href="%s">%s</a>',
                    `${ACCOUNT_URL}`,
                    `https://${window.location.hostname}${ACCOUNT_URL}`,
                );
                let termOfUseLinkTag = sprintf(
                    '<a href="%s" target="_blank" rel="noopener">Terms of Use</a>',
                    this.$store.state.serverData.termOfUseLink,
                );
                let privaryLinkTag = sprintf(
                    '<a href="%s" target="_blank" rel="noopener">Privacy Policy</a>',
                    this.$store.state.serverData.privacyLink,
                );

                let explainerStr = this.pageAttribute.get(
                    'cancel_terms_explanation',
                    'You can cancel your subscription to Paramount+ at any time by contacting us at (888)274-5343, Monday to Sunday 8 am to midnight EST, or by logging into your account at https://www.cbs.com/all-access/account/ and clicking "cancel subscription." If you cancel your subscription, the cancellation will go into effect at the end of the then-current monthly or annual subscription period, as applicable. You will have continued access to Paramount+ for the remainder of your paid subscription period, but YOU WILL NOT RECEIVE A REFUND OF ANY FEES PAID. You must cancel your subscription before it renews for the next month or year, as applicable, to avoid paying the Fees for the next month or year, as applicable. After cancellation of your subscription, you will continue to owe any accrued amounts due under these Terms and not yet paid. You acknowledge and agree that cancellation of your subscription is your sole recourse if you have any dissatisfaction, issue or concern related to Paramount+, its Content or features or these Terms, including Fees, applicable taxes, or billing methods; the selection of Content available to you through Paramount+; these Terms, including the CBS Interactive, or any changes thereto; or any other Paramount+ policies or practices.',
                );
                explainerStr = explainerStr.replace('%ACCOUNT_LINK%', accountLinkTag);
                explainerStr = explainerStr.replace('%TOU_LINK%', termOfUseLinkTag);
                explainerStr = explainerStr.replace('%PRIVACY_POLICY_LINK%', privaryLinkTag);
                return explainerStr;
            },
        },
    };
</script>
