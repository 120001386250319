import AccountPageTrackingService from 'aa/services/AccountPageTrackingService';
import { actionName } from 'aa/services/AccountPageTrackingService';
import HttpService from 'services/HttpService';
import { AA_XHR_BASE_PATH } from 'aa/vue/constants/aaRoutes';

let http = new HttpService();

export default class Recaptcha {
    /**
     * Recaptcha constructor
     */
    constructor(siteKey, language = null) {
        this.setSiteKey(siteKey);
        this.setTrackingService();

        this.recaptchaLoaded = false;
        if (!this.checkIfRecaptchaIsLoaded()) {
            let script = document.createElement('script');
            script.id = 'recaptcha-script';
            script.src = `https://www.google.com/recaptcha/enterprise.js?render=${this.siteKey}${
                language ? `&hl=${language}` : ''
            }`;
            script.onerror = this.handleScriptError;
            document.head.appendChild(script);

            script = document.querySelector('#recaptcha-script');
            script.addEventListener('load', (e) => {
                this.recaptchaLoaded = true;
            });
        } else {
            this.recaptchaLoaded = true;
        }
    }

    /**
     * Handle recaptcha script append error
     *
     * @param err
     */
    handleScriptError(err) {
        console.error('There was an error on loading the recaptcha script.', err);
        this.recaptchaLoaded = false;
    }

    /**
     * Validate google recaptcha token
     *
     * @param token
     * @returns {Promise<unknown>}
     */
    validateToken(token) {
        let path = `${AA_XHR_BASE_PATH}recaptcha-verify/`;

        if (token.length < 2) {
            return Promise.reject('No token provided');
        }

        const httpData = {
            recaptchaToken: token,
        };

        return new Promise((resolve, reject) => {
            http.doPost(path, httpData)
                .then((response) => {
                    if (response.success) {
                        resolve(response.data);
                    } else {
                        reject(response);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    /**
     * Token debug: calls recaptcha proxy token validation endpoint.
     * Needs DEBUG=true in the url.
     *
     * @param token
     */
    shouldDebugToken(token) {
        const urlParams = new URLSearchParams(window.location.search);
        const debug = urlParams.get('CAPTCHA_DEBUG');

        if (debug === 'true') {
            this.validateToken(token)
                .then((response) => {
                    if (response.success) {
                        this.trackRecaptchaVerified();
                    }
                })
                .catch((err) => {
                    console.warn('-- gerecaptcha token validation error --', err);
                });
            return true;
        } else {
            return false;
        }
    }

    /**
     * Run google recaptcha get token
     *
     * @param configs
     */
    run(configs) {
        this.setConfig(configs);
        this.getToken()
            .then((token) => {
                // token debug helper
                if (!this.shouldDebugToken(token)) {
                    token = this.action === 'FORM_SIGN_IN' ? { value: token } : token;

                    // set token on component data (on form or formData)
                    this.setTokenToComponentData(token);

                    // call component callback method if set
                    if (typeof this.callback === 'function') {
                        if (this.callbackArguments.length > 0) {
                            this.callback(...this.callbackArguments);
                        } else {
                            this.callback();
                        }
                    }
                }
            })
            .catch((err) => {
                // handle error
                console.error('There was an error after obtaining the recaptcha token.', err);
            });
    }

    /**
     * Get the recaptcha token for a given action
     */
    getToken() {
        try {
            // check if grecaptcha is loaded
            if (this.checkIfRecaptchaIsLoaded() === false) {
                return Promise.reject('Recaptcha is not loaded.');
            }

            // track recaptcha view
            this.trackRecaptchaView();

            // get the token for the given action
            return new Promise((resolve, reject) => {
                grecaptcha.enterprise.ready(() => {
                    grecaptcha.enterprise
                        .execute(this.siteKey, { action: this.action })
                        .then((token) => {
                            // track recaptcha success
                            this.trackRecaptchaSuccess();

                            resolve(token);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            });
        } catch (error) {
            return Promise.reject(error);

            // track recaptcha failure
            this.trackRecaptchaFailure();
        }
    }

    /**
     * Set recaptcha configs
     *
     * @param configs
     */
    setConfig(configs) {
        this.configs = configs;
        this.setAction().setComponent().setComponentCallBack().setCallBackArguments();
    }

    /**
     * Set tracking service
     *
     * @returns {Recaptcha}
     */
    setTrackingService() {
        this.trackingService = new AccountPageTrackingService();
        return this;
    }

    /**
     * Set token on component data (form or formData)
     *
     * @param token
     */
    setTokenToComponentData(token) {
        // add token into form or formData
        if (this.component?.formData) {
            this.component.formData.recaptchaToken = token;
        }

        if (this.component?.form) {
            this.component.form.recaptchaToken = token;
        }

        // this means that the component is not using a form or formData, but we need to pass
        // the token to the callback so we create the formData object and assign the token to it
        if (!this.component?.form && !this.component?.formData) {
            this.component.formData = { recaptchaToken: token };
        }
    }

    /**
     * Set callback method argument(s)
     *
     * @returns {Recaptcha}
     */
    setCallBackArguments() {
        this.callbackArguments = [];
        if (this.configs?.callbackArguments) {
            this.callbackArguments = this.configs.callbackArguments;
        }

        return this;
    }

    /**
     * Set caller component
     *
     * @returns {Recaptcha}
     */
    setComponent() {
        this.component = null;
        if (this.configs?.component) {
            this.component = this.configs.component;
        }

        return this;
    }

    /**
     * Set component callback method
     *
     * @returns {Recaptcha}
     */
    setComponentCallBack() {
        this.componentCallBack = false;
        if (this.configs?.componentCallBack) {
            this.callback = this.configs.componentCallBack;
        }

        return this;
    }

    /**
     * Set site key
     *
     * @param siteKey string
     * @returns {Recaptcha}
     */
    setSiteKey(siteKey) {
        this.siteKey = siteKey;
        return this;
    }

    /**
     * Set action
     *
     * @returns {Recaptcha}
     */
    setAction() {
        this.action = 'UNSPECIFIED-ACTION';
        if (this.configs?.action) {
            this.action = this.configs.action.toUpperCase().trim();
        }

        return this;
    }

    /**
     * Check if grecaptcha is loaded
     *
     * @throws Error if grecaptcha is not loaded
     */
    checkIfRecaptchaIsLoaded() {
        const ownProp = Object.prototype.hasOwnProperty;
        let ret =
            ownProp.call(window, 'grecaptcha') &&
            ownProp.call(window.grecaptcha, 'enterprise') &&
            ownProp.call(window.grecaptcha.enterprise, 'render');
        return ret;
    }

    /**
     * Track open modal action
     */
    trackRecaptchaView() {
        const action = actionName.RECAPTCHA_VIEW;
        this.trackingService.trackAction(action, {
            captchaEventView: 1,
        });
    }

    /**
     * Track checkbox click action
     */
    trackRecaptchaVerified() {
        const action = actionName.RECAPTCHA_VERIFY;
        this.trackingService.trackAction(action, {
            captchaEventVerify: 1,
        });
    }

    /**
     * Track recaptcha validation success action
     */
    trackRecaptchaSuccess() {
        const action = actionName.RECAPTCHA_SUCCESS;
        this.trackingService.trackAction(action, {
            captchaEventSuccess: 1,
        });
    }

    /**
     * Track recaptcha validation failure action
     */
    trackRecaptchaFailure() {
        const action = actionName.RECAPTCHA_FAILURE;
        this.trackingService.trackAction(action, {
            captchaEventFail: 1,
        });
    }
}
