export default {
    data() {
        return {
            threeDModalOpen: false,
        };
    },
    methods: {
        openThreeDModal: function () {
            if (window) {
                this.bodyScrollPos = window.pageYOffset;
            }
            if (document) {
                document.querySelector('body').style.position = 'fixed';
                document.querySelector('body').style.top = -1 * this.bodyScrollPos + 'px';
            }

            this.threeDModalOpen = true;
        },
        closeThreeDModal: function () {
            if (document) {
                document.querySelector('body').style.position = '';
                document.querySelector('body').style.top = '';
            }

            this.threeDModalOpen = false;
        },
        openModal(modalName, data = {}) {
            this.$store.dispatch('modal/setModal', { modalName, data });
        },
    },
};
