export default class Duration {
    constructor(amount = null, unit = null, convertUnits = false) {
        this.amount = amount;
        this.unit = unit?.toLowerCase().trim().replace(/s$/, '');

        if (convertUnits) {
            this.convertUnits();
        }
    }

    toCopyParts(plural, ucFirst) {
        let unit = this.unit;

        if (plural && this.amount > 1) {
            unit += 's';
        }

        if (ucFirst) {
            unit = unit?.charAt(0).toUpperCase() + unit?.substring(1);
        }

        return {
            amount: this.amount,
            unit,
        };
    }

    /**
     * @param locale
     * @returns {string}
     */
    toLocaleString(locale) {
        if (!this.unit) {
            return '';
        }

        return new Intl.NumberFormat(locale, {
            style: 'unit',
            unit: this.unit,
            unitDisplay: 'long',
        }).format(this.amount);
    }

    convertUnits() {
        if (!this.amount || !this.unit) {
            return;
        }

        if (this.unit === 'day' && this.amount >= 7 && this.amount % 7 === 0) {
            this.amount /= 7;
            this.unit = 'week';
        }

        if (this.unit === 'month' && this.amount >= 12 && this.amount % 12 === 0) {
            this.amount /= 12;
            this.unit = 'year';
        }
    }
}
