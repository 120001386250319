import { debouncedScroll } from 'helpers/bindScroll';

export default class AAUpsellHeroBanner {
    constructor() {
        this.classHide = 'hide';
        this.classShow = 'show';
        this.classFirstLoad = 'first-load';
        this.isFirstLoad = true;
        this.container = document.querySelector('.hero__banner');

        if (this.container) {
            this.setupScrollListener();
            this.wait();
        }
    }

    wait() {
        document.addEventListener('DOMContentLoaded', () => {
            setTimeout(() => {
                this.animate();
            }, 200);
        });
    }

    firstLoadOnly() {
        const bannerTextShowName = this.container?.querySelector('.show-name');
        const bannerTextPrefix = this.container?.querySelector('.prefix');

        const showNameTextContent = bannerTextShowName?.textContent.trim();
        const prefixTextContent = bannerTextPrefix?.textContent.trim();

        if (this.container && (showNameTextContent || prefixTextContent)) {
            this.container.classList.add(this.classFirstLoad);
        }
    }

    setupScrollListener() {
        debouncedScroll(() => this.animate(), {
            capture: true,
            passive: true,
        });
    }

    animate() {
        let scrollPos = window.pageYOffset;

        if (this.isFirstLoad && scrollPos === 0) {
            this.firstLoadOnly();
        } else {
            if (scrollPos === 0) {
                this.show();
            } else {
                this.hide();
            }
        }

        this.isFirstLoad = false;
    }

    show() {
        if (this.container && this.container.classList.contains(this.classHide)) {
            this.container.classList.remove(this.classHide);
            this.container.classList.add(this.classShow);
        }
    }

    hide() {
        if (this.container && !this.container.classList.contains(this.classHide)) {
            this.container.classList.remove(this.classShow);
            this.container.classList.remove(this.classFirstLoad);
            this.container.classList.add(this.classHide);
        }
    }
}
