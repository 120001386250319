import PartnerAuthenticator from 'aa/vue/components/partner/PartnerAuthenticator';
import Step from 'models/steps/Step';
import PartnerService from 'services/PartnerService';

const createAccountCallback = (componentRef) => {
    componentRef.$router.push({
        name: 'SIGNUP',
        params: { fromPartnerConfirmation: true },
    });
};
const bindAccountCallback = (componentRef) => {
    componentRef.$router.push({
        name: 'SIGNIN',
        params: { fromPartnerConfirmation: true },
    });
};

export default class Partner extends Step {
    path = '/partner-auth/:partner';
    name = 'PartnerAuthenticator';
    pathToRegexpOptions = { strict: true };
    component = PartnerAuthenticator;
    props = {
        isValidRedirect: true,
        createAccountCallback: createAccountCallback,
        bindAccountCallback: bindAccountCallback,
    };

    // TODO: Get partner flow name from url csp value / page attribtues
    async beforeEnter(to, from, next) {
        const { params } = to;
        const partnerCSPValue = params?.partner;
        this._store.commit('partner/setPartnerCSPValue', partnerCSPValue);
        PartnerService.setPartnerFlow(partnerCSPValue);

        const isEmpty = (obj) =>
            [Object, Array].includes((obj || {}).constructor) && !Object.entries(obj || {}).length;

        this.props.isValidRedirect = !isEmpty(to.query);
        this.enterRoute(to, from, next);
    }

    getTrackingArgs(to) {
        const args = {
            ...super.getTrackingArgs(),
            pageType: 'partner_entry',
        };

        return args;
    }
}
