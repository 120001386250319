<template>
    <div class="upsell-plans-grid-wrapper upsell-plans-cta-wrapper">
        <div class="upsell-plans-grid footer">
            <div class="upsell-plans-grid-item"></div>
            <div class="upsell-plans-grid-item button-state" v-for="plan in plans">
                <PlansButtonState :plan="plan" :plans="plans" />
            </div>
        </div>
    </div>
</template>

<script>
    import PlansButtonState from 'aa/vue/components/upsell/PlansButtonState.vue';

    export default {
        name: 'PlansButtonList',
        props: {
            plans: {
                type: Array,
                required: true,
                default: [],
            },
        },
        components: {
            PlansButtonState,
        },
    };
</script>
