import 'shared/global';
import 'aa/components/AAUpsell';
import 'aa/components/FooterAllAccess';
import { AAAbTestModuleLoader } from 'aa/abTests/lib/AAAbTestModuleLoader';
import { measureHeroCtaPerformance } from 'aa/bundles/upsellMetric';

window.addEventListener('DOMContentLoaded', (event) => {
    new AAAbTestModuleLoader();
    measureHeroCtaPerformance();
});
