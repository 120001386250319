export default {
    data() {
        return {
            pageHeadingTabIndex: -1,
        };
    },
    methods: {
        removeHeadingTabIndex() {
            this.pageHeadingTabIndex = null;
        },
    },
    mounted() {
        if (this.$refs.pageHeading) {
            this.$refs.pageHeading.focus();
        }
    },
};
