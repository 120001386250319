<script>
    import { VSlideGroup } from 'vuetify/lib';
    function calculateCenteredOffset(selectedElement, widths, rtl) {
        const { offsetLeft, clientWidth } = selectedElement;
        if (rtl) {
            const offsetCentered =
                widths.content - offsetLeft - clientWidth / 2 - widths.wrapper / 2;
            return -Math.min(widths.content - widths.wrapper, Math.max(0, offsetCentered));
        } else if (widths.wrapper <= 1023) {
            const offsetCentered = offsetLeft + clientWidth / 2 - widths.wrapper / 2;
            return offsetCentered;
        } else {
            const offsetCentered = offsetLeft + clientWidth / 2 - widths.wrapper / 2;
            return Math.min(widths.content - widths.wrapper, Math.max(0, offsetCentered));
        }
    }

    function calculateUpdatedOffset(selectedElement, widths, rtl, currentScrollOffset) {
        const clientWidth = selectedElement.clientWidth;
        const offsetLeft = rtl
            ? widths.content - selectedElement.offsetLeft - clientWidth
            : selectedElement.offsetLeft;

        if (rtl) {
            currentScrollOffset = -currentScrollOffset;
        }

        const totalWidth = widths.wrapper + currentScrollOffset;
        const itemOffset = clientWidth + offsetLeft;
        const additionalOffset = clientWidth * 0.4;

        if (offsetLeft <= currentScrollOffset) {
            currentScrollOffset = Math.max(offsetLeft - additionalOffset, 0);
        } else if (totalWidth <= itemOffset) {
            currentScrollOffset = Math.min(
                currentScrollOffset - (totalWidth - itemOffset - additionalOffset),
                widths.content - widths.wrapper,
            );
        }

        return rtl ? -currentScrollOffset : currentScrollOffset;
    }

    export default {
        name: 'SlideGroup',
        extends: VSlideGroup,
        watch: {
            scrollOffset(val) {
                if (this.$vuetify.rtl) val = -val;
                let scroll = val <= 0 ? -val : -val;
                if (this.$vuetify.rtl) scroll = -scroll;
                this.$refs.content.style.transform = `translateX(${scroll}px)`;
            },
        },
        methods: {
            scrollIntoView() {
                if (!this.selectedItem && this.items.length) {
                    const lastItemPosition =
                        this.items[this.items.length - 1].$el.getBoundingClientRect();
                    const wrapperPosition = this.$refs.wrapper.getBoundingClientRect();
                    if (
                        (this.$vuetify.rtl && wrapperPosition.right < lastItemPosition.right) ||
                        (!this.$vuetify.rtl && wrapperPosition.left > lastItemPosition.left)
                    ) {
                        this.scrollTo('prev');
                    }
                }
                if (!this.selectedItem) {
                    return;
                }
                if (this.selectedIndex === 0 || (!this.centerActive && !this.isOverflowing)) {
                    this.scrollOffset = 0;
                } else if (this.centerActive) {
                    this.scrollOffset = calculateCenteredOffset(
                        this.selectedItem.$el,
                        this.widths,
                        this.$vuetify.rtl,
                    );
                } else if (this.isOverflowing) {
                    this.scrollOffset = calculateUpdatedOffset(
                        this.selectedItem.$el,
                        this.widths,
                        this.$vuetify.rtl,
                        this.scrollOffset,
                    );
                }
            },
        },
    };
</script>
