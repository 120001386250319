import ResetPasswordComponent from 'aa/vue/components/ResetPassword';
import Step from 'models/steps/Step';
import { GLOBAL_CHANGE_PASSWORD_PATH } from '../../../vue/constants/aaRoutes';

export default class GlobalChangePassword extends Step {
    path = GLOBAL_CHANGE_PASSWORD_PATH;
    name = 'GlobalChangePassword';
    component = ResetPasswordComponent;
    requireAuth = false;
    requireNonAuth = false;
    requireSub = true;
    props = {
        isChangePasswordFlow: true,
    };
}
