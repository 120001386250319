<template>
    <div class="upsell-plans-grid-wrapper">
        <ValuePropListItem
            v-for="(valueProp, idx) in getValueProps"
            :key="idx"
            :valueProp="valueProp"
            :plans="plans" />
    </div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex';
    import ValuePropListItem from 'aa/vue/components/upsell/ValuePropListItem.vue';

    export default {
        name: 'ValuePropList',
        data: function () {
            return {
                defaultText: '_text',
                defaultCheckbox: '_checkbox',
            };
        },
        props: {
            plans: {
                type: Array,
                required: true,
            },
        },
        components: {
            ValuePropListItem,
        },
        computed: {
            ...mapState('pageAttributes', {
                upsell_value_prop: (state) => state.attributes.upsell_value_prop,
            }),
            getValueProps() {
                if (this.upsellPlansAnnualEnabled && this.upsell_value_prop.length > 0) {
                    const last = this.upsell_value_prop.pop();
                    this.upsell_value_prop.unshift(last);
                }
                return this.upsell_value_prop;
            },
            ...mapGetters({
                upsellPlansAnnualEnabled: 'featureFlags/upsellPlansAnnualEnabled',
            }),
        },
    };
</script>
