//
// page attribute keys
//

// force negative banner
const RESUME_FAILURE = 'resume_failure';
const CANCEL_SCHEDULED = 'cancel_scheduled';

// auto close
const AUTO_CLOSE_SWITCH_SUCCESS = 'switch_success'; // only when upgrading
const AUTO_CLOSE_PAYMENT_INFO_UPDATED = 'payment_info_updated';
const AUTO_CLOSE_BILLING_INFO_UPDATED = 'billing_info_updated';

// override switch-success message with....
const SWITCH_SUCCESS = 'switch_success';
const SWITCH_SUCCESS_DOWNGRADE = 'switch_success_downgrade';
const SWITCH_SUCCESS_UPGRADE = 'switch_success_upgrade';
const SWITCH_SUCCESS_UPGRADE_ANNUAL = 'switch_success_upgrade_annual';

const DOWNGRADE = 'downgrade';
const GIFT = 'gift';

export default class NotificationBarModel {
    constructor(arg) {
        this.success = true;
        this.message = '';
        this.messageKey = '';
        this.autoClose = true;

        // CBSCOMSUB-4696
        this.denyClose = false;
        this.routeNameForMessage = null;

        // CBSCOMSUB-7247 notification below header consolidation
        this.messageTitle = '';
        this.spacerHeight = 0;
        this.showBelowHeader = false;

        if (typeof arg === 'string') {
            this.messageKey = arg;
            this.success = NotificationBarModel.knownNegatives.indexOf(this.messageKey) === -1;
        }

        if (typeof arg === 'object') {
            /**
             *
             * @type {boolean}
             */
            this.success = typeof arg.success !== 'undefined' ? arg.success : true;

            /**
             *
             * @type {string}
             */
            this.message = typeof arg.message !== 'undefined' ? arg.message : '';

            /**
             *
             * @type {string}
             */
            this.messageTitle = typeof arg.messageTitle !== 'undefined' ? arg.messageTitle : '';

            /**
             *
             * @type {string}
             */
            this.messageKey = typeof arg.messageKey !== 'undefined' ? arg.messageKey : '';

            /**
             * @type {boolean}
             */
            this.autoClose =
                typeof arg.autoClose !== 'undefined' && typeof arg.autoClose === 'boolean'
                    ? arg.autoClose
                    : true;

            // CBSCOMSUB-4696
            /**
             * @type {boolean}
             */
            this.denyClose =
                typeof arg.denyClose !== 'undefined' && typeof arg.denyClose === 'boolean'
                    ? arg.denyClose
                    : false;

            /**
             *
             * @type {boolean}
             */
            this.showBelowHeader =
                typeof arg.showBelowHeader !== 'undefined' ? arg.showBelowHeader : false;

            if (typeof arg.routeNameForMessage === 'string' && arg.routeNameForMessage.length) {
                this.routeNameForMessage = arg.routeNameForMessage;
            }
        }

        this.__resetAutoClose();
    }

    /**
     * Override auto close property depending on success state
     * @private
     */
    __resetAutoClose() {
        // all negative notifications must be closed by the user
        // success messages should autoClose by default, but can be overridden in constructor
        if (!this.success) {
            this.autoClose = false;
        }
    }

    /**
     *
     * @param bool
     * @returns {NotificationBarModel}
     */
    setSuccess(bool) {
        this.success = bool;
        return this;
    }

    setMessage(msg) {
        this.message = msg;
        return this;
    }

    setMessageKey(msgKey) {
        this.messageKey = msgKey;
        return this;
    }

    setAutoClose(bool) {
        this.autoClose = bool;
        return this;
    }

    static get AUTO_CLOSE_SWITCH_SUCCESS() {
        return AUTO_CLOSE_SWITCH_SUCCESS;
    }

    static get SWITCH_SUCCESS() {
        return SWITCH_SUCCESS;
    }

    static get SWITCH_SUCCESS_DOWNGRADE() {
        return SWITCH_SUCCESS_DOWNGRADE;
    }

    static get SWITCH_SUCCESS_UPGRADE() {
        return SWITCH_SUCCESS_UPGRADE;
    }

    static get SWITCH_SUCCESS_UPGRADE_ANNUAL() {
        return SWITCH_SUCCESS_UPGRADE_ANNUAL;
    }

    static get DOWNGRADE() {
        return DOWNGRADE;
    }

    static get knownNegatives() {
        return [RESUME_FAILURE, CANCEL_SCHEDULED];
    }

    static get knownAutoClose() {
        return [
            AUTO_CLOSE_SWITCH_SUCCESS,
            AUTO_CLOSE_PAYMENT_INFO_UPDATED,
            AUTO_CLOSE_BILLING_INFO_UPDATED,
        ];
    }
}
