import { bindWithPartnerAccount, getPartnerSubscriptionDetails } from 'services/ApiService';
import PartnerService from 'services/PartnerService';
import Domain from 'helpers/Domain';

const storedInfo = {
    subscriptionDetails: 'SUBSCRIPTION_DETAILS',
    partnerCSP: 'CSPVALUE',
    partnerAuthAttributes: 'AUTH_ATTRIBUTES',
};

export default {
    namespaced: true,

    state: {
        partnerCSPValue: PartnerService.getValue(storedInfo.partnerCSP),
        subscriptionDetails: PartnerService.getValue(storedInfo.subscriptionDetails),
        partnerAuthAttributes: PartnerService.getValue(storedInfo.partnerAuthAttributes),
        inPartnerFlow: PartnerService.inPartnerFlow(),
    },

    getters: {
        getPartnerCSPValue(state) {
            return state.partnerCSPValue;
        },
        inPartnerFlow(state) {
            return state.inPartnerFlow;
        },
    },

    mutations: {
        setPartnerCSPValue(state, payload) {
            state.partnerCSPValue = payload;
            PartnerService.setValue(storedInfo.partnerCSP, payload);
        },
        setSubscriptionDetails(state, payload) {
            state.subscriptionDetails = payload;
            PartnerService.setValue(storedInfo.subscriptionDetails, payload);
        },
        setPartnerAuthAttributes(state, payload) {
            state.partnerAuthAttributes = payload;
            PartnerService.setValue(storedInfo.partnerAuthAttributes, payload);
        },
    },
    actions: {
        bindWithPartner({ commit, dispatch, state }) {
            const httpData = {
                subscriptionId: state.subscriptionDetails.subscriptionId,
            };

            const bindPartnerSuccessCallback = () => {
                let urlParams = '';
                const hasOwnDevices = state.partnerAuthAttributes.has_own_devices;
                // TODO: Check against state.partner
                if (PartnerService.isPartnerFlow(state.partnerCSPValue)) {
                    if (!hasOwnDevices) {
                        PartnerService.unsetPartnerFlow(state.partnerCSPValue);
                    }
                    urlParams = `?partner=${encodeURIComponent(state.partnerAuthAttributes.csp_display_name)}`;
                }

                // If partner has own devices, send to rendezvous
                if (hasOwnDevices) {
                    const rendezvousLocation = `/${Domain.getRegionCode()}/tv${urlParams}`;
                    window.location.href = rendezvousLocation;
                } else {
                    window.location.href = `/${urlParams}`;
                }
            };

            return bindWithPartnerAccount(httpData, bindPartnerSuccessCallback);
        },
        async partnerAuthentication({ commit, dispatch, state }, successFn) {
            // Partner code is the backend name for the partner to be used in the endpoint.
            // TODO: This could either be the partner variable from CMS or the CSP value in the physical url
            const partnerCode = state.partnerCSPValue;
            const httpData = {
                url: window?.location?.href,
                partnerCode,
            };
            const success = await getPartnerSubscriptionDetails(httpData, successFn);

            return success;
        },
    },
};
