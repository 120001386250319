import Vue from 'vue';

const mutations = {
    parseData(state, data) {
        if (typeof data === 'object') {
            for (let key in data) {
                if (data.hasOwnProperty(key)) {
                    Vue.set(state, key, data[key]);
                }
            }
        }
    },
};

export default mutations;
