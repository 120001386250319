import portraitCarousel from 'components/portraitCarousel';
import landscapeCarousel from 'components/landscapeCarousel';
import {
    SwiperUpsellPortrait,
    SwiperUpsellLandscape,
} from 'components/settings/BreakpointSettings';
import AAUpsellCta from 'aa/components/AAUpsellCTA';
import AAUpsellShowtimeBundle from 'aa/components/AAUpsellShowtimeBundle';
import AAUpsellHeroBanner from 'aa/components/AAUpsellHeroBanner';
import AAUpsellHeroVideo from 'aa/components/AAUpsellHeroVideo';
import AAUpsellHero from 'aa/components/AAUpsellHero';
import AAUpsellCenterContent from 'aa/components/AAUpsellCenterContent';
import AAUpsellTracking from 'aa/components/AAUpsellTracking';
import AAUpsellMarketing from 'aa/components/AAUpsellMarketing';
import AAUpsellDisclaimer from 'aa/components/AAUpsellDisclaimer';
import AAUpsellPlansApp from 'aa/vue/apps/upsell/AAUpsellPlansApp';
import LazyLoad from 'vanilla-lazyload';

(function () {
    new AAUpsellPlansApp();
    new AAUpsellCta();
    new AAUpsellHeroBanner();
    new AAUpsellHeroVideo();
    new AAUpsellHero();
    new AAUpsellShowtimeBundle();
    new AAUpsellCenterContent();
    new AAUpsellMarketing();
    new AAUpsellTracking();
    new AAUpsellDisclaimer();

    portraitCarousel({
        parentContainer: $('#favorites-carousel'),
        options: {
            responsive: SwiperUpsellPortrait,
            centerInsufficientSlides: true,
        },
    });

    landscapeCarousel({
        parentContainer: $('#live-carousel'),
        options: {
            responsive: SwiperUpsellLandscape,
            centerInsufficientSlides: true,
        },
    });

    portraitCarousel({
        parentContainer: $('#originals-carousel'),
        options: {
            responsive: SwiperUpsellPortrait,
            centerInsufficientSlides: true,
        },
    });

    portraitCarousel({
        parentContainer: $('#custom-carousel'),
        options: {
            responsive: SwiperUpsellPortrait,
            centerInsufficientSlides: true,
        },
    });

    portraitCarousel({
        parentContainer: $('#kids-carousel'),
        options: {
            responsive: SwiperUpsellPortrait,
        },
    });

    //add on doc loaded and then the prevent default stuff (clicking user name in account menu in header)
    document.addEventListener('DOMContentLoaded', () => {
        let items = document.getElementsByClassName('suppress-default');
        for (let item of items) {
            item.addEventListener('click', (e) => {
                e.preventDefault();
            });
        }
    });

    let lazyBgSelector = '.lazy-bg';
    if (document.querySelector(lazyBgSelector)) {
        new LazyLoad({
            threshold: 500,
            elements_selector: lazyBgSelector,
        });
    }
})();
