// base paths
export const AA_CONFIG_BASE_PATH = '/cbs-all-access/';
export const AA_BASE_PATH = '/';

export const AA_ACCOUNT_BASE_PATH = '/';
export const OAUTH_BASE_PATH = '/oauth/';
export const TVE_RENDEZVOUS_BASE_PATH = '/tv/';
export const RENDEZVOUS_BASE_PATH = '/tv/';
export const OPTIONS_PATH = '/select/';
export const SIGNUP_URL = 'user/signup/';

// tracking (userStatus)
export const USER_STATUS_ANON = 'sb|0';

// tracking (userType)
export const USERTYPE_ANONYMOUS = 'ANON';
export const USERTYPE_REGISTERED = 'Registered';
export const USERTYPE_SUBSCRIBER = 'Subscriber';
export const USERTYPE_EX_SUBSCRIBER = 'Ex-subscriber';
export const AUTHZ_AUTHN_ERROR = 'AuthN/AuthZ';
export const THREE_D_SECURE_ERROR_CODE = 'three_d_secure_action_required';
export const SUBSCRIBED_OR_SUSPENDED = 'user is subscribed or suspended';

// Account notification urls
export const ACCOUNT_PATH = '/account';
export const GIFT_ADD_PATH = `${ACCOUNT_PATH}?notification=gift-add`;
export const GIFT_THIRD_PARTY_ERROR_PATH = `${ACCOUNT_PATH}?notification=gift-third-party-error`;

// Routes
export const PLAN_PICKER_PATH = '/account/signup/pickplan/';

// Payment methods
export const PAYMENT_PAYPAL = 'paypal';

// Cookie name
export const LOCKED_CONTENT_COOKIE_NAME = 'showtime_upgrade';
export const FLOWTYPE_SHO_MIGRATED = 'signin_showtime';

// TODO: use a prop on the object?
// plans on upsell helper flag to know last value prop, for pricing
export const LAST = 'last';

// MSP Switch Plan
export const SWITCH_DIRECTION_DOWNGRADE = 'downgrade';
export const SWITCH_DIRECTION_UPGRADE = 'upgrade';
export const SWITCH_DIRECTION_CURRENT = 'current';
export const SWITCH_DIRECTION_DISABLED = 'disabled';
export const RESELLER_CONSENT = 'RESELLER_CONSENT';
