// ENTIRE FILE IS FOR TEST PLOCTOPLUS-1298: google captcha
export default {
    namespaced: true,

    state: {
        recaptchaPublicKey: '',
    },

    mutations: {
        /**
         * Set the recaptcha public key
         *
         * @param state
         * @param payload
         */
        setRecaptchaPublicKey(state, payload) {
            state.recaptchaPublicKey = payload.recaptchaPublicKey;
        },
    },
};
