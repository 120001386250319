<template>
    <div>
        <notification-bar></notification-bar>

        <svod-header
            aa-region="global header"
            :minimal="hasError"
            :showSignIn="showSignInLink"
            :logoClass="logoClass"></svod-header>

        <div id="main-aa-container" v-if="!userMessage.heading" :class="getClasses">
            <transition name="slide-fade" mode="out-in">
                <router-view
                    :key="$route.path"
                    :setSignInVisibility="setSignInVisibility"></router-view>
            </transition>
        </div>

        <transition name="slide-fade" mode="out-in">
            <user-message v-bind="userMessage" v-if="userMessage.heading"></user-message>
        </transition>

        <Debug v-if="debug"></Debug>
    </div>
</template>

<script>
    import { mapState, mapActions, mapGetters } from 'vuex';
    import NotificationBar from 'aa/vue/components/NotificationBar';
    import UserMessage from 'aa/vue/components/UserMessage';
    import SvodHeader from 'aa/vue/components/SvodHeader';
    import Debug from 'aa/vue/components/Debug';
    import { AUTHZ_AUTHN_ERROR } from 'aa/vue/constants/shared';
    import get from 'lodash/get';
    import { SKIP_EXPLAINER_STEPS } from 'aa/helpers/featureConstants';

    // START TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan
    import { PLAN_WITH_SHO_PRE_SELECTED_ONE_CTA } from 'helpers/featureConstants';
    // END TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan

    export default {
        name: 'Funnel',
        components: {
            NotificationBar,
            SvodHeader,
            UserMessage,
            Debug,
        },
        data: function () {
            return {
                logoClass: '',
                hasError: false,
                debug: false,
                //showSignInLink an override option to be used to hide the SignIn link - false hides SignIn link
                showSignInLink: this.$root.serverData.showSignInLink,
            };
        },
        computed: {
            getClasses() {
                return {
                    'skip-explainers': this.featureIsActive(SKIP_EXPLAINER_STEPS),
                    // START TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan
                    'pre-selected-one-cta': this.featureIsActive(
                        PLAN_WITH_SHO_PRE_SELECTED_ONE_CTA,
                    ),
                    // END TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan
                };
            },
            ...mapState({
                authSuiteLoaded: (state) => state['authSuite']?.isLoaded,
                accessStatus: (state) => state['authSuite']?.accessStatus,
                loginEvent: (state) => state['authSuite']?.authSuiteServices.loginEvent,
            }),
            ...mapState(['userMessage']),
            ...mapGetters(['featureIsActive']),
        },
        created() {
            if (this.authSuiteLoaded) {
                this.listenToLoginEvents();
            }

            // Check if noAuth query param is true and display auth error
            const noAuthError = get(this.$route, 'query.noAuth', false);
            const path = get(this.$route, 'path');
            if (noAuthError && path === '/partner/') {
                this.emitAuthError();
            }
        },
        methods: {
            setSignInVisibility(visible) {
                this.showSignInLink = visible;
            },
            listenToLoginEvents() {
                this.loginEvent.onFail((err = {}) => {
                    if (err.errorType === AUTHZ_AUTHN_ERROR) {
                        this.$store.commit('setUserMessage', {
                            heading: this.$getLocale(err.heading, err.overrides),
                            subHeading: this.$getLocale(err.subHeading, err.overrides),
                            primaryButton: {
                                label: this.$getLocale(err.primaryButton.label),
                                link: err.primaryButton.link,
                            },
                        });
                    } else {
                        this.$store.commit('setUserMessage', {});
                    }
                    this.hasError = true;
                });
            },
            ...mapActions('authSuite', {
                emitAuthError: 'emitAuthZError',
            }),
        },
        watch: {
            authSuiteLoaded(newValue, oldValue) {
                if (oldValue === false && newValue === true) {
                    this.listenToLoginEvents();
                }
            },
        },
    };
</script>
