import UserMenu from 'components/userMenu';

export const toggleClass = (classList, className) => {
    if (classList.indexOf(className) < 0) {
        classList.push(className);
    } else {
        classList.splice(classList.indexOf(className), 1);
    }

    return classList;
};

export const removeClass = (classList, className) => {
    classList.splice(classList.indexOf(className), 1);

    return classList;
};

export const addClass = (classList, className) => {
    classList.push(className);

    return classList;
};

export const maybePluralize = (count, noun, suffix = 's') => `${noun}${count !== 1 ? suffix : ''}`;

export const capitalize = (word) => word.charAt(0).toUpperCase() + word.slice(1);

export const dollarFormat = (amount) => {
    amount = amount.toFixed(2);

    return '$' + amount;
};

export const decimalFormat = (price, subunitRatio) => {
    return price.toFixed(Math.log10(subunitRatio));
};

export const scrollTo = (elem) => {
    const navHeight = document.getElementById('global-header-container').offsetHeight;
    const elemYPos = elem.getBoundingClientRect().top - 20;

    window.scrollTo(window.scrollX, window.pageYOffset + elemYPos - navHeight);
};

/**
 * Navigate to the given url with a delay
 *
 * @param {string} url - The url to navigate to
 * @param {int} delayInSeconds - The number of second to delay before navigate to url (default 1, CDMDIGMOPS-3188)
 */
export const navigateToUrlWithDelay = (url, delayInSeconds = 2) => {
    // delayInSeconds CDMDIGMOPS - 3188
    setTimeout(() => {
        window.location.href = url;
    }, delayInSeconds * 1000);
};

export const doLogout = (flowUrl, code) => {
    const userMenu = new UserMenu();
    // go to create account, rebind.
    userMenu.logout(function () {
        window.location.href = flowUrl + 'signup/?code=' + code;
    });
};
