import Recaptcha from 'aa/vue/components/recaptcha/ScoreBasedRecaptcha';
import { mapGetters, mapState } from 'vuex';
import AccountPageTrackingService from 'aa/services/AccountPageTrackingService';
// ENTIRE FILE IS FOR TEST PLOCTOPLUS-1298: google captcha

export default {
    data: function () {
        return {
            recaptcha: null,
            captchaAction: '',
            captchaUseNonDefaultCallBack: false,
            captchaNonDefaultCallBack: null,
            captchaDefaultCallBack: this.handleValidForm,
            callbackArguments: [],
            actionsFlagType: 'google',
        };
    },
    computed: {
        featureFlagIsEnabled() {
            return this.$store?.getters?.['featureFlags/isEnabled'];
        },
        captchaIsGoogle() {
            return this.$store?.getters?.['variant/captchaIsGoogle'];
        },
        recaptchaPublicKey() {
            return this.$store?.state?.recaptcha?.recaptchaPublicKey;
        },
        trackingService() {
            return new AccountPageTrackingService();
        },
    },
    methods: {
        initRecaptcha() {
            this.recaptcha = new Recaptcha(
                this.recaptchaPublicKey,
                this.$store.state.serverData.language,
            );
        },

        /**
         * Check if the action feature flag is enabled
         */
        isActionFlagEnabled(action) {
            return this.featureFlagIsEnabled(
                this.getCaptchaFeatureFlagByAction(action.toUpperCase()),
            );
        },

        /**
         * Initialize captchas
         */
        addCaptchaValidation() {
            const action = this.captchaAction.toUpperCase();

            if (this.isActionFlagEnabled(action)) {
                if (this.captchaIsGoogle) {
                    this.initRecaptcha();
                    this.setCaptchaCallBack();
                }
            }
        },

        /**
         * Check if any captcha is enabled, then get it's token and call component's callback
         *
         * @param action
         * @param callbackArguments
         * @returns {boolean}
         */
        runFraudProtection(action, callbackArguments = []) {
            try {
                if (action.length < 2) {
                    return false;
                }

                action = action.toUpperCase();
                this.callbackArguments = callbackArguments;

                // get the feature flag for the given action
                if (this.isActionFlagEnabled(action)) {
                    // run google captcha if enabled
                    if (this.captchaIsGoogle) {
                        this.recaptcha.run({
                            action: action,
                            component: this,
                            componentCallBack: this.captchaDefaultCallBack,
                            callbackArguments: callbackArguments,
                        });
                        return true;
                    }
                }

                return false;
            } catch (err) {
                console.error('error in runFraudProtection', err);
                return false;
            }
        },

        /**
         * Set recaptcha callback method
         */
        setCaptchaCallBack() {
            if (this.captchaUseNonDefaultCallBack === true) {
                this.captchaDefaultCallBack = this.processValidCaptcha;
            }
        },

        /**
         * Get feature flag name by captcha action
         *
         * @param action
         * @returns {string|null}
         */
        getCaptchaFeatureFlagByAction(action) {
            switch (action) {
                case 'FORM_SIGN_UP':
                    return 'recaptcha_fraud_protection_account_sign_up_form';
                case 'FORM_SIGN_IN':
                    return 'recaptcha_fraud_protection_account_sign_in_form';
                case 'FORM_PASSWORD':
                    return 'recaptcha_fraud_protection_account_password_form';
                case 'FORM_EMAIL':
                    return 'recaptcha_fraud_protection_account_email_form';
                case 'FORM_EDIT_PAYMENT':
                    return 'recaptcha_fraud_protection_payment_domestic';
                case 'FORM_PAYMENT':
                    return 'recaptcha_fraud_protection_payment_domestic';
                case 'FORM_SWITCH_PLAN':
                    return 'recaptcha_fraud_protection_account_switch_plan';
                default:
                    return null;
            }
        },

        /**
         * Return Feature Flag name based on bucket type and action
         * @param action
         */
        getFormActionFeatureFlag(action) {
            if (this.actionsFlagType === 'google') {
                return this.getCaptchaFeatureFlagByAction(action);
            }
        },

        /**
         * Callback method to call after get token if the default callback isn't used
         */
        processValidCaptcha() {
            this.callNonDefaultCallback();
        },

        /**
         * Call the non default callback
         */
        callNonDefaultCallback() {
            if (typeof this.captchaNonDefaultCallBack !== 'function') {
                throw new Error('Captcha non default callback is missing!');
            }

            this.captchaSolved = true;
            if (this.callbackArguments.length > 0) {
                const args = Array.from(this.callbackArguments);
                this.captchaNonDefaultCallBack(...args);
            } else {
                this.captchaNonDefaultCallBack();
            }
        },

        /**
         * Track recaptcha trackRecaptchaFailure or trackCaptchaVerified from api response
         *
         * @param response
         */
        trackCaptchaTokenValidationResult(response) {
            if (!this.recaptcha) {
                return;
            }

            if (response?.data?.recaptchaTokenValidated) {
                this.recaptcha.trackRecaptchaVerified();
            } else if (response?.data?.recaptchaTokenValidated === false) {
                this.recaptcha.trackRecaptchaFailure();
            }
        },
    }
};
