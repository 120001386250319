<template id="debug">
    <div v-if="show">
        <div class="debug-breakpoint">
            <span class="debug-mp">MP</span>
            <span class="debug-ml">ML</span>
            <span class="debug-tp">TP</span>
            <span class="debug-tl">TL</span>
            <span class="debug-d">D</span>
            <span class="debug-dw">DW</span>
        </div>
        <div class="debug-nav">
            <ul id="route-nav-debug">
                <li><router-link to="/plan/">Plan</router-link></li>
                <li><router-link to="/signin/">Sign In</router-link></li>
                <li><router-link to="/signup/">Sign Up</router-link></li>
                <li><router-link to="/payment/">Payment</router-link></li>
                <li>
                    <router-link to="/forgotpassword/">Forgot Password</router-link>
                </li>
                <li>
                    <router-link to="/resetpassword/token/">Reset Password</router-link>
                </li>
                <li>
                    <router-link to="/activate/appletv/">AppleTV</router-link>
                </li>
                <li>
                    <router-link to="/activate/appletv/complete/">AppleTV Complete</router-link>
                </li>
                <li style="color: #aaeeff">{{ flowType }}</li>
                <li style="color: #ffbbbb">{{ userStatusCode }}</li>
            </ul>
            <div class="debug-close" @click="hideDebug"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Debug',
        data: function () {
            return {
                show: true,
            };
        },

        computed: {
            flowType() {
                return this.$store.getters['flow/type'];
            },

            userStatusCode() {
                return this.$store.state.user.statusCode;
            },
        },

        methods: {
            hideDebug: function () {
                this.show = false;
            },
        },
    };
</script>
