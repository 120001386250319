<template>
    <div id="modal-legal-overlay" class="modal--cta">
        <h3 class="no-border">
            {{ currentModalData.legal_copy_header }}
        </h3>
        <p class="modal--body" v-html="explainer"></p>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import Product from 'aa/vue/models/Product';

    export default {
        name: 'BillingPlanLegalOverlayModal',
        mounted() {
            this.$store.dispatch('modal/setDisplayCloseButton', true);
        },
        computed: {
            ...mapState('modal', {
                currentModalData: (state) => state.currentModalData,
            }),
            explainer() {
                return this.currentModalData.chosen_legal_copy_desktop;
            },
        },
    };
</script>
