<template>
    <div id="modal-switch-to-annual-prompt" class="modal--cta">
        <h3>
            {{ pageAttribute.get('switch_prompt_header', 'Switch plan and remove SHOWTIME?') }}
        </h3>
        <p class="modal--body" v-html="disclaimer"></p>
        <ButtonCta :buttonConfig="submitConfig"></ButtonCta>
        <ButtonCta :buttonConfig="cancelConfig"></ButtonCta>
    </div>
</template>

<script>
    import ButtonCta from 'atoms/ButtonCta';
    import BaseAccount from 'aa/vue/components/account/BaseAccount/BaseAccount';

    export default {
        extends: BaseAccount,
        name: 'SwitchToAnnualPromptModal',
        components: {
            ButtonCta,
        },
        computed: {
            disclaimer() {
                let disclaimer = this.pageAttribute.get(
                    'switch_prompt_disclaimer',
                    'The SHOWTIME add-on is only available on a monthly plan. By switching to an annual plan, you will lose access to SHOWTIME.',
                );
                return disclaimer.replace(/(\r\n|\r|\n)/g, '<br/>');
            },
            submitConfig() {
                return {
                    styleClass: ['button', 'secondary'],
                    displayText: this.pageAttribute.get('switch_prompt_cancel_cta', 'Cancel'),
                    event: this.cancelOnClicked,
                };
            },
            cancelConfig() {
                return {
                    styleClass: ['button', 'primary'],
                    displayText: this.pageAttribute.get('switch_prompt_confirm_cta', 'Continue'),
                    event: this.confirmOnClicked,
                };
            },
        },
        methods: {
            cancelOnClicked() {
                this.closeModal();
            },
            confirmOnClicked() {
                this.$store.dispatch('modal/setConfirmSwitchToAnnual', true);
                this.closeModal();
            },
        },
    };
</script>
