import ResetPasswordComponent from 'aa/vue/components/ResetPassword';
import Step from 'models/steps/Step';
import { ACCOUNT_BASE_PATH } from 'vue/constants/svodRoutes';

export default class ChangePassword extends Step {
    path = `${ACCOUNT_BASE_PATH}changepassword/:token/`;
    name = 'ChangePassword';
    component = ResetPasswordComponent;
    requireAuth = false;
    requireNonAuth = false;
    props = {
        isChangePasswordFlow: true,
    };
}
