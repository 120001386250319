import Mvpd from 'aa/vue/components/mvpd/Mvpd';
import Step from 'models/steps/Step';

export default class MVPDStep extends Step {
    path = '/partner/:TVProviderID?';
    name = 'MVPD';
    component = Mvpd;
    postponeTrackStep = true;

    async beforeEnter(to, from, next) {
        const isEnabled = this._store.getters['getServerDataByKey']('authSuiteConfig')?.enabled;

        if (isEnabled) {
            this.enterRoute(to, from, next);
        } else {
            next({
                name: 'SIGNIN',
            });
        }
    }
}
