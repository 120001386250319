<!-- // START TEST PLOCTOPLUS-2036 Pick a Plan: Plan Tiles Above Fold -->
<template>
    <div class="native-app-view-ab-test">
        <div class="plan-list-grid-ab-test">
            <AbTestNativeAppViewPlansBox
                v-for="(plan, idx) in plans"
                :key="idx"
                :idx="idx"
                :plan="plan"
                @setFeaturesList="setFeaturesList" />
        </div>

        <div class="multi-sub-plan__active">
            <div class="plan-box__data">
                <FeatureList :plan="getHighlightedPlan" :features="featureList" />
            </div>
            <div class="multi-sub-plan__proceed-button -gradient">
                <ButtonCta :buttonConfig="getButtonConfig" />
            </div>
        </div>
    </div>
</template>

<script>
    import FeatureList from 'aa/vue/atoms/FeatureList';
    import AbTestNativeAppViewPlansBox from 'aa/vue/components/AbTestNativeAppViewPlansBox';
    import PillComponent from 'aa/vue/components/PillComponent';
    import { mapState, mapGetters } from 'vuex';
    import ButtonCta from 'atoms/ButtonCta';
    import AbTestPlanTilesAboveFoldMixin from 'aa/vue/abTestRelated/AbTestPlanTilesAboveFoldMixin';

    export default {
        name: 'AbTestNativeAppViewPlans',
        mixins: [AbTestPlanTilesAboveFoldMixin],
        props: {
            plans: {
                type: Array,
                required: true,
            },
        },
        data: function () {
            return {
                featureList: [],
            };
        },
        methods: {
            setFeaturesList(featureList) {
                this.featureList = featureList;
            },
            handlePlanSelectedProxy() {
                const plan = this.plans[this.highlightedPlanIdx];
                this.$emit('planSelected', plan);
            },
        },
        computed: {
            ...mapGetters({
                getNextStep: 'flow/getNextStep',
            }),
            getHighlightedPlan() {
                return this.highlightedPlanIdx === -1
                    ? this.plans[0]
                    : this.plans[this.highlightedPlanIdx];
            },
            getButtonConfig() {
                return {
                    displayText: 'Select Plan',
                    styleClass: 'button primary ab-test-cta',
                    tabIndex: 0,
                    event: () => {
                        this.handlePlanSelectedProxy();
                    },
                };
            },
        },
        components: {
            ButtonCta,
            PillComponent,
            AbTestNativeAppViewPlansBox,
            FeatureList,
        },
    };
</script>
<!-- // END TEST PLOCTOPLUS-2036 Pick a Plan: Plan Tiles Above Fold -->
