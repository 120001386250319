import Domain from 'helpers/Domain';
import {
    AA_BASE_PATH,
    ACCOUNT_BASE_PATH,
    AA_XHR_BASE_PATH,
    ACCOUNT_XHR_BASE_PATH,
} from 'vue/constants/svodRoutes';

// Base Paths
export { AA_BASE_PATH, ACCOUNT_BASE_PATH, AA_XHR_BASE_PATH, ACCOUNT_XHR_BASE_PATH };

export const HOME_PATH = '/home/';
export const ACCOUNT_PATH = '/account';
export const RENDEZVOUS_BASE_PATH = Domain.isDomestic() ? '/activate/' : '/tv/';
export const OAUTH_BASE_PATH = '/oauth/';
export const PARTNER_BASE_PATH = '/activate/';
export const TVE_RENDEZVOUS_BASE_PATH = '/tv/';
export const EDU_BASE_PATH = `${ACCOUNT_BASE_PATH}edu/`;

// Routes
export const SIGNIN_URL = `${ACCOUNT_BASE_PATH}signin`;
export const FORGOT_PASSWORD_URL = Domain.isDomestic()
    ? `${ACCOUNT_BASE_PATH}forgotpassword`
    : `/${Domain.getRegionCode()}${ACCOUNT_BASE_PATH}forgotpassword`;
export const GLOBAL_RESET_PASSWORD_URL = '/global/resetpassword';
export const PICK_PLAN_URL = `${ACCOUNT_BASE_PATH}signup/pickplan/`;
export const SIGNUP_URL = `${ACCOUNT_BASE_PATH}signup/createaccount/`;
export const GIFT_URL = Domain.isInternational()
    ? `/${Domain.getRegionCode()}${ACCOUNT_BASE_PATH}gift/redeem`
    : `${ACCOUNT_BASE_PATH}gift/redeem`;
export const LINK_TO_PROVIDER_URL = Domain.isInternational()
    ? `/${Domain.getRegionCode()}${ACCOUNT_BASE_PATH}link`
    : `${ACCOUNT_BASE_PATH}link`;
export const GIFT_PURCHASE_ADD_URL = `${ACCOUNT_BASE_PATH}?notification=gift-add`;
export const GIFT_INVALID_ERROR = `${GIFT_URL}?notification=gift-invalid-error`;
export const GIFT_PURCHASE_THIRD_PARTY_URL = `${ACCOUNT_BASE_PATH}?notification=gift-third-party`;
export const CONFIRMATION_URL = `${ACCOUNT_BASE_PATH}subscription/payment/confirmation`;
export const ACCOUNT_URL = ACCOUNT_BASE_PATH.replace(/\/$/, '');
export const HOME_URL = HOME_PATH.replace(/\/$/, '');
export const SHOW_PICKER_SIGNUP = `${ACCOUNT_BASE_PATH}shows/picker/`;
export const COMPILING_SHOWS_SIGNUP = `${ACCOUNT_BASE_PATH}shows/compiling/`;
export const GLOBAL_CHANGE_PASSWORD_PATH = `/global/changepassword/:token/`;
