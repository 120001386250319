<template>
    <div
        v-show="isOpen && !showBelowHeader"
        class="notification-bar-wrapper"
        ref="notificationBarWrapper">
        <div :class="getClassList" ref="notificationBar" data-ci="notification-bar">
            <div class="padded-container" ref="html">
                <span class="icon info"></span>
                <span class="notification" v-html="message"></span>
                <!-- CBSCOMSUB-4696 -->
                <span v-if="!denyClose" class="icon close" @click="doHide"></span>
            </div>
        </div>
    </div>
</template>

<script>
    /*

    HOW TO:
    ------------------------------------------------------------
    Display a message stored in NotificationBar page attributes
    from any component (including this one on page load)
    ------------------------------------------------------------

    Preferred Way

    let nbm = new NotificationBarModel(<string>);
    this.openNotification(nbm);

    or

    let nbm = new NotificationBarModel();
    nbm.setMessageKey(<string>);
    this.openNotification(nbm);

    ^ where <string> reflects the page attribute key i.e. switch_success_downgrade

    NOTE: the NotificationBarStore will override positive (blue) bar with
    negative (red) bar automatically (if necessary), so there's no need to
    set the success state in NotificationBarModel for this use case

    ------------------------------------------------------------
    Display a custom message from any component
    ------------------------------------------------------------

    Preferred Way:

    let nbm = new NotificationBarModel({
        success: <boolean>, // (optional, default: true)
        message: <string>   // (required)
    });
    this.openNotification(nbm);

    or

    let nbm = new NotificationBarModel();
    nbm.setSuccess(<boolean>)
        .setMessage(<string>);
    this.openNotification(nbm);

    or (relaxed way, success only)

    let nbm = new NotificationBarModel();
    nbm.setMessage(<string>);
    this.openNotification(nbm);

    or (super relaxed way)

    this.openNotification({
        success: <boolean>,
        message: <string>
    })

    */

    import { mapState } from 'vuex';
    import NotificationBarModel from 'aa/vue/models/NotificationBarModel';
    import Domain from 'helpers/Domain';

    export default {
        name: 'NotificationBar',
        props: {},
        data: function () {
            return {
                elHeader: document.querySelector('header'),
                elWrapper: null,
                elBar: null,
                animationSpeed: 300,

                // CBSCOMSUB-4696
                isFlowApp: document.getElementById('app'),
                elBody: document.querySelector('body'),
                elBodyClass: 'has-notification-bar',
            };
        },
        methods: {
            setWrapperHeight() {
                this.$refs['notificationBarWrapper'].style.height =
                    this.$refs['notificationBar'].getBoundingClientRect().height + 'px';
                this.doShow();
            },

            doShow() {
                // CBSCOMSUB-4696 (can remove true portion of conditional when removing facebook banner
                if (this.isFlowApp) {
                    this.elBody.classList.add(this.elBodyClass);
                    this.$refs['notificationBarWrapper'].style.maxHeight =
                        this.$refs['notificationBar'].getBoundingClientRect().height + 'px';
                    this.$refs['notificationBar'].style.top = 0;
                    this.elHeader.style.top =
                        this.$refs['notificationBar'].getBoundingClientRect().height + 'px';
                    setTimeout(() => {
                        this.$refs['notificationBarWrapper'].style.zIndex = 3;
                    }, this.animationSpeed);
                } else {
                    this.$refs['notificationBarWrapper'].style.maxHeight =
                        this.$refs['notificationBar'].getBoundingClientRect().height + 'px';
                    this.$refs['notificationBar'].style.top =
                        this.elHeader.getBoundingClientRect().height + 'px';
                }
            },

            doHide() {
                this.$store.dispatch('notification/close');
            },

            doCollapse() {
                // CBSCOMSUB-4696 (can remove true portion of conditional when removing facebook banner
                if (this.isFlowApp) {
                    this.elBody.classList.remove(this.elBodyClass);
                    this.$refs['notificationBarWrapper'].style.maxHeight = 0 + 'px';
                    this.$refs['notificationBar'].style.top = 0;
                    this.elHeader.style.top = 0 + 'px';
                    this.$refs['notificationBarWrapper'].style.zIndex = 1;
                } else {
                    this.$refs['notificationBar'].style.top = 0;
                    this.$refs['notificationBarWrapper'].style.maxHeight = 0;
                }
            },

            // some messages have links
            // this makes sure the links are handled by the vue router IF the view is in Vue (not legacy)
            // remove conditional when this.messageKeyLinksToLegacy is empty (no longer applicable)
            fixAnchors() {
                if (
                    this.messageKeyLinksToLegacy &&
                    this.messageKeyLinksToLegacy.indexOf(this.messageKey) === -1
                ) {
                    // timeout allows Vue to render the view
                    // then the anchors are accessible
                    setTimeout(() => {
                        let ref = this.$refs['html'];
                        let anchors = ref.querySelectorAll('a');
                        anchors.forEach((el) => {
                            if (el.getAttribute('target') !== '_blank') {
                                el.addEventListener('click', (e) => {
                                    if (el.hostname && el.hostname === window.location.hostname) {
                                        e.preventDefault();
                                        let href = e.target
                                            .getAttribute('href')
                                            .replace('\/all-access', '');
                                        this.$router.push(href);
                                    }
                                });
                            }
                        });
                    }, 1);
                }
            },

            // Gift Credit addition
            updateGiftCardAmount() {
                // timeout allows Vue to render the view
                // then the element is accessible to update
                setTimeout(() => {
                    let el = document.querySelector('#gift-card-message-amount');
                    if (el) {
                        let gift_data = window.sessionStorage.getItem('gift_data')
                            ? JSON.parse(window.sessionStorage.getItem('gift_data'))
                            : {};
                        let display_gift_amount = gift_data.amount
                            ? '$' +
                              (gift_data.amount.split('.')[1].length === 1
                                  ? gift_data.amount + '0'
                                  : gift_data.amount)
                            : '';
                        if (display_gift_amount.split('.')[1] === '00') {
                            display_gift_amount = display_gift_amount.slice(0, -3);
                        }
                        el.innerHTML = display_gift_amount;
                    }
                }, 1);
            },
            shouldHideNotification(to) {
                const notRoutingToSigninOrPayment =
                    to.name !== 'SIGNIN' &&
                    (to.name !== 'PAYMENT' || to.name !== 'PAYMENT_SUMMARY');
                const isIntlOrNotRoutingToSignup = Domain.isDomestic() || to.name !== 'SIGNUP';
                const isNotNavigatingForMessage =
                    !this.routeNameForMessage || to.name !== this.routeNameForMessage;

                return (
                    this.isFlowApp &&
                    notRoutingToSigninOrPayment &&
                    isIntlOrNotRoutingToSignup &&
                    isNotNavigatingForMessage
                );
            },
        },
        computed: {
            getClassList() {
                return {
                    'notification-bar': true,
                    positive: this.isPositive,
                    negative: !this.isPositive,
                };
            },

            ...mapState('notification', {
                isOpen: (state) => state.isOpen,
                isClosing: (state) => state.isClosing,
                message: (state) => state.message,
                messageTitle: (state) => state.messageTitle,
                messageKey: (state) => state.messageKey, // this can be removed when messageKeyLinksToLegacy is removed
                isPositive: (state) => state.isPositive,
                showBelowHeader: (state) => state.showBelowHeader,

                // CBSCOMSUB-4696
                denyClose: (state) => state.denyClose,
                routeNameForMessage: (state) => state.routeNameForMessage,
            }),
        },

        mounted() {
            window.addEventListener('resize', () => {
                this.setWrapperHeight();
            });

            this.elHeader = document.querySelector('header');
        },
        watch: {
            isOpen: function (newValue, oldValue) {
                if (newValue === true) {
                    // required delay to set heights
                    // so animation can occur after isOpen is reflected
                    // and template displays
                    setTimeout(() => {
                        this.setWrapperHeight();
                    }, 1);

                    // for messages with links
                    this.fixAnchors();

                    // for gift card message
                    this.updateGiftCardAmount();
                }
            },

            isClosing: function (newValue, oldValue) {
                if (newValue) {
                    this.doCollapse();
                }
            },

            $route: function (to, from) {
                // CBSCOMSUB-4696
                if (this.shouldHideNotification(to)) {
                    this.doHide();
                }
            },
        },
    };
</script>
