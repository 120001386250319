<template>
    <div id="modal-cancel-sub" class="modal--cta">
        <h3>{{ heading }}</h3>
        <p v-html="$options.filters.sanitize(offerDescription)"></p>
        <ButtonCta :buttonConfig="submitConfig"></ButtonCta>
        <ButtonCta :buttonConfig="cancelConfig"></ButtonCta>
    </div>
</template>

<script>
    import ButtonCta from 'atoms/ButtonCta';
    import { PageAttribute } from 'aa/vue/components/account/PageAttribute';
    import Feature from 'aa/helpers/featureConstants';
    import NotificationBarModel from 'aa/vue/models/NotificationBarModel';
    import BaseAccount from 'aa/vue/components/account/BaseAccount/BaseAccount';
    import { ACCOUNT_XHR_BASE_PATH } from 'aa/vue/constants/aaRoutes';
    import Product from 'aa/vue/models/Product';

    export default {
        extends: BaseAccount,

        name: 'CancelSubModal',
        created() {
            this.$trackingService.trackPromoOfferDisplayed(this.heading);
        },
        mounted() {
            this.$parent.$on('closeModalEvent', (e) => {
                this.$trackingService.trackPromoOfferExited(this.heading);
            });
        },
        computed: {
            content() {
                if (Feature.multipleEntitlementsEnabled()) {
                    return new PageAttribute(this.$store.state.modal.pageAttributes.CancelSubModal);
                }

                return this.pageAttribute;
            },
            heading() {
                return this.content.get(this.offerPrefix + '_heading');
            },

            offerDescription() {
                return this.content.get(this.offerPrefix + '_content');
            },

            submitConfig() {
                return {
                    styleClass: ['button', 'secondary'],
                    displayText: this.content.get('btn_text_continue_cancel'),
                    event: this.confirmCancel,
                };
            },

            cancelConfig() {
                return {
                    styleClass: ['button', 'primary'],
                    displayText: this.content.get('btn_text_redeem'),
                    event: this.redeemCoupon,
                };
            },

            offerPrefix() {
                if (this.$store.state.serverData.featureFlag.aa_retention_offer_package_prefix) {
                    return this.offerPrefixFromUserPackage;
                }

                return this.offerPrefixFromProductCode;
            },

            offerPrefixFromProductCode() {
                return this.user.svod.recurly_package.product_code.toLowerCase();
            },

            offerPrefixFromUserPackage() {
                const userPackage = this.user.svod.recurly_package;

                const packageCodePart = {
                    [Product.PACKAGE_CODE.LCP]: 'lcp',
                    [Product.PACKAGE_CODE.LC]: 'lc',
                    [Product.PACKAGE_CODE.CF]: 'cf',
                }[userPackage.code];

                const addOnCodesPart = userPackage.addOns
                    ?.map((addon) => addon.internalAddOnCode.toLowerCase())
                    .join('_');

                const planType =
                    userPackage.plan_type === Product.TYPE_ANNUAL
                        ? Product.TYPE_ANNUAL
                        : Product.TYPE_MONTHLY;
                const trialStatus = userPackage.on_trial ? 'trial' : 'paid';

                const prefix = ['aa', packageCodePart, addOnCodesPart, planType, trialStatus]
                    .filter((part) => part)
                    .join('_');

                return prefix;
            },
        },

        methods: {
            confirmCancel() {
                // close modal
                this.closeModal();
                this.$trackingService.trackPromoOfferDeclined(this.heading);

                // send to cancel reasoning
                this.$router.push({ name: 'AccountCancelSubscription' });
            },

            async redeemCoupon() {
                this.$trackingService.trackPromoOfferRedeemed(this.heading);

                const httpData = this.buildHttpData();

                const res = await this.$http.doPost(
                    `${ACCOUNT_XHR_BASE_PATH}retention/apply/`,
                    httpData,
                );

                if (res.success) {
                    this.onRedemptionSuccess();
                } else {
                    this.onRedemptionError();
                }
            },

            onRedemptionSuccess() {
                let nbmSuccess = new NotificationBarModel({
                    success: true,
                    message: this.content.get('redeem_success_message'),
                });

                this.$trackingService.trackPromoOfferApplied(this.heading);
                this.syncServerData();
                this.openNotification(nbmSuccess);
                this.closeModal();
            },

            onRedemptionError() {
                let nbmError = new NotificationBarModel({
                    success: false,
                    message: this.content.get('redeem_error_message'),
                });

                this.openNotification(nbmError);
                this.closeModal();
            },
        },
        components: {
            ButtonCta,
        },
    };
</script>
