import Breakpoints from 'helpers/breakpoints';
import Feature from 'helpers/feature';
import { debouncedScroll } from 'helpers/bindScroll';
import Domain from 'helpers/Domain';

export default class AAUpsellCTA {
    constructor() {
        this.breakpoints = new Breakpoints();

        // elements
        this.header = null;
        this.topElement = null;
        this.signInLi = null;
        this.heroCta = null;
        this.footerCta = null;
        this.logo = null;

        // Waypoints
        this.topElementTop = 0;
        this.topElementBot = 0;
        this.upsellPlanButtonsTop = 0;
        this.heroCtaTop = 0;
        this.heroCtaBot = 0;
        this.footerCtaTop = 0;
        this.footerCtaBot = 0;
        this.windowHeight = 0;

        // used to determine if scrolling up or down
        this.lastScrollTop = 0;

        this.listenerOptions = Feature.supportsPassive()
            ? {
                  capture: true,
                  passive: true,
              }
            : false;

        window.addEventListener('DOMContentLoaded', () => {
            this.init();
        });
    }

    init() {
        let ul = document.querySelector('#user-profiles-menu-trigger');
        this.header = document.querySelector('header');
        this.topElement = document.querySelector('.siteLogo');
        if (ul) {
            this.signInLi = ul.querySelector('#js-li-sign-in');
        }
        this.upsellPlanButtons = document.querySelector('.upsell-plans-cta-wrapper');
        this.heroCta = document.querySelector('.hero .button');
        this.footerCta = document.querySelector('.its-on .button');
        this.logo = document.querySelector('.siteLogo');
        this.mobileCta = document.querySelector('.mobile-cta-container');

        this.resetTopElementWaypoint();
        this.resetWaypoints();
        this.resizeListener();
        this.scrollListener();
        this.animate();
    }

    resizeListener() {
        window.addEventListener(
            'resize',
            () => {
                this.resetTopElementWaypoint();
                this.resetWaypoints();
                this.animate();
            },
            this.listenerOptions,
        );
    }

    scrollListener() {
        debouncedScroll(() => this.animate(), this.listenerOptions);
    }

    planCTAsVisibleInViewPort() {
        return (
            this.upsellPlanButtonsTop &&
            this.upsellPlanButtonsTop > this.topElementBot &&
            this.upsellPlanButtonsTop < this.topElementBot + this.windowHeight - 100
        );
    }

    // determine if we should try to hide or show the cta
    animate() {
        // unfortunately we have call getBoundingClientRect again
        // IntersectionObserver doesn't quite work well enough when scrolling slowly
        this.resetWaypoints();

        let top = window.pageYOffset;
        this.scrollDirection = this.lastScrollTop < top ? 'down' : 'up';

        if (top === 0) {
            this.hide();
        } else if (this.scrollDirection === 'down') {
            if (
                this.heroCtaTop < this.topElementBot &&
                this.footerCtaBot > this.windowHeight &&
                !this.planCTAsVisibleInViewPort()
            ) {
                this.show();
            } else {
                this.hide();
            }
        } else {
            if (
                this.heroCtaTop < this.topElementBot &&
                this.footerCtaTop > this.windowHeight &&
                !this.planCTAsVisibleInViewPort()
            ) {
                this.show();
            } else {
                this.hide();
            }
        }

        this.lastScrollTop = top;
    }
    resetWaypoints() {
        let upsellClientRect = this.upsellPlanButtons
            ? this.upsellPlanButtons.getBoundingClientRect()
            : null;
        let heroCtaClientRect = this.heroCta ? this.heroCta.getBoundingClientRect() : null;

        if (this.footerCta) {
            let f = this.footerCta.getBoundingClientRect();
            this.footerCtaTop = f.top;
            this.footerCtaBot = f.bottom;
        }

        this.upsellPlanButtonsTop = upsellClientRect ? upsellClientRect.top : null;
        this.upsellPlanButtonsBot = upsellClientRect ? upsellClientRect.bottom : null;

        this.heroCtaTop = heroCtaClientRect.top;
        this.heroCtaBot = heroCtaClientRect.bottom;

        this.windowHeight = window.innerHeight;
        this.windowWidth = window.innerWidth;
    }

    resetTopElementWaypoint() {
        if (this.topElement) {
            let el = this.topElement.getBoundingClientRect();
            this.topElementTop = el.top;
            this.topElementBot = el.bottom;
        }
    }

    show() {
        // show dark header
        if (!this.header.classList.contains('dark')) {
            this.header.classList.add('dark');
        }

        if (this.mobileCta) {
            // hide mobile cta
            if (this.windowWidth >= this.breakpoints.getMax('tabletPortrait')) {
                this.mobileCta.classList.remove('showMobileCta');
                this.mobileCta.classList.add('hideMobileCta');
            }

            // show mobile cta
            if (this.windowWidth < this.breakpoints.getMax('tabletPortrait')) {
                this.mobileCta.classList.add('showMobileCta');
                this.mobileCta.classList.remove('hideMobileCta');
                this.scrollDirection === 'up'
                    ? this.mobileCta.classList.add('ios-cta-class')
                    : this.mobileCta.classList.remove('ios-cta-class');
            }
        }

        // show logo
        if (!this.logo.classList.contains('show')) {
            this.logo.classList.add('show');
            this.logo.classList.remove('hide');
        }
    }

    hide() {
        // hide/prevent dark header
        this.header.classList.remove('dark');

        // show the li located behind the cta (styles apply to MP-TL)
        if (this.signInLi && !this.signInLi.classList.contains('show')) {
            this.signInLi.classList.add('show');
            this.signInLi.classList.remove('hide');
        }
        // hide logo
        if (Domain.isDomestic() && this.logo.classList.contains('show')) {
            this.logo.classList.add('hide');
            this.logo.classList.remove('show');
        }

        // hide mobile cta
        if (this.mobileCta && this.windowWidth < this.breakpoints.getMax('tabletPortrait')) {
            this.mobileCta.classList.remove('showMobileCta');
            this.mobileCta.classList.add('hideMobileCta');
            this.mobileCta.classList.remove('ios-cta-class');
        }
    }
}
