<template>
    <div class="partner-bundle-wrapper">
        <div class="partner-bundle">
            <div class="partner-bundle-items">
                <div class="partner-bundle__text">
                    <div class="partner-bundle__text-heading">
                        {{ headingCopy }}
                    </div>
                    <div class="partner-bundle__text-description">
                        {{ descriptionCopy }}
                    </div>
                </div>
            </div>
            <div class="partner-bundle-items">
                <div class="partner-bundle__cta">
                    <ButtonCta :buttonConfig="getButtonConfig"></ButtonCta>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ButtonCta from 'atoms/ButtonCta';
    import { mapState } from 'vuex';
    import { tracking } from 'services/Tracking';

    export default {
        name: 'PartnerBundleCTA',
        props: {},
        data: function () {
            return {
                buttonConfig: {},
                observer: null,
                displayShadow: true,
            };
        },
        components: {
            ButtonCta,
        },
        methods: {
            handleClick: function (e) {
                this.standaloneCtaTracking();
                window.location.href = '/account/user-flow/f-upsell/';
            },

            standaloneCtaTracking() {
                const trackingArgs = {
                    ctaText: this.buttonConfig.displayText,
                };

                tracking.trackAction('trackGetParamountStandalone', trackingArgs);
            },
        },
        computed: {
            ...mapState('pageAttributes', {
                headingCopy: (state) => state.attributes.aa_pap_bundle_option_heading,
                descriptionCopy: (state) => {
                    return state.attributes.aa_pap_bundle_option_description;
                },
                getCtaDisplayTextCopy: (state) => state.attributes.aa_pap_bundle_option_cta,
            }),

            getButtonConfig: function () {
                this.buttonConfig = Object.assign({}, this.buttonConfig);
                this.buttonConfig.styleClass = 'button secondary button__extra-large';
                this.buttonConfig.displayText = this.getCtaDisplayTextCopy;
                this.buttonConfig.tabIndex = '0';
                this.buttonConfig.event = this.handleClick;

                return this.buttonConfig;
            },
        },
    };
</script>
