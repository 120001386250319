import { getMVPDAuthZObj, getMVPDCMObj } from 'helpers/mvpdStorage';

let http;

export default {
    paths: {
        mvpdHome: '/live-tv/stream/tveverywhere/',
        bindGhostAccount: '/live-tv/stream/tveverywhere/user/bind/',
        unbindGhostAccount: '/live-tv/stream/tveverywhere/user/unbind/',
        checkMVPDIsBound: '/live-tv/stream/tveverywhere/user/check-mvpd-is-bound/',
        configBaseUrl: '/live-tv/stream/tveverywhere/mvpd-configs/',
    },

    setHttpService(httpService) {
        http = httpService;
    },

    getMVPDAuthZObj() {
        const mvpd_authz = getMVPDAuthZObj();
        const mvpd_cm = getMVPDCMObj();

        if (mvpd_authz) {
            mvpd_authz.householdID = mvpd_cm ? mvpd_cm.householdID : null;

            return mvpd_authz;
        }

        return null;
    },

    /**
     * @param reqData (expecting tk_trp)
     *
     * @returns {Promise<unknown>}
     */
    bindUser(reqData) {
        let tokenObj = this.getMVPDAuthZObj();

        reqData.mvpdId = tokenObj.mvpd_name;
        reqData.shortMediaToken = encodeURIComponent(tokenObj.token);

        if (tokenObj.householdID) {
            reqData.householdID = tokenObj.householdID;
        }

        let path = this.paths.bindGhostAccount;

        return new Promise((resolve, reject) => {
            http.doPost(path, reqData)
                .then((val) => {
                    if (val.success) {
                        resolve(val.data);
                    } else {
                        reject(val);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    unbindUser(reqData) {
        let tokenObj = this.getMVPDAuthZObj();

        reqData.mvpdId = tokenObj.mvpd_name;
        reqData.shortMediaToken = encodeURIComponent(tokenObj.token);

        if (tokenObj.householdID) {
            reqData.householdID = tokenObj.householdID;
        }

        let path = this.paths.unbindGhostAccount;

        return new Promise((resolve, reject) => {
            http.doPost(path, reqData)
                .then((val) => {
                    if (val.success) {
                        resolve(val.data);
                    } else {
                        reject(val);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    async checkMVPDIsBound(reqData) {
        let mvpdObj = this.getMVPDAuthZObj();

        if (mvpdObj) {
            try {
                reqData.mvpdId = mvpdObj.mvpd_name;
                reqData.shortMediaToken = encodeURIComponent(mvpdObj.token);

                if (mvpdObj.householdID) {
                    reqData.householdID = mvpdObj.householdID;
                }

                let path = this.paths.checkMVPDIsBound;

                let res = await http.doPost(path, reqData);

                if (res.data.result && typeof res.data.result === 'object') {
                    return res.data.result.isUserBounded;
                }
            } catch (err) {}
        }

        return null;
    },

    getConfig(name) {
        let path = this.paths.configBaseUrl + name + '/';

        return new Promise((resolve, reject) => {
            http.doGet(path)
                .then((val) => {
                    if (val.success) {
                        resolve(val.data);
                    } else {
                        reject(val);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};
