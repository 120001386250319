import { ACCOUNT_XHR_BASE_PATH } from 'aa/vue/constants/aaRoutes';
import Feature from 'aa/helpers/featureConstants';

export default {
    namespaced: true,
    state: {
        isShown: false,
        currentModal: null,
        currentModalData: null,
        cancelHasBeenViewed: false, // cancel only shown once per session
        confirmSwitchToAnnual: false,
        additionalClasses: '',
        modalCloseEnabled: true,
        confirmSwitchPlan: false,
        pageAttributes: {
            CancelSubModal: null,
        },
        displayCloseButton: true,
    },
    mutations: {
        updateCurrentModal(state, value) {
            state.currentModal = value;
        },
        updateCurrentModalData(state, value) {
            state.currentModalData = value;
        },
        updatePageAttributesForModal(state, payload) {
            state.pageAttributes[payload.modal] = payload.pageAttributes;
        },
        updateIsShown(state, value) {
            state.isShown = value;
        },
        updateCancelShown(state) {
            state.cancelHasBeenViewed = true;
        },
        updateConfirmSwitchToAnnual(state, value) {
            state.confirmSwitchToAnnual = value;
        },
        setAdditionalClasses(state, value) {
            state.additionalClasses = value;
        },
        setModalCloseEnabled(state, bool) {
            state.modalCloseEnabled = bool;
        },
        updateConfirmSwitchPlan(state, value) {
            state.confirmSwitchPlan = value;
        },
        updateDisplayCloseButton(state, value) {
            state.displayCloseButton = value;
        },
    },
    actions: {
        setModal(context, { modalName, data }) {
            context.commit('updateCurrentModalData', data);
            context.commit('updateCurrentModal', modalName);
            context.commit('updateIsShown', true);
        },

        async displayRetentionOffer(context) {
            if (context.state.cancelHasBeenViewed) {
                return false;
            }

            const isEligible = await context.dispatch('checkCancelOfferEligibility');

            if (isEligible) {
                await context.dispatch('setModal', { modalName: 'CancelSubModal' });
                await context.dispatch('setCancelFlag');
            }

            return isEligible;
        },
        /**
         * @param context
         * @returns {Promise<boolean>}
         */
        async checkCancelOfferEligibility(context) {
            try {
                const featureEnabled = context.rootGetters.getServerDataByKey('redemptionCoupon');
                const user = context.rootState.user;
                const planNotEligible =
                    user.isAnnualPlan || user.svod?.recurly_package?.addOns?.[0]?.vendorAddOnCode; // has v1 showtime addon

                if (!featureEnabled || planNotEligible) {
                    return false;
                }

                const res = await this.$http.doPost(
                    `${ACCOUNT_XHR_BASE_PATH}retention/eligibility/`,
                    {
                        tk_trp: context.rootGetters.getServerDataByKey('authToken'),
                    },
                );

                if (Feature.multipleEntitlementsEnabled()) {
                    context.commit('updatePageAttributesForModal', {
                        modal: 'CancelSubModal',
                        pageAttributes: res.data?.pageAttributes,
                    });
                }

                return Boolean(res.success);
            } catch (e) {}

            return false;
        },
        setIsShown(context, value) {
            context.commit('updateIsShown', value);
        },
        setCancelFlag(context) {
            context.commit('updateCancelShown');
            sessionStorage.setItem('cancelHasBeenViewed', 'true');
        },
        setConfirmSwitchToAnnual(context, value) {
            context.commit('updateConfirmSwitchToAnnual', value);
        },
        setConfirmSwitchPlan(context, value) {
            context.commit('updateConfirmSwitchPlan', value);
        },
        setDisplayCloseButton(context, value) {
            context.commit('updateDisplayCloseButton', value);
        },
    },
    getters: {
        getModal: (state) => {
            return state.currentModal;
        },
    },
};
