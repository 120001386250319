import User from 'aa/vue/models/User';
import HttpService from 'services/HttpService';
import {
    USERTYPE_ANONYMOUS,
    USER_STATUS_ANON,
    USERTYPE_EX_SUBSCRIBER,
    USERTYPE_REGISTERED,
    USERTYPE_SUBSCRIBER,
} from 'aa/vue/constants/shared';
import { AA_XHR_BASE_PATH } from 'aa/vue/constants/aaRoutes';
import CheckoutOptions from 'aa/vue/models/CheckoutOptions';

export default {
    namespaced: true,

    state: new User(),

    mutations: {
        parseUserData(state, data) {
            if (data) {
                let UserModel = new User();
                if (typeof data === 'object') {
                    for (let key in data) {
                        if (data.hasOwnProperty(key) && UserModel.hasOwnProperty(key)) {
                            state[key] = data[key];
                        }
                    }

                    CheckoutOptions.validateUserOptions(state.regID);
                } else {
                    console.warn('You did not pass setUserData an object');
                    state.needsUpdate = true;
                }
            } else {
                state.needsUpdate = true;
            }
        },

        needsUpdate(state, data) {
            state.needsUpdate = data;
        },
    },

    getters: {
        getDisplayName: (state) => {
            if (
                !state.profile.hasOwnProperty('first_name') ||
                !state.profile.hasOwnProperty('last_name')
            ) {
                return '';
            }
            return state.profile.first_name + ' ' + state.profile.last_name.substring(0, 1) + '.';
        },

        getProfileType: (state) => {
            return state.profile.profile_type;
        },

        firstName: (state) => {
            if (state.profile) {
                return state.profile.first_name;
            }

            return null;
        },

        lastName: (state) => {
            if (state.profile) {
                return state.profile.last_name;
            }

            return null;
        },

        isSubscriber: (state) => {
            if (state.isSubscriber) {
                return state.isSubscriber;
            }

            return null;
        },

        isExSubscriber: (state) => {
            if (state.isExSubscriber) {
                return state.isExSubscriber;
            }

            return null;
        },

        isMVPD: (state) => {
            return state.isMVPD || state.svod?.user_package?.source === 'mvpd' || null;
        },

        isPartnerSubscription: (state) => {
            return state.isPartnerSubscription || null;
        },

        isLoggedIn: (state) => {
            if (state.isLoggedIn) {
                return state.isLoggedIn;
            }
            return false;
        },

        isNoTrialUser: (state) => {
            return state.isExSubscriber || state.isMVPDAuthZExSub;
        },

        eduProfile: (state) => {
            return state.edu && typeof state.edu === 'object' ? state.edu.profile : null;
        },

        eduCoupon: (state) => {
            return state.edu && typeof state.edu === 'object' ? state.edu.coupon : null;
        },

        trackingUserStatus: (state) => {
            return state.svod.package_status ?? USER_STATUS_ANON;
        },

        getUserPackageStatus: (state) => {
            return state.svod.user_package.status ?? '';
        },

        trackingUserType: (state) => {
            if (state.isRegistered) {
                return USERTYPE_REGISTERED;
            } else if (state.isSubscriber) {
                return USERTYPE_SUBSCRIBER;
            } else if (state.isExSubscriber) {
                return USERTYPE_EX_SUBSCRIBER;
            } else {
                return USERTYPE_ANONYMOUS;
            }
        },
        getRegistrationCountry: (state) => {
            return state.userRegistrationCountry?.toLowerCase() || null;
        },
    },

    actions: {
        setUserData(context) {
            return new Promise((resolve, reject) => {
                if (context.state.needsUpdate) {
                    this.$http.doGet(`${AA_XHR_BASE_PATH}user/`).then((res) => {
                        if (res.success) {
                            context.commit('parseUserData', res.data.result.aaUser);

                            const activeProfile = res?.data?.result?.activeProfile;

                            if (activeProfile) {
                                //Update the global profile info
                                CBS.Registry.user.profile_id = activeProfile.profileId;
                                CBS.Registry.user.profile_type = activeProfile.profileType;
                                CBS.Registry.user.userProfileMaster = activeProfile.isMasterProfile;
                            }
                        }
                        if (res.data.result.edu && res.data.result.edu.coupon) {
                            context.commit('setPromo', res.data.result.aaUser.edu.coupon, {
                                root: true,
                            });
                        }
                        resolve(res.data.result.aaUser);
                    });
                } else {
                    resolve(context.state);
                }
            });
        },

        loadServerData({ commit, rootGetters }) {
            let user = rootGetters.getServerDataByKey('user');

            if (user) {
                commit('parseUserData', user);
            }
        },

        relogin(context, payload) {
            return new Promise((resolve, reject) => {
                let http = new HttpService();

                payload.tk_trp = context.rootState.token;

                http.doPost(`${AA_XHR_BASE_PATH}relogin/`, payload).then((res) => {
                    if (res.success) {
                        resolve(res);
                    } else {
                        reject(res);
                    }
                });
            });
        },
    },
};
