<template>
    <section>
        <div class="padded-container">
            <div class="dma-dispute__title">{{ getTitle }}</div>
        </div>
        <div class="grid-item text-center span-cols">
            <div class="dma-dispute__subtitle" v-if="getSubTitle" v-html="getSubTitle"></div>

            <ButtonCta :buttonConfig="ctaConfig"></ButtonCta>
        </div>
    </section>
</template>

<script>
    import ButtonCta from 'atoms/ButtonCta';
    import ButtonConfig from 'models/ButtonConfig';
    import { mapState } from 'vuex';
    export default {
        name: 'DMADispute',
        components: {
            ButtonCta,
        },
        mounted() {
            this.$store.commit('modal/setModalCloseEnabled', false);
            this.$store.commit('modal/setAdditionalClasses', 'dma-dispute');
        },
        computed: {
            ...mapState('user', {
                mvpdDispute: (state) => {
                    return state.mvpdDispute;
                },
            }),
            ...mapState('pageAttributes', {
                pageAttributes: (state) => state.attributes,
            }),
            ctaConfig() {
                return new ButtonConfig({
                    displayText: 'OK',
                    event: this.goToUpSell,
                    styleClass: 'button',
                });
            },
            getTitle() {
                if (this.getProvider) {
                    let title = 'mvpd_dma_dispute_title_' + this.getProvider;
                    if (this.pageAttributes[title]) {
                        return this.pageAttributes[title];
                    }
                }
                return '';
            },
            getSubTitle() {
                if (this.getProvider) {
                    let subTitle = 'mvpd_dma_dispute_subtitle_' + this.getProvider;
                    if (this.pageAttributes[subTitle]) {
                        return this.getReplacedText(subTitle);
                    }
                }
                return '';
            },
            getProvider() {
                let providerName = this.mvpdDispute?.mvpdProvider.toLowerCase();
                if (providerName) {
                    return providerName;
                }
                return false;
            },
        },
        methods: {
            goToUpSell() {
                window.location.href = '/';
            },
            getHref: function () {
                let link = 'mvpd_dma_dispute_link_' + this.getProvider;
                let text = 'mvpd_dma_dispute_link_title_' + this.getProvider;
                if (this.pageAttributes[link] && this.pageAttributes[text]) {
                    return `<a href=\"${this.pageAttributes[link]}\" target=\"_blank\" class="faq" >${this.pageAttributes[text]}</a>`;
                }
                return '';
            },
            getReplacedText(subTitle) {
                return this.pageAttributes[subTitle].replace('{link}', this.getHref());
            },
        },
    };
</script>
