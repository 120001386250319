import Breakpoints from 'helpers/breakpoints';

export default class AAUpsellCenterContent {
    constructor() {
        this.breakpoints = new Breakpoints();

        this.contentClassName = 'js-content';
        this.carouselClassName = 'js-le-carousel';

        // containers to ignore
        this.classListToSkip = ['hero', 'its-on'];

        this.tabletLandscapeMin = this.breakpoints.getMin('tabletLandscape');

        this.skipContainer = false;
        this.foundCarousel = false;

        window.addEventListener('DOMContentLoaded', () => {
            this.init();
        });
    }

    init() {
        window.addEventListener('resize', () => {
            this.handleResize();
        });
        this.verticallyAlignContentWithCarousels();
    }

    handleResize() {
        this.verticallyAlignContentWithCarousels();
    }

    verticallyAlignContentWithCarousels() {
        let sections = document.querySelectorAll('section');
        let currentBreakpointName = this.breakpoints.getCurrentBreakpointName();
        let currentBreakpointMin = this.breakpoints.getBreakpoint(currentBreakpointName).min;

        sections.forEach((el) => {
            if (currentBreakpointMin >= this.tabletLandscapeMin) {
                this.checkSection(el);
            } else {
                this.removeStyles(el);
            }
        });
    }

    checkSection(el) {
        this.skipContainer = false;
        this.foundCarousel = false;

        // ensure we're skipping hero and it's on buckets
        this.classListToSkip.forEach((className) => {
            if (!this.skipContainer) {
                this.skipContainer = this._hasClass(el, className);
            }
        });

        // now make sure the container has a carousel
        if (!this.skipContainer) {
            this.foundCarousel = this._findChild(el, this.carouselClassName);
        }

        if (this.foundCarousel) {
            this._reposition(el);
        }
    }

    removeStyles(el) {
        let content = el.querySelector('.' + this.contentClassName);
        if (content) {
            content.style.position = '';
            content.style.topp = '';
        }
    }

    _hasClass(el, className) {
        return el.classList.contains(className);
    }

    _findChild(el, className) {
        return el.querySelector('.' + className) !== null;
    }

    _reposition(el) {
        let content = el.querySelector('.' + this.contentClassName);
        let carousel = el.querySelector('.' + this.carouselClassName);

        if (content) {
            let sectionHeight = this._getComputedStyle(el, 'height');
            let contentHeight = this._getComputedStyle(content, 'height');
            let carouselHeight = this._getComputedStyle(carousel, 'height');
            let carouselFromBottom = this._getComputedStyle(carousel, 'bottom');

            let space = sectionHeight - carouselHeight - carouselFromBottom;
            let top = (space - contentHeight) / 2;

            content.style.position = 'absolute';
            content.style.top = top + 'px';
        }
    }

    _getComputedStyle(el, prop) {
        return parseInt(getComputedStyle(el)[prop]);
    }
}
