<template id="UserMessage">
    <section class="interstitial-steps-sections">
        <div class="interstitial-steps-wrapper message-step">
            <h2 class="h2--secondary" v-html="heading" data-ci="error_heading"></h2>
            <h3
                v-if="subHeading"
                class="h3--secondary"
                v-html="subHeading"
                data-ci="error_subheading"></h3>

            <div class="interstitial_button" data-ci="error_button">
                <a
                    class="button primary"
                    :class="primaryButton.class"
                    :href="primaryButton.link"
                    v-on:click="primaryButton.clickEvent ? primaryButton.clickEvent : {}">
                    <span class="button__text">{{ primaryButton.label }}</span>
                </a>
            </div>
            <div v-if="secondaryButton" class="interstitial_button">
                <a class="button secondary" :href="secondaryButton.link">
                    <span class="button__text">{{ secondaryButton.label }}</span>
                </a>
            </div>
        </div>
    </section>
</template>

<script>
    import isEmpty from 'lodash/isEmpty';
    import commonMixins from 'aa/vue/plugins/account/commonMixins';
    import UserMenu from 'components/userMenu';
    import { PAGE_TYPES } from 'aa/vue/constants/aaTrackingConstants';
    import { ACTION_NAMES, APP_LOG_TYPE, tracking } from 'services/Tracking';

    export default {
        name: 'user-message',
        mixins: [commonMixins],
        props: {
            heading: {
                type: String,
                default() {
                    return this.$getLocale('oops_something_went_wrong');
                },
            },
            subHeading: String,
            primaryButton: {
                type: Object,
                default() {
                    return {
                        label: this.$getLocale('close'),
                        link: '/',
                        clickEvent: () => {},
                    };
                },
            },
            secondaryButton: {
                type: Object,
                label: String,
                link: String,
            },
        },
        mounted() {
            const mvpdCode = this.$route?.query?.mvpdCode;
            if (mvpdCode) {
                tracking.trackState({
                    pageType: PAGE_TYPES.PPLUS_NO_ACCESS,
                    screenName: PAGE_TYPES.PPLUS_NO_ACCESS,
                    authPartnerId: mvpdCode,
                });

                tracking.trackAction(ACTION_NAMES.APP_LOG, {
                    linkName: ACTION_NAMES.APP_LOG,
                    appLogText: this.subHeading,
                    appLogType: APP_LOG_TYPE.APP_LOG_ERROR_TYPE,
                    appLogCode: '0',
                    authPartnerId: mvpdCode,
                    pageType: PAGE_TYPES.PPLUS_NO_ACCESS,
                    screenName: PAGE_TYPES.PPLUS_NO_ACCESS,
                });
            }
        },
    };
</script>
