export const PAYMENT_ANNUAL_UPSELL_VARIANTS = Object.freeze({
    CONTROL: 'control',
    CHECKBOX: 'annual_checkbox',
    CTA: 'annual_cta',
});

export const AbTestRelated = {};

// START TEST PLOCTOPLUS-1298: google captcha
export const CAPTCHA_GOOGLE_VARIANTS = Object.freeze({
    CONTROL: 'control',
    GOOGLE: 'google',
});
export const FRAUD_PROTECTION_GOOGLE_ENABLED = 'fraud_protection_google_enabled';
// END TEST PLOCTOPLUS-1298: google captcha
