import CheckoutOptions from 'aa/vue/models/CheckoutOptions';

export default class AAUpsellShowtimeBundle {
    constructor() {
        document.addEventListener('click', (e) => {
            const anchor = e.target.closest('a');

            if (anchor && typeof anchor.href === 'string') {
                let flowURL = anchor.href.match(/\/account\/(flow|user-flow)\/([^?\/]+)/);

                if (flowURL) {
                    const cbscidmt = new URLSearchParams(window.location.search).get('cbscidmt');
                    const isBundleCampaign = cbscidmt === 'showtimebundle';
                    const isShowtimeBannerCTA = anchor.classList.contains('showtime-bundle-cta');

                    const options = new CheckoutOptions({
                        addonShowtime: isBundleCampaign || isShowtimeBannerCTA,
                        userID: CBS.Registry.user?.id,
                        flowType: flowURL[2] ?? null,
                    });

                    options.save();
                }
            }
        });
    }
}
