import ResetPasswordComponent from 'aa/vue/components/ResetPassword';
import Step from 'models/steps/Step';
import { ACCOUNT_BASE_PATH } from 'vue/constants/svodRoutes';

export default class ForgotPassword extends Step {
    path = `${ACCOUNT_BASE_PATH}resetpassword/:token/`;
    name = 'RESET_PASSWORD';
    component = ResetPasswordComponent;
    requireAuth = false;
    requireNonAuth = false;
}
