<template>
    <div
        v-if="providersToDisplay && providersToDisplay.length"
        class="providers-list"
        :class="{ 'providers-list--two-col': isDomestic }"
        aa-region="live tv">
        <div
            v-for="provider in providersToDisplay"
            :key="provider.code"
            class="providers-list__item">
            <!-- TODO: selection to be handled... -->
            <a
                href="#"
                :aa-link="aaLink(provider.primaryName)"
                :data-providerid="provider.code"
                :data-providername="provider.primaryName"
                @click="providerSelected(provider)">
                <img :src="provider.pickerLogoUrl" :alt="altLogo(provider.primaryName)" />
            </a>
        </div>
    </div>
</template>

<script>
    import { ACTION_NAMES, tracking } from 'services/Tracking';
    import Domain from 'helpers/Domain';

    export default {
        name: 'MvpdGrid',
        props: {
            providers: {
                type: Array,
            },
            allProviders: {
                type: Array,
            },
            isDeeplink: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            aaLink: () => (providerName) => {
                return `live tv|mvpd|${providerName}`;
            },
            altLogo: () => (providerName) => {
                return `logo ${providerName}`;
            },
            providersToDisplay: function () {
                if (this.isDeeplink) {
                    const providerID =
                        this.$route.params.TVProviderID || this.$route.query.mvpdCode;

                    if (typeof providerID !== 'string') {
                        return [];
                    }

                    return this.allProviders.filter((provider) => {
                        return provider.code.toLowerCase() === providerID.toLowerCase();
                    });
                } else {
                    return this.providers;
                }
            },
            isDomestic() {
                return Domain.isDomestic();
            },
        },
        methods: {
            providerSelected(provider) {
                this.$emit('selected', {
                    value: {
                        clickLabel: this.altLogo(provider.primaryName),
                        mvpdPartnerId: provider.code,
                        mvpdPartnerName: provider.primaryName,
                    },
                });
            },
        },
    };
</script>
