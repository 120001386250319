<template>
    <div class="showtime-bundle-wrapper shadow" ref="showtimeBundleRef">
        <div class="showtime-bundle">
            <div class="showtime-bundle-items display-logo" v-if="!bundleShowtime">
                <div class="showtime-bundle__logo"></div>
            </div>
            <div class="showtime-bundle-items">
                <div class="showtime-bundle__text" :class="getShowtimeBundleTextClass">
                    <div class="showtime-bundle__text-heading">
                        {{ headingCopy }}
                    </div>
                    <div class="showtime-bundle__text-description">
                        {{ descriptionCopy }}
                    </div>
                </div>
            </div>
            <div class="showtime-bundle-items">
                <div class="showtime-bundle__cta">
                    <ButtonCta :buttonConfig="getButtonConfig"></ButtonCta>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ButtonCta from 'atoms/ButtonCta';
    import { ShowtimeBundleConfig } from 'aa/vue/FormConfig';
    import { mapState, mapGetters } from 'vuex';
    import { tracking } from 'services/Tracking';

    export default {
        name: 'ShowtimeBundle',
        props: {
            bundleShowtime: {
                type: Boolean,
            },
        },
        data: function () {
            return {
                showtimeBundleConfig: ShowtimeBundleConfig,
                buttonConfig: {},
                observer: null,
                displayShadow: true,
            };
        },
        components: {
            ButtonCta,
        },

        mounted() {
            window.addEventListener('scroll', this.handleScroll);
            window.addEventListener('resize', this.handleScroll);
        },

        methods: {
            handleScroll: function (e) {},

            handleResize: function (e) {},

            handleClick: function (e) {
                // TODO: navigate to showtime bundle
                // TODO: tracking
                this.showtimeBundleStandAloneBtnTracking(e);

                this.$emit('toggleBundle');
            },

            showtimeBundleStandAloneBtnTracking(e) {
                const trackingParamsBtn = {
                    ctaText: e.target.innerText.trim(),
                };

                // add tracking parameter if showtime bundle
                if (!this.bundleShowtime) {
                    utag_data.partnerBundle = 'showtime';
                } else {
                    delete utag_data.partnerBundle;
                }

                tracking.trackAction('trackGetParamountStandalone', trackingParamsBtn);
            },
        },
        computed: {
            ...mapState('pageAttributes', {
                pageAttr: (state) => state.attributes,
            }),

            ...mapGetters('flow', ['isCampaign']),

            getShowtimeBundleTextClass() {
                if (this.bundleShowtime) {
                    return 'bundled';
                }
            },

            getCtaDisplayTextCopy() {
                if (this.bundleShowtime) {
                    return this.pageAttr.aa_pap_bundle_option_cta_showtime;
                } else {
                    return this.pageAttr.aa_pap_bundle_option_cta;
                }
            },

            headingCopy() {
                if (this.bundleShowtime) {
                    return this.pageAttr.aa_pap_bundle_option_heading_showtime;
                } else {
                    return this.pageAttr.aa_pap_bundle_option_heading;
                }
            },

            hasPlanSuppression() {
                return (
                    this.isCampaign &&
                    (this.pageAttr.hide_monthly_annual_toggle ||
                        this.pageAttr.hide_essential_plan ||
                        this.pageAttr.hide_premium_plan)
                );
            },

            descriptionCopy() {
                let suppressionSuffix = this.hasPlanSuppression ? '_plan_suppressed' : '';
                let bundleSuffix = this.bundleShowtime ? '_showtime' : '';

                return this.pageAttr[
                    `aa_pap_bundle_option_description${suppressionSuffix}${bundleSuffix}`
                ];
            },

            getButtonConfig: function () {
                this.buttonConfig = this.showtimeBundleConfig.buttonConfig;
                this.buttonConfig.displayText = this.getCtaDisplayTextCopy;
                this.buttonConfig.event = this.handleClick;
                return this.buttonConfig;
            },
        },
    };
</script>
