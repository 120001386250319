/**
 * DEPENDENCY: CBS.Registry.region
 */

const LOCALES = {
    us: 'en-US',
    ca: 'en-US', // same with US by design
    au: 'en-AU',
};

const defaultDateFormat = { year: 'numeric', month: '2-digit', day: '2-digit' };

const addTimeUnit = (endDate, amount, unit) => {
    switch (unit) {
        case 'day':
        case 'days':
            endDate.setDate(endDate.getDate() + amount);
            break;
        case 'week':
        case 'weeks':
            endDate.setDate(endDate.getDate() + amount * 7);
            break;
        case 'month':
        case 'months':
            endDate.setMonth(endDate.getMonth() + amount);
            break;
        case 'year':
        case 'years':
            endDate.setFullYear(endDate.getFullYear() + amount);
            break;
    }
    return endDate;
};

const getEndDate = (amount, unit) => {
    amount = parseInt(amount);
    let endDate = new Date();
    return addTimeUnit(endDate, amount, unit);
};

/**
 * add number of months in a current date and return enddate
 */

const addCouponDiscountPeriod = (endDate, period, timeUnit) => {
    const dayOfCurrentMonth = endDate.getDate(); // return day of a month

    if (timeUnit !== 'day' && timeUnit !== 'week') {
        endDate.setDate(1);
    }

    endDate = addTimeUnit(endDate, period, timeUnit);

    if (timeUnit !== 'day' && timeUnit !== 'week') {
        endDate.setDate(
            Math.min(
                dayOfCurrentMonth,
                new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0).getDate(),
            ),
        );
    }

    return endDate;
};

class DateFormatter {
    /**
     * Giving a raw string, returning back formatted date based on i18n
     * Format is based on https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
     * @param {Date} date
     * @param {Object} [options: {year: 'numeric', month: '2-digit', day: '2-digit'}] - Options to pass to Intl.DateTimeFormat
     * @returns {string}
     */
    static format(date, options = defaultDateFormat, locale = 'en-US') {
        const property = CBS.Registry.region.property.toLowerCase();
        if (Object.keys(LOCALES).includes(property)) {
            locale = LOCALES[property];
        }

        const opts = { ...defaultDateFormat, ...options };

        return new Intl.DateTimeFormat(locale, opts).format(date);
    }
}

export { DateFormatter, getEndDate, addCouponDiscountPeriod };
