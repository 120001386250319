export default class User {
    constructor() {
        //
        // SET FROM CALL TO SERVER
        //
        this.entitlement = {};
        this.isLoggedIn = false;
        this.displayName = '';
        this.regID = null; // tracking

        /**
         * profile
         * @type {Object}
         */
        this.profile = {};

        /**
         * svod
         * @type {Object}
         */
        this.svod = {
            user_package: {
                product_tier: -1,
            },
        };

        this.statusCode = 'anon';

        /**
         * isSubscriber
         * @type {boolean}
         */
        this.isSubscriber = false;

        /**
         * isThirdParty
         * @type {boolean}
         */
        this.isThirdParty = false;

        /**
         * isExSubscriber
         * @type {boolean}
         */
        this.isExSubscriber = false;

        /**
         * isSuspended
         * @type {boolean}
         */
        this.isSuspended = false;

        /**
         * isGhost
         * @type {boolean}
         */
        this.isGhost = false;

        /**
         * isMVPDAuthZ
         * @type {boolean}
         */

        this.isMVPDAuthZ = false;

        /**
         * @type {boolean}
         */
        this.isMVPDAuthZExSub = false;

        /**
         * isActive
         * @type {boolean}
         */
        this.isActive = false;

        /**
         * isPartnerSubscriptiob
         * @type {boolean}
         */
        this.isPartnerSubscription = false;

        /**
         * isReseller
         * @type {boolean}
         */
        this.isReseller = false;

        /**
         * isRecurly
         * @type {boolean}
         */
        this.isRecurly = false;

        /**
         * isLC
         * @type {boolean}
         */
        this.isLC = false;

        /**
         * isCF
         * @type {boolean}
         */
        this.isCF = false;

        /**
         * is complementary user account
         * @type {boolean}
         */
        this.isCompUser = false;

        /**
         * is a registered account
         * @type {boolean}
         */
        this.isRegistered = false;

        /**
         *
         * @type {boolean}
         */
        this.isOptimum = false;

        /**
         * is logged in with unsupported vendor
         * @type {boolean}
         */
        this.isUnsupportedVendor = false;

        /**
         * is monthly subscriber
         * @type {boolean}
         */
        this.isMonthlyPlan = false;

        /**
         * is annual subscriber
         * @type {boolean}
         */
        this.isAnnualPlan = false;

        /**
         * can edit user profile
         * @type {boolean}
         */
        this.canEdit = false;

        /**
         * can provide native link to vendor devices
         * @type {boolean}
         */
        this.provideNativeDeviceSubSettingsLink = false;

        /**
         * does user data need update? (false if it was provided in server data)
         * @type {boolean}
         */
        this.needsUpdate = false;
        this.edu = null;

        /**
         * Tells us whether the user is an MVPD user or not
         * @type {boolean}
         */
        this.isMVPD = false;

        this.userRegistrationCountry = null;

        /**
         * Helper to check if the user region is on sunset state
         * @type {boolean}
         */
        this.isUserRegionOnSunset = false;
        this.tags = {};
        /**
         * this will let us know user has MVPD DMA Dispute OR not
         * @type {Object}
         */
        this.mvpdDispute = {};
    }
}
