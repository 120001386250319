import HttpService from 'services/HttpService';
import { AA_XHR_BASE_PATH } from 'aa/vue/constants/aaRoutes';

export default {
    getPlans: function () {
        return new Promise(function (resolve, reject) {
            let http = new HttpService();

            http.doGet(`${AA_XHR_BASE_PATH}products/`)
                .then((res) => {
                    if (Array.isArray(res.data.result)) {
                        resolve(res.data.result);
                    } else {
                        reject(res);
                    }
                })
                .catch((err) => {
                    // console.error('Error in PlanService getPlans:', err);
                    reject(err);
                });
        });
    },
};
