<template id="ResetPassword">
    <section class="padded-container">
        <div class="reset-password-grid">
            <div class="grid-item">
                <h3 class="steps-header">{{ heading }}</h3>
                <p>{{ subHeading }}</p>
                <p v-if="formMessage" class="form-message">{{ formMessage }}</p>
                <p v-if="isSuccess" class="form-message">
                    {{
                        this.$getLocale('success_your_password_has_been_reset', {
                            countDown: countDown,
                        })
                    }}
                </p>
                <p v-if="formMessageError" role="alert" class="form-message error">
                    {{ formMessageError }}
                </p>
            </div>

            <form
                id="reset-password-form"
                autocomplete="off"
                v-on:submit.stop.prevent="handleSubmit">
                <div class="form-wrapper">
                    <div
                        v-for="(value, key, index) in formConfig.inputList"
                        :key="key"
                        class="grid-item cbs-text-input span-cols">
                        <LabelInput
                            :inputConfig="value"
                            v-model="form[key]"
                            :class="{ error: isInError(key) }"
                            @showHidePasswordStatus="handleShowHidePasswordStatus" />
                        <div
                            role="alert"
                            :class="textInputErrorCSS(key)"
                            v-for="error in errors"
                            v-if="error[key]">
                            {{ error[key] }}
                        </div>
                    </div>

                    <div class="grid-item text-center span-cols">
                        <ButtonCta
                            :buttonConfig="buttonConfig"
                            :class="{ disabled: !validFormSubmit }" />
                    </div>

                    <InputElm
                        v-for="(value, key) in formConfig.hiddenInputList"
                        :inputConfig="value"
                        v-bind:key="formConfig.hiddenInputList.ref"
                        v-model="form[key]" />
                </div>
            </form>

            <div class="grid-item text-center">
                <a
                    @click="handleCancelRedirect"
                    @keydown.enter.prevent="handleCancelRedirect"
                    class="form-link qt-cancelbtn"
                    tabindex="0">
                    {{ this.$getLocale('cancel') }}
                </a>
            </div>
        </div>
    </section>
</template>

<script>
    import StepsHeader from 'aa/vue/components/StepsHeader';
    import InputElm from 'atoms/InputElm';
    import LabelInput from 'atoms/LabelInput';
    import ButtonCta from 'atoms/ButtonCta';
    import { ResetPassConfig } from 'aa/vue/FormConfig';
    import { AA_XHR_BASE_PATH, SIGNIN_URL, ACCOUNT_BASE_PATH } from 'aa/vue/constants/aaRoutes';
    import Domain from 'helpers/Domain';
    import { ACTION_NAMES, APP_LOG_TYPE, tracking } from 'services/Tracking';
    import { PASSWORD_RESET_FLOW } from 'helpers/featureConstants';
    import { mapGetters } from 'vuex';
    import { navigateToUrlWithDelay } from 'aa/vue/components/account/helpers';
    import { actionName } from 'aa/services/AccountPageTrackingService';
    const timeoutTime = 5000;
    const oneSecond = 1000;

    export default {
        props: {
            isChangePasswordFlow: {
                type: Boolean,
                default: false,
                required: false,
            },
        },
        data: function () {
            return {
                subHeading: this.$getLocale('enter_and_confirm_new_password'),
                form: {
                    token: {
                        value: this.sanitizeStr(this.$route.params.token),
                    },
                },
                errors: [],
                buttonConfig: Object.assign(ResetPassConfig.buttonConfig, {
                    event: this.handleSubmit,
                }),
                formMessage: '',
                formMessageError: '',
                countDown: timeoutTime / oneSecond,
                isSuccess: false,
                passIsVisible: 0,
            };
        },
        methods: {
            handleShowHidePasswordStatus(payload) {
                this.passIsVisible = Number(payload);
                tracking.trackAction(
                    this.passIsVisible
                        ? ACTION_NAMES.SHOWHIDE_PASSWORD_SHOW
                        : ACTION_NAMES.SHOWHIDE_PASSWORD_HIDE,
                    {
                        passIsVisible: this.passIsVisible.toString(),
                    },
                );
            },
            handleSubmit(e) {
                let checkForm = this.checkForm(e);
                if (checkForm) {
                    this.handleValidForm();
                }

                tracking.trackAction(ACTION_NAMES.TRACK_RESET_PASSWORD, {
                    ctaText: this.buttonConfig?.displayText,
                    passIsVisible: this.passIsVisible.toString(),
                });
            },
            handleRequired(e) {
                let checkForm = this.checkFormRequired(e);
                if (checkForm) {
                    this.buttonConfig.styleClass = this.buttonConfig.styleClass.filter(
                        (cssClass) => cssClass !== 'disabled',
                    );
                }
            },
            checkForErrors: function () {
                this.errors = [];

                if (!this.form.password || this.form.password.value == '') {
                    this.errors.push({
                        password: this.$getLocale('password_required'),
                    });
                } else if (this.form.password.value.length < 6) {
                    this.errors.push({
                        password: this.$getLocale('password_is_too_short'),
                    });
                } else if (this.form.password.value.length > 30) {
                    this.errors.push({
                        password: this.$getLocale('password_is_too_long'),
                    });
                }

                if (!this.form.passwordConfirm || this.form.passwordConfirm.value == '') {
                    this.errors.push({
                        passwordConfirm: this.$getLocale('password_confirm_required'),
                    });
                } else if (this.form.passwordConfirm.value !== this.form.password.value) {
                    this.errors.push({
                        passwordConfirm: this.$getLocale('password_and_confirm_must_match'),
                    });
                }
            },
            checkForm: function (e) {
                e.preventDefault();

                this.checkForErrors();
                //Length Rules

                //Is Sanitized

                if (this.errors.length === 0) {
                    this.formMessageError = '';
                    this.formMessage = '';
                    return true;
                } else {
                    this.buttonConfig.styleClass = this.buttonConfig.styleClass.filter(
                        (cssClass) => cssClass !== 'disabled',
                    );
                }
            },
            checkFormRequired: function (e) {
                if (
                    this.form.password &&
                    this.form.password.value !== '' &&
                    this.form.passwordConfirm &&
                    this.form.passwordConfirm.value !== ''
                ) {
                    return true;
                }
            },
            handleValidForm: function () {
                if (this.buttonConfig.styleClass.indexOf('disabled') >= 0) {
                    return;
                }
                this.buttonConfig.styleClass.push('disabled');

                const httpData = {};
                for (let key in this.form) {
                    if (this.form.hasOwnProperty(key)) {
                        httpData[key] = this.form[key].value;
                    }
                }
                if (this.hasFeatureActiveChangePasswordFlow) {
                    httpData.changePasswordFlow = true;
                }

                const that = this;
                this.$http.doPost(`${AA_XHR_BASE_PATH}reset-password/`, httpData).then((res) => {
                    if (res.success) {
                        that.formMessageError = '';
                        that.formMessage = '';

                        if (that.$store.getters['user/isLoggedIn']) {
                            this.handleSuccessRedirection();
                        } else {
                            if (Domain.isDomestic()) {
                                that.$router.push({ name: 'SIGNIN' });
                            } else {
                                that.isSuccess = true;
                                that.countDownTimer();
                                setTimeout(() => {
                                    // Setting the window.location instead of doing a $router.push()
                                    // forces the page to reload allowing the data set in php to update correctly.
                                    // This is mainly being done to prevent a user having the wrong region set after entering the app from /global/
                                    window.location.href = `${SIGNIN_URL}`;
                                }, timeoutTime);
                            }
                        }
                    } else {
                        that.buttonConfig.styleClass = that.buttonConfig.styleClass.filter(
                            (cssClass) => cssClass !== 'disabled',
                        );
                        that.formMessage = '';
                        that.formMessageError =
                            typeof res.message !== 'undefined' &&
                            res.message !== '' &&
                            res.message !== 'no error provided'
                                ? res.message
                                : that.$getLocale(
                                      'sorry_there_was_a_problem_resetting_the_password',
                                  );
                        // Track error
                        tracking.trackAction(ACTION_NAMES.APP_LOG, {
                            appLogText: that.formMessageError,
                            appLogType: APP_LOG_TYPE.RESET_PWD_ERROR,
                            appLogCode: res.errors,
                        });
                    }
                });
            },
            sanitizeStr: (str) => {
                const temp = document.createElement('div');
                temp.textContent = str;
                return temp.innerHTML;
            },
            textInputErrorCSS: (key) => {
                return 'text-input-error qt-err-' + key;
            },
            countDownTimer() {
                if (this.countDown > 0) {
                    setTimeout(() => {
                        this.countDown -= 1;
                        this.countDownTimer();
                    }, oneSecond);
                }
            },
            handleCancelRedirect() {
                if (this.hasFeatureActiveChangePasswordFlow) {
                    tracking.trackAction(ACTION_NAMES.TRACK_CANCEL_EDIT_PASSWORD, {
                        ctaText: this.$getLocale('cancel'),
                    });
                }

                if (this.$store.getters['user/isLoggedIn']) {
                    navigateToUrlWithDelay(`${CBS.Registry.region.prefix}${ACCOUNT_BASE_PATH}`);
                } else {
                    window.location.href = `${CBS.Registry.region.prefix}/`;
                }
            },
            handleSuccessRedirection() {
                if (this.hasFeatureActiveChangePasswordFlow) {
                    navigateToUrlWithDelay(
                        `${CBS.Registry.region.prefix}${ACCOUNT_BASE_PATH}?notification=password-changed`,
                    );
                } else {
                    window.location.href = `${CBS.Registry.region.prefix}${SIGNIN_URL}`;
                }
            },
        },
        computed: {
            hasFeatureActiveChangePasswordFlow() {
                return this.featureIsActive(PASSWORD_RESET_FLOW) && this.isChangePasswordFlow;
            },
            heading() {
                if (this.hasFeatureActiveChangePasswordFlow) {
                    return this.$getLocale('change_your_password');
                }
                return this.$getLocale('reset_your_password');
            },
            isInError() {
                return (key) => {
                    if (this.errors.length === 0) {
                        return false;
                    }
                    const result = this.errors.filter((err) => typeof err[key] !== 'undefined');
                    return result.length !== 0;
                };
            },
            formConfig() {
                return ResetPassConfig;
            },
            validFormSubmit() {
                let { password, passwordConfirm } = this.form;
                let validForm = false;

                if (password && passwordConfirm) {
                    if (password.value && passwordConfirm.value) {
                        if (password.value === passwordConfirm.value) {
                            validForm = true;
                            this.checkForErrors();
                            if (this.errors.length > 0) {
                                validForm = false;
                            }
                        }
                    }
                }
                return validForm;
            },
            ...mapGetters(['featureIsActive']),
        },
        filters: {
            valuesFromObj: (objStr) => {
                return Object.values(objStr).toString();
            },
        },
        components: {
            StepsHeader,
            ButtonCta,
            InputElm,
            LabelInput,
        },

        // Lifecycle Hooks
        beforeCreate: function () {
            Object.assign(ResetPassConfig.hiddenInputList.tk_trp, {
                value: this.$store.state.token,
            });
            const context = this;
            const fListner = function (e) {
                if (e.code === 'Enter') {
                    context.buttonConfig.styleClass = context.buttonConfig.styleClass.filter(
                        (cssClass) => cssClass !== 'disabled',
                    );
                    context.handleSubmit(e);
                } else {
                    context.handleRequired(e);
                }
            };
            window.addEventListener('keypress', fListner, false);
            window.addEventListener('mousedown', fListner, false);
        },
    };
</script>
