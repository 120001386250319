<template>
    <!-- <div :class="getWrapperClass">-->
    <!-- START TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan-->
    <!-- Please uncomment above line # 1, when u remove a/b test code -->
    <div :class="getWrapperClass" @click="handlePlanPreSelect" @mouseenter="handlePlanPreSelect">
        <!-- END TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan-->
        <!-- main plan box -->
        <div :class="getBoxClassList">
            <!-- Coupon  -->
            <PlanCoupon :hasValidCoupon="hasValidCoupon()" :couponString="getCouponString()" />
            <div :class="planBoxDetailsClass">
                <!-- START TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan-->
                <div v-if="isPreSelectAbTest" :class="radioBoxClassList"></div>
                <!-- END TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan -->

                <h3 v-html="planHeading"></h3>

                <!-- START TEST PLOCTOPLUS-2035: Simplified Plans Tiles for POPI -->
                <div v-if="isSimplifiedPlansPOPI" class="plan-prefix">{{ planHeadingPrefix }}</div>
                <!-- END TEST PLOCTOPLUS-2035: Simplified Plans Tiles for POPI -->

                <div v-if="getTrialCopy" class="plan-copy">
                    {{ getTrialCopy }}
                </div>

                <div class="plan-price-copy" :class="getNonTrialPlanPriceClass()">
                    <span class="copy-stripe" v-if="plan.isCouponValidAndAllowed()">
                        {{ plan.planRetailPrice() }}
                    </span>

                    <!-- START TEST PLOCTOPLUS-2035: Simplified Plans Tiles for POPI -->
                    <span class="plan-price" v-if="isSimplifiedPlansPOPI">
                        {{ planPriceValue }}
                    </span>
                    <span class="plan-price-suffix" v-if="isSimplifiedPlansPOPI">
                        {{ planPriceSuffix }}
                    </span>
                    <!-- END TEST PLOCTOPLUS-2035: Simplified Plans Tiles for POPI -->

                    <span>{{ planPriceCopy }}</span>
                </div>
                <div v-if="isAnnualPlan">
                    {{ monthlyEffectivePriceCopy }}
                </div>
            </div>

            <template v-if="displayFeatureList">
                <hr :class="getPillClass" />
                <PillComponent
                    v-if="needsBestValuePill"
                    :className="getPillClass"
                    :textValue="planValuePillCopy"></PillComponent>

                <!-- START TEST PLOCTOPLUS-2035: Simplified Plans Tiles for POPI -->
                <FeatureList
                    :features="simplifiedPlansTilesList"
                    :plan="plan"
                    :bottomFeatures="bottomFeatures"
                    v-if="isSimplifiedPlansPOPI && !plansWithNoProps"></FeatureList>
                <!-- END TEST PLOCTOPLUS-2035: Simplified Plans Tiles for POPI -->
                <!-- PLOCTOPLUS-2035 v-else-if="!isSimplifiedPlansPOPI"
                line ~47 is part of a/b test, added only for a/b test and will remove when we will remove this a/b test -->
                <FeatureList
                    v-else-if="!isSimplifiedPlansPOPI"
                    :plan="plan"
                    :features="featureList"
                    :bottomFeatures="bottomFeatures"></FeatureList>
            </template>

            <ButtonCta :buttonConfig="getButtonConfig"></ButtonCta>
        </div>

        <!-- START TEST PLOCTOPLUS-2035: Simplified Plans Tiles for POPI -->
        <div
            class="pick-a-plan-grid-item no-ads-disclaimer"
            v-if="isSimplifiedPlansPOPI && plan.isCF">
            *Except live TV and a few shows
        </div>
        <!-- END TEST PLOCTOPLUS-2035: Simplified Plans Tiles for POPI -->
    </div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex';
    import PageAttributes from 'aa/vue/models/PageAttributes';
    import { PaymentConfig, PickAPlanConfig } from 'aa/vue/FormConfig';
    import ButtonCta from 'atoms/ButtonCta';
    import FeatureList from 'aa/vue/atoms/FeatureList';
    import { FLOW_TYPE } from 'models/FlowModel';
    import PillComponent from 'aa/vue/components/PillComponent';
    import PlanCoupon from 'aa/vue/components/PlanCoupon';
    import Feature, {
        CAMPAIGN_SERVICE_ENABLED,
        CONTINUE_FREE_TRIAL,
    } from 'aa/helpers/featureConstants';
    import aarpMixin from 'aa/vue/plugins/aarpMixin';
    import couponsMixin from 'aa/vue/plugins/couponsMixin';

    //START TEST PLOCTOPLUS-2035: Simplified Plans Tiles for POPI
    import SimplifiedPlansTilesAbTestPOPI from 'aa/vue/abTestRelated/SimplifiedPlansTilesAbTestPOPI';
    //END TEST PLOCTOPLUS-2035: Simplified Plans Tiles for POPI
    // START TEST PLOCTOPLUS-2036: plan tiles above fold
    import AbTestPlanTilesAboveFoldMixin from 'aa/vue/abTestRelated/AbTestPlanTilesAboveFoldMixin';
    import Plan from 'aa/vue/models/Plan';
    // END TEST PLOCTOPLUS-2036: plan tiles above fold

    export default {
        name: 'PickAPlanBox',
        mixins: [
            aarpMixin,
            couponsMixin,

            //START TEST PLOCTOPLUS-2035: Simplified Plans Tiles for POPI
            SimplifiedPlansTilesAbTestPOPI,
            //END TEST PLOCTOPLUS-2035: Simplified Plans Tiles for POPI

            // START TEST PLOCTOPLUS-2036: plan tiles above fold
            AbTestPlanTilesAboveFoldMixin,
            // END TEST PLOCTOPLUS-2036: plan tiles above fold
        ],

        props: {
            idx: {
                type: Number,
            },
            plan: {
                type: Plan,
                default: {
                    trialInterval: '',
                    trialUnit: '',
                    tier: '',
                    trial: true,
                },
            },
            planValuePillCopy: {
                type: String,
                required: false,
            },
            isNoFreeTrialCampaign: {
                type: Boolean,
            },
            hasSingleTrialPlan: {
                type: Boolean,
                default: false,
            },

            // START TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan
            isActivePlan: {
                type: Boolean,
                required: false,
                default: () => false,
            },
            // END TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan
        },
        event: 'planSelected',
        data: function () {
            return {
                coupon: {
                    discount: {
                        type: '',
                        trial: {
                            amount: 0,
                        },
                    },
                },
                papConfig: PickAPlanConfig,
                buttonConfig: {},
            };
        },
        components: {
            FeatureList,
            ButtonCta,
            PillComponent,
            PlanCoupon,
        },
        beforeCreate() {
            // ignore IDE "unresolved variable"
            PaymentConfig.recurlyConfig.publicKey = this.$root.serverData.recurlyPublicKey;
        },
        mounted() {
            // runs when XHR to get plans completes and components are rendered
            // trigger recurly
            this.$nextTick(() => {
                if (this.recurly) {
                    this.updatePriceAndCouponCopy(this.plan, this.$store.state.promo);
                }
            });

            // START TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan
            // BY DEFAULT P+ with SHo plan should be selected, tier 2 = sho
            if (this.plan?.tier === 2) {
                this.handlePreSelectionByTier();
            }
            // END TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan
        },
        methods: {
            // emit the selected plan
            handleClick: function () {
                let ctaText = this.buttonConfig.displayText;
                this.$emit('planSelected', this.plan, ctaText);
            },

            // replaces the trial copy for the plans if a coupon is associated with the user flow

            hasValidCoupon: function () {
                if (this.isAarpFlow()) {
                    return this.plan.hasValidCoupon() && this.hasValidAarpCoupon();
                }

                return this.plan.hasValidCoupon();
            },

            getCouponString() {
                return this.plan.getCouponString();
            },

            dspAfterTrial() {
                return;
            },

            //adds conditional css depending on if the plan has trial copy
            getNonTrialPlanPriceClass: function () {
                return {
                    'nontrial-plan-price': this.hasSingleTrialPlan && !this.getTrialCopy,
                };
            },

            // START TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan
            handlePreSelectionByTier() {
                if (this.isPreSelectAbTest && this.isActivePlan) {
                    this.selectPlanCard();
                }
            },
            selectPlanCard() {
                this.$emit('handleCardSelected', {
                    idx: this.idx,
                    ctaText: this.buttonConfig.displayText,
                    plan: this.plan,
                });
            },
            handlePlanPreSelect: function (e) {
                try {
                    if (
                        this.isShoPlanPreSelected &&
                        (e?.type === 'mouseenter' || e?.type === 'click')
                    ) {
                        this.selectPlanCard();
                    }
                    if (this.isShoPlanPreSelectedOneCTA && e?.type === 'click') {
                        this.selectPlanCard();
                    }
                } catch (e) {}
            },
            // END TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan

            getButtonText() {
                if (
                    Feature.enabled(CAMPAIGN_SERVICE_ENABLED) &&
                    Feature.enabled(CONTINUE_FREE_TRIAL)
                ) {
                    if (this.getTrialCopy) {
                        return this.pageAttrs?.aa_pap_button_text_continue_free_trial ?? '';
                    } else if (this.isExSubscriber || !this.plan?.isFreeTrial) {
                        return this.pageAttrs?.aa_pap_button_text_continue ?? '';
                    }
                }
                return this.pageAttrs.aa_pap_button_text;
            },
        },
        computed: {
            getWrapperClass() {
                return {
                    'plan-box-wrapper': true,
                    // START TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan
                    'sho-pre-selected': this.isShoPlanPreSelected,
                    'sho-pre-select-one-cta': this.isShoPlanPreSelectedOneCTA,
                    selectedPlan: this.isPreSelectAbTest && this.isActivePlan,
                    essential: this.plan?.tier === 0,
                    sho: this.plan?.tier === 2,
                    // END TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan
                };
            },
            // START TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan
            radioBoxClassList() {
                return {
                    'radio-box': true,
                    selected: this.isPreSelectAbTest && this.isActivePlan,
                };
            },
            // END TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan
            getBoxClassList() {
                return {
                    'plan-box': true,
                };
            },
            getPillClass() {
                return this.needsBestValuePill ? 'best-value' : '';
            },
            needsBestValuePill() {
                return this.plan.isCF;
            },
            recurly() {
                return this.$store.state.payment.recurly;
            },

            planPriceCopy() {
                let price = this.plan.price.toLowerCase(),
                    pa,
                    adjustedPromoRateString = this.plan?.getAdjustedPromoRateString(
                        this.isExSubscriber,
                    );

                if (this.plan.isCouponValidAndAllowed() && adjustedPromoRateString) {
                    return adjustedPromoRateString;
                }

                if (!this.getTrialCopy) {
                    return price;
                }

                if (this.plan.couponType) {
                    pa = this.pageAttrs.aa_pap_plan_price_format_with_percent_coupon;
                } else if (!this.plan.trial) {
                    pa = this.pageAttrs.aa_pap_plan_price_format_no_trial;
                } else {
                    pa = this.pageAttrs.aa_pap_plan_price_format_with_trial;
                }

                return pa.replace('%PRICE_WITH_UNIT%', price);
            },

            planHeading: function () {
                // START TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan
                if (this.isPreSelectAbTest && this.plan?.planTitle) {
                    return this.plan.planTitle.replace(' ', '<br/>');
                }
                // END TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan
                return this.plan.planTitle;
            },

            eligibleForCoupon() {
                return !(this.flow.type === FLOW_TYPE.GIFT);
            },

            noCouponApplied() {
                return !this.eligibleForCoupon || !this.plan.couponString;
            },

            removeBaseTrial() {
                const isNoFreeTrialCampaign = Feature.enabled(CAMPAIGN_SERVICE_ENABLED)
                    ? false
                    : this.isNoFreeTrialCampaign;

                return (
                    this.isNoTrialUser ||
                    this.flow.type === FLOW_TYPE.GIFT ||
                    isNoFreeTrialCampaign ||
                    this.plan.couponRemovesTrial === true
                );
            },

            waitForCouponCheck() {
                // check that getter isset, otherwise use store
                const promo = typeof this.$store.getters['promo'] !== 'undefined' 
                    ? this.$store.getters['promo'] 
                    : this.$store.state.promo
                return (
                    promo &&
                    this.eligibleForCoupon &&
                    this.plan.couponRemovesTrial === null
                ); // pending validation
            },

            // refer to svod/Product.php -> getTrialString
            getTrialCopy: function () {
                if (!this.plan.trial) {
                    return null;
                }

                if (
                    this.waitForCouponCheck ||
                    (this.removeBaseTrial && this.noCouponApplied) ||
                    !this.plan.trial
                ) {
                    return null;
                }

                this.plan.pageAttrs = this.pageAttrs;

                return (
                    this.plan
                        .setRemoveBaseTrial(this.removeBaseTrial)
                        .getTrialCopy(this.noCouponApplied, this.isExSubscriber) ?? ''
                );
            },

            isAnnualPlan() {
                return this.plan.isAnnual;
            },

            monthlyEffectivePriceCopy() {
                if (!this.isAnnualPlan) {
                    return null;
                }
                try {
                    return this.pageAttrs.aa_pap_annual_plan_monthly_price_format.replace(
                        '%MONTHLY_PRICE_WITH_UNIT%',
                        this.plan.annualPlanMonthlyPriceWithUnit(),
                    );
                } catch (e) {
                    return null;
                }
            },

            keyPrefixPackage() {
                return this.plan.isCF ? 'cf' : 'lcp';
            },

            /**
             * @returns {Array}
             */
            featureList() {
                let list = [];

                const keyPrefix = `aa_pap_${this.keyPrefixPackage}_feature_list_item_`;
                const keySuffix = this.plan.bundleShowtime ? PageAttributes.SHOWTIME_SUFFIX : '';

                new Array(10).fill().forEach((value, index) => {
                    let pageAttributeKey = `${keyPrefix}${index + 1}${keySuffix}`;

                    const listItem = this.pageAttrs[pageAttributeKey]?.trim();

                    if (listItem) {
                        list.push(listItem);
                    }
                });

                return list;
            },

            /**
             * @returns {Array}
             */
            bottomFeatures() {
                let list = [];

                const keyPrefix = `aa_pap_${this.keyPrefixPackage}_feature_list_bottom_item_`;

                new Array(10).fill().forEach((value, index) => {
                    let pageAttributeKey = `${keyPrefix}${index + 1}`;

                    const listItem = this.pageAttrs[pageAttributeKey]?.trim();

                    if (listItem) {
                        list.push(listItem);
                    }
                });

                return list;
            },

            displayFeatureList() {
                return this.featureList.length > 0;
            },

            getButtonConfig: function () {
                this.buttonConfig = Object.assign({}, this.papConfig.button);
                this.buttonConfig.displayText = this.getButtonText();
                this.buttonConfig.ariaLabel = this.$getLocale('select_plan_name', {
                    plan: this.plan.planTitle,
                });
                this.buttonConfig.event = this.handleClick;

                // START TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan
                if (this.isShoPlanPreSelected && !this.isActivePlan) {
                    this.buttonConfig.styleClass = this.buttonConfig.styleClass + ' secondary';
                }
                if (this.isShoPlanPreSelectedOneCTA) {
                    this.$emit('updateSinglCtaCopy', this.buttonConfig.displayText);
                }
                // START TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan

                return this.buttonConfig;
            },

            planBoxDetailsClass() {
                const classList = ['plan-box-details'];

                // if (this.removeBaseTrial) {
                //     classList.push('plan-box-details--no-subheading');
                // }
                return classList;
            },

            couponString() {
                return this.plan.couponString;
            },

            ...mapState('pageAttributes', {
                pageAttrs: (state) => state.attributes,
            }),
            ...mapGetters('user', ['isNoTrialUser']),
            ...mapState('flow', {
                flow: (state) => state.config,
            }),
            ...mapState('user', {
                isExSubscriber: (state) => state.isExSubscriber,
            }),
            ...mapGetters({
                bundleShowtime: 'bundleShowtime',
                featureFlagIsActive: 'featureFlags/isActive',
                // START TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan
                isShoPlanPreSelected: 'featureFlags/isShoPlanPreSelected',
                isShoPlanPreSelectedOneCTA: 'featureFlags/isShoPlanPreSelectedOneCTA',
                isPreSelectAbTest: 'featureFlags/isPreSelectAbTest',
                // END TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan
            }),
        },
        watch: {
            plan: function (newVal, oldVal) {
                this.updatePriceAndCouponCopy(this.plan, this.$store.state.promo);
                // START TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan
                this.handlePreSelectionByTier();
                // END TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan
            },
            bundleShowtime(newVal, oldVal) {
                this.updatePriceAndCouponCopy(this.plan, this.$store.state.promo);
            },
            recurly(newVal, oldVal) {
                if (newVal) {
                    this.updatePriceAndCouponCopy(this.plan, this.$store.state.promo);
                }
            },
        },
    };
</script>
