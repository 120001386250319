import ForgotPasswordComponent from 'aa/vue/components/ForgotPassword';
import Step from 'models/steps/Step';

export default class ForgotPassword extends Step {
    path = '/account/forgotpassword/';
    name = 'FORGOT_PASSWORD';
    component = ForgotPasswordComponent;
    requireAuth = false;
    requireNonAuth = false;
}
