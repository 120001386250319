<template>
    <section>
        <transition name="fade">
            <WaitingOverlay
                :line1="$getLocale('we_are_processing_your_subscription')"
                :line2="$getLocale('this_will_not_take_long')"
                v-if="!validated && !error" />
        </transition>
        <success-confirmation
            v-if="validated"
            :provider="provider"
            :create-account-callback="createAccountCallback"
            :bind-account-callback="bindAccountCallback"
            :isPartnerSubscription="true" />
    </section>
</template>

<script>
    import { mapActions, mapState } from 'vuex';

    import PartnerMixin from './PartnerMixin';
    import SuccessConfirmation from '../mvpd/elements/SuccessConfirmation';
    import WaitingOverlay from 'aa/vue/atoms/WaitingOverlay';

    export default {
        name: 'PartnerAuthenticator',
        components: {
            SuccessConfirmation,
            WaitingOverlay,
        },
        mixins: [PartnerMixin],
        props: {
            createAccountCallback: {
                type: Function,
            },
            bindAccountCallback: {
                type: Function,
            },
            isValidRedirect: {
                type: Boolean,
                default: true,
            },
        },
        data: function () {
            return {
                validated: false,
                provider: {
                    name: null,
                    logo: null,
                },
                error: false,
            };
        },
        computed: {
            ...mapState(['user', 'partner']),
        },
        methods: {
            ...mapActions('partner', ['partnerAuthentication', 'bindWithPartner']),
            partnerValidationSuccess: function (obj) {
                const { subscriptionDetails } = obj;
                this.$store.commit('partner/setSubscriptionDetails', subscriptionDetails);
                this.provider = {
                    logoUrl:
                        this.$store.getters['cmsImageBaseURL'] +
                        subscriptionDetails?.logos?.blackLogo,
                    name: subscriptionDetails?.partnerCode,
                };

                this.checkBinding();
            },
            checkBinding: async function () {
                if (this.user.isRegistered || this.user.isExSubscriber) {
                    const response = await this.bindWithPartner();

                    if (response?.success === false) {
                        this.setError(response?.errorCode, 'BINDING_ERROR');
                    }
                } else if (this.user.isSubscriber) {
                    this.setError('ACTIVE_USER_PACKAGE_EXISTS');
                } else {
                    this.setValidated(true);
                }
            },
            attemptPartnerAuthentication: async function () {
                const success = await this.partnerAuthentication(this.partnerValidationSuccess);
                if (!success) {
                    this.setError(true, 'AUTHENTICATION_ERROR');
                }
            },
            setError: function (errorCode = '', errorType = 'GENERIC_ERROR') {
                this.error = true;

                this.showPartnerError(errorCode, errorType);
            },
            //Stubbed out to fill with tracking
            setValidated: function (val) {
                this.validated = val;
            },
            emitNoQueryError: function () {
                this.setError(true, 'NO_QUERY_ERROR');

                this.$store.commit('setUserMessage', {
                    heading: this.$getLocale(
                        'an_error_has_occurred_please_try_again_at_a_later_time',
                    ),
                    subHeading: this.$getLocale('error_partner_query_missing', {
                        partner: this.partner.partnerAuthAttributes.csp_display_name,
                    }),
                    primaryButton: {
                        label: this.$getLocale('close'),
                        link: this.$store.state.serverData.regionBaseUrl,
                    },
                });
            },
        },
        created() {
            if (!this.isValidRedirect) {
                this.emitNoQueryError();
            } else {
                this.attemptPartnerAuthentication();
            }
        },
    };
</script>
