import { CustomMeasure } from '@cbsi-cbscom/custom-measurement';

const UPSELL_MEASURE = {
    namespace: 'UPSELL',
    events: {
        load_time: 'page_load_time',
        hero_cta: 'hero_cta_load_time',
    },
};

const customMeasure = new CustomMeasure({
    namespace: UPSELL_MEASURE.namespace,
});

/**
 * Measure the given element performance
 * @param {string} eventName - The event name on New Relic
 * @param {number} value - The value to be sent to New Relic
 */
function measureElementPerformance(eventName, value) {
    if (window.performance) {
        var performanceNavigationTiming = performance.getEntriesByType('navigation');
        if (Array.isArray(performanceNavigationTiming) && performanceNavigationTiming.length > 0) {
            customMeasure.sendEvent({
                eventName: eventName,
                customData: {
                    value: value,
                    navigationType: performanceNavigationTiming[0].type,
                },
            });
        }
    }
}

function measureHeroCtaPerformance() {
    let heroCtaLoadTime = CBS.Registry.Performance.upsell.heroCta;

    if (heroCtaLoadTime !== undefined) {
        measureElementPerformance(UPSELL_MEASURE.events.hero_cta, heroCtaLoadTime);
    }
}

export { measureHeroCtaPerformance };
