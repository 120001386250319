export class ValueProp {
    // cms keys
    constructor(obj = {}) {
        this.position = obj?.position || '0';
        this.value_prop = obj?.value_prop || '';
        this.value_prop_slice = obj?.value_prop_slice || '';
        this.essential_checkbox = obj?.essential_checkbox || false;
        this.premium_checkbox = obj?.premium_checkbox || false;
        this.essential_text = obj?.essential_text || '';
        this.premium_text = obj?.premium_text || '';
    }
}
