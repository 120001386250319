import HttpService from 'services/HttpService';
import { AA_XHR_BASE_PATH, ACCOUNT_BASE_PATH, CONFIRMATION_URL } from 'aa/vue/constants/aaRoutes';
import UrlHelper from 'helpers/UrlHelper';

let http = new HttpService();

export default {
    paths: {
        payment: `${AA_XHR_BASE_PATH}processPayment/`,
        invoice: `${AA_XHR_BASE_PATH}reviewInvoice/`,
        coupon: `${AA_XHR_BASE_PATH}processRecurlyCoupon/`,
        defaultConfirmationBaseUrl: UrlHelper.getHomePageURL(),
        showPickerConfirmationBaseUrl: `${ACCOUNT_BASE_PATH}shows/picker/`,
        orderIdQuery: '?order_id=',
        gift: `${AA_XHR_BASE_PATH}gift-aa-redeem/`,
        giftPurchase: `${AA_XHR_BASE_PATH}gift-purchase/`,
        giftCode: `${AA_XHR_BASE_PATH}gift-code/`,
        getPromo: `${AA_XHR_BASE_PATH}promo/`,
        editPayment: `${AA_XHR_BASE_PATH}payment/update/`,
    },

    setHttpService(httpService) {
        http = httpService;
    },

    /**
     * @param orderId {number}
     * @param overrideBaseUrl {string}
     *
     * @return {string}
     */
    confirmationUrl(orderId, overrideBaseUrl) {
        let baseUrl = overrideBaseUrl || this.paths.defaultConfirmationBaseUrl;

        if (orderId) {
            return baseUrl + this.paths.orderIdQuery + orderId;
        } else {
            return baseUrl;
        }
    },

    /**
     * @param orderId {number}
     * @param overrideBaseUrl {string}
     *
     * @return {string}
     */
    showPickerConfirmationUrl(orderId, overrideBaseUrl) {
        let baseUrl = overrideBaseUrl || this.paths.showPickerConfirmationBaseUrl;

        if (orderId) {
            return baseUrl + this.paths.orderIdQuery + orderId;
        } else {
            return baseUrl;
        }
    },

    /**
     * @param reqData {object}
     * {
     *      token: (token.id),
     *      m: (buttonEvent.clientX.toString() + buttonEvent.clientY.toString()),
     *      i: (request counter),
     * }
     *
     * @return {Promise}
     */
    postPayment(reqData) {
        let path = this.paths.payment;

        if (!reqData.couponCode) {
            delete reqData.couponCode;
        }

        return new Promise((resolve, reject) => {
            http.doPost(path, reqData)
                .then((val) => {
                    if (val.success) {
                        // TODO: handle this in HttpService
                        resolve(val.data);
                    } else {
                        reject(val);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    postPaymentUpdate(httpData) {
        const path = this.paths.editPayment;
        return new Promise((resolve, reject) => {
            http.doPost(path, httpData)
                .then((res) => {
                    if (res.success) {
                        resolve(res);
                    } else {
                        reject(res.data);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    getReviewInvoice(plan, token, coupon, state, zip) {
        let path = this.paths.invoice;

        return new Promise((resolve, reject) => {
            http.doPost(path, {
                plan: plan,
                token: token,
                coupon: coupon,
                state: state,
                zip: zip,
            })
                .then((val) => {
                    if (val.success) {
                        // TODO: handle this in HttpService
                        resolve(val.data.result.amounts);
                    } else {
                        reject(val);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    postCoupon(reqData) {
        let path = this.paths.coupon;

        return http.doPost(path, reqData);
    },

    postGiftToken(reqData) {
        let path = this.paths.gift;

        return new Promise((resolve, reject) => {
            http.doPost(path, reqData)
                .then((val) => {
                    if (val.success) {
                        resolve(val.data);
                    } else {
                        reject(val);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    postGiftPurchase(reqData) {
        let path = this.paths.giftPurchase;

        return new Promise((resolve, reject) => {
            http.doPost(path, reqData)
                .then((val) => {
                    if (val.success) {
                        resolve(val.data);
                    } else {
                        reject(val);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    postGiftCode(reqData) {
        let path = this.paths.giftCode;

        return new Promise((resolve, reject) => {
            http.doPost(path, reqData)
                .then((val) => {
                    if (val.success) {
                        resolve(val.data);
                    } else {
                        reject(val);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getPromo(userType) {
        let path = this.paths.getPromo;

        return new Promise((resolve, reject) => {
            http.doGet(path, { ut: userType })
                .then((val) => {
                    if (val.success) {
                        resolve(val.data.result);
                    } else {
                        reject(val);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    postUpdateShortCode(reqData) {
        let path = '/oauth/cbssports/update-short-code/' + reqData.queryString;

        return new Promise((resolve, reject) => {
            http.doPost(path, reqData)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};
