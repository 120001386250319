import Domain from 'helpers/Domain';

export default {
    methods: {
        getCurrencySymbol(fallbackSymbol) {
            let symbol = fallbackSymbol;
            const configElement =
                document.getElementById('app-config') ?? document.getElementById('php-to-js-var');
            if (configElement) {
                symbol = JSON.parse(configElement.innerHTML)?.currencySymbol;
            }
            return symbol;
        },
        pricePrettyPrint(
            numberValue,
            symbol,
            currencyCode,
            language = CBS.Registry.region.locale,
            displayCurrencyCode = !!Domain.isInternational(),
        ) {
            // latam uses the same currency formatting as BR because Intl.Numberformat does not support es-419
            // Also MXN does not follow the same formatting as the rest of latam
            if (language === 'es-419' && currencyCode !== 'MXN') {
                language = 'pt-BR';
            }
            const currencySymbol = this.getCurrencySymbol(symbol);
            // in some cases the numberValue is already formatted
            // so this line is here to strip non-number chars
            let n = numberValue.toString().replace(/[^0-9.]+/g, '');

            let formattedVal = Intl.NumberFormat(language, {
                style: 'currency',
                currency: currencyCode.trim(),
            }).format(n);
            formattedVal = formattedVal.match(/[\d.,]+/g)[0];

            let prettyPrice = `${currencySymbol}${formattedVal}`;

            if (displayCurrencyCode) {
                prettyPrice += ` ${currencyCode}`;
            }
            return prettyPrice;
        },
    },
};
