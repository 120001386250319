<template>
    <div class="upsell-plans-grid">
        <ValuePropDescription :valueProp="valueProp" />
        <ValuePropState
            v-for="plan in plans"
            :key="plan.recurlyCode"
            :plan="plan"
            :valueProp="valueProp" />
    </div>
</template>

<script>
    import ValuePropState from 'aa/vue/components/upsell/ValuePropState.vue';
    import ValuePropDescription from 'aa/vue/components/upsell/ValuePropDescription.vue';

    export default {
        name: 'ValuePropListItem',
        props: {
            valueProp: {
                type: Object,
                required: true,
            },
            plans: {
                type: Array,
                required: true,
            },
        },
        components: {
            ValuePropDescription,
            ValuePropState,
        },
    };
</script>
