<template>
    <div class="full-overlay">
        <div class="screen"></div>
        <div class="centered-area">
            <div class="message-area">
                <div class="spinner-wrapper">
                    <Spinner></Spinner>
                </div>
                <div class="message large">
                    {{ line1 }}
                </div>
                <div class="message small">
                    {{ line2 }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Spinner from 'aa/vue/atoms/Spinner';

    export default {
        name: 'WaitingOverlay',
        props: {
            line1: String,
            line2: String,
        },
        components: {
            Spinner,
        },
    };
</script>
