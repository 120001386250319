<template>
    <div class="legal-overlay-modal">
        <div class="legal-overlay-modal__header">
            <h3 class="legal-overlay-modal__header-text">{{ header }}</h3>
            <a href="#" class="legal-overlay-modal__header-close" v-on:click="onCloseClicked"></a>
        </div>
        <p class="legal-overlay-modal__body" v-html="copy"></p>
    </div>
</template>

<script>
    import ButtonCta from 'atoms/ButtonCta';
    import BaseAccount from 'aa/vue/components/account/BaseAccount/BaseAccount';
    import { tracking } from 'services/Tracking';

    export default {
        extends: BaseAccount,
        name: 'LegalOverlayModal',
        props: {
            data: {
                type: Object,
                default: true,
            },
        },
        components: {
            ButtonCta,
        },
        computed: {
            header() {
                return this.data.header;
            },
            copy() {
                return this.data.copy;
            },
        },
        methods: {
            onCloseClicked() {
                const trackingParams = {
                    ctaText: 'Exit legal overlay',
                    pageType: 'svod_pickaplan',
                    pageName: '/pick-a-plan/',
                    screenName: '/pick-a-plan/',
                };
                tracking.trackAction('trackCloseLegalButtonClick', trackingParams);
                this.closeModal();
            },
        },
    };
</script>
