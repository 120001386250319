<template id="ForgotPassword">
    <section class="padded-container">
        <div class="forgot-password-grid">
            <div class="grid-item">
                <h3
                    :tabindex="pageHeadingTabIndex"
                    ref="pageHeading"
                    class="steps-header"
                    @blur="removeHeadingTabIndex">
                    {{ getStepsHeaderCopy }}
                </h3>

                <p v-if="!isSuccess">
                    {{ pageAttributes.sub_heading }}
                </p>

                <p v-if="isSuccess" data-ci="success_message" class="form-message">
                    {{ pageAttributes.success_message }}
                </p>

                <p v-if="formSubMessage" class="form-message" v-html="formSubMessage">
                    {{ formSubMessage }}
                </p>

                <p
                    v-if="formMessageError"
                    data-ci="error_message"
                    role="alert"
                    class="form-message error">
                    {{ formMessageError }}
                </p>
            </div>

            <form id="forgot-password-form" v-on:submit.stop.prevent="handleSubmit">
                <div class="form-wrapper">
                    <div
                        v-for="(value, key) in formConfig.inputList"
                        :key="key"
                        class="grid-item cbs-text-input span-cols">
                        <LabelInput
                            :ref="key"
                            :inputConfig="value"
                            v-model="form[key]"
                            :class="{ error: isInError(key) }" />
                        <div v-for="error in errors" :key="key">
                            <div :class="textInputErrorCSS(key)" v-if="error[key]" role="alert">
                                {{ error[key] }}
                            </div>
                        </div>
                    </div>

                    <div class="grid-item text-center span-cols">
                        <ButtonCta :buttonConfig="buttonConfig" />
                    </div>

                    <InputElm
                        v-for="(value, key) in formConfig.hiddenInputList"
                        :inputConfig="value"
                        v-bind:key="formConfig.hiddenInputList.ref"
                        v-model="form[key]" />
                </div>
            </form>

            <div class="grid-item text-center">
                <a
                    v-if="cancelCallback"
                    @click="useCancelCallback"
                    tabindex="3"
                    class="form-link qt-cancelbtn">
                    <span @click="cancelRouteTrack">{{ $getLocale('cancel') }}</span>
                </a>
                <a
                    v-else-if="isLoggedIn"
                    :href="accountURL"
                    @click="cancelRouteTrack"
                    tabindex="3"
                    class="form-link qt-cancelbtn">
                    {{ $getLocale('cancel') }}
                </a>
                <router-link
                    v-else
                    :to="{ name: 'SIGNIN' }"
                    tabindex="3"
                    class="form-link qt-cancelbtn">
                    <span @click="cancelRouteTrack">{{ $getLocale('cancel') }}</span>
                </router-link>
            </div>
        </div>
        <BackToRendezvousOptions :separator="false" v-if="!isDomestic"></BackToRendezvousOptions>
    </section>
</template>

<script>
    import BackToRendezvousOptions from 'aa/vue/components/BackToRendezvousOptions';
    import ButtonCta from 'atoms/ButtonCta';
    import InputElm from 'atoms/InputElm';
    import LabelInput from 'atoms/LabelInput';
    import StepsHeader from 'aa/vue/components/StepsHeader';
    import { AA_XHR_BASE_PATH, ACCOUNT_URL } from 'aa/vue/constants/aaRoutes';
    import { sprintf } from 'sprintf-js';
    import accesabilityMixins from 'aa/vue/plugins/account/accesabilityMixins';
    import { ACTION_NAMES, tracking } from 'services/Tracking';
    import { ForgotPassConfig } from 'aa/vue/FormConfig';
    import { mapState } from 'vuex';
    import Domain from 'helpers/Domain';

    export default {
        props: {
            cancelCallback: {
                type: Function,
            },
        },
        mixins: [accesabilityMixins],
        data: function () {
            return {
                isSuccess: false,
                inProgress: false,
                form: {},
                errors: [],
                buttonConfig: Object.assign(ForgotPassConfig.buttonConfig, {
                    event: this.handleSubmit,
                }),
                formMessage: '',
                formMessageError: '',
                formSubMessage: '',
                isLoggedIn: this.$store.state.user.isLoggedIn,
                accountURL: ACCOUNT_URL,
                countDown: 15,
                isDomestic: Domain.isDomestic(),
            };
        },
        methods: {
            disableSubmitButton() {
                if (!this.buttonConfig.disabled) {
                    this.buttonConfig.disabled = true;
                    this.buttonConfig.styleClass.push('disabled');
                }
            },
            enableSubmitButton() {
                this.buttonConfig.disabled = false;
                this.buttonConfig.styleClass = this.buttonConfig.styleClass.filter(
                    (cssClass) => cssClass !== 'disabled',
                );
            },
            useCancelCallback(e) {
                this.cancelCallback(this, e);
            },
            handleSubmit(e) {
                // prevent multiple submissions
                if (!this.isSuccess && !this.inProgress) {
                    let checkForm = this.checkForm(e);
                    if (checkForm) {
                        this.handleValidForm();
                    } else {
                        this.disableSubmitButton();
                    }
                } else {
                    this.disableSubmitButton();
                }
            },
            handleRequired(e) {
                if (!this.isSuccess) {
                    let checkForm = this.checkFormRequired(e);
                    if (checkForm) {
                        this.enableSubmitButton();
                    } else {
                        this.disableSubmitButton();
                    }
                } else {
                    this.disableSubmitButton();
                }
            },
            checkForm: function (e) {
                e.preventDefault();

                this.errors = [];

                if (!this.form.email || this.form.email.value === '') {
                    this.errors.push({
                        email: this.$getLocale('email_is_required'),
                    });
                } else if (!this.validEmail(this.form.email.value)) {
                    this.errors.push({
                        email: this.$getLocale('valid_email_required'),
                    });
                }
                //Length Rules
                //Is Sanitized
                if (this.errors.length === 0) {
                    this.formMessageError = '';
                    return true;
                } else {
                    this.$refs?.email?.[0]?.$refs?.email?.focus();
                }

                return false;
            },
            checkFormRequired: function () {
                return this.form.email && this.form.email.value !== '';
            },
            validEmail: (email) => {
                const re =
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            },
            handleValidForm: function () {
                if (this.buttonConfig.styleClass.indexOf('disabled') >= 0) {
                    return;
                }

                this.disableSubmitButton();

                const httpData = {};
                for (let key in this.form) {
                    if (this.form.hasOwnProperty(key)) {
                        httpData[key] = this.form[key].value;
                    }
                }
                const context = this;
                this.inProgress = true;
                this.$http.doPost(`${AA_XHR_BASE_PATH}forgot-password/`, httpData).then((res) => {
                    this.inProgress = false;
                    if (res.success) {
                        this.isSuccess = true;
                        tracking.trackAction(ACTION_NAMES.FORGOT_PASSWORD_CTA);
                        context.formMessageError = '';

                        if (!Domain.isDomestic()) {
                            context.getCountDown();
                            window.setInterval(() => {
                                context.getCountDown();
                            }, 1000);
                            window.setTimeout(() => {
                                this.getRedirected(context);
                            }, 15000);
                        }
                    } else {
                        this.enableSubmitButton();
                        context.formMessageError =
                            typeof res.message !== 'undefined' &&
                            res.message !== '' &&
                            res.message !== 'no error provided'
                                ? res.message
                                : this.pageAttributes.error_message_fallback;
                    }
                });
            },
            getRedirected: function (context) {
                if (context.isLoggedIn) {
                    window.location.assign('/account/');
                } else {
                    window.location.assign(this.$root.serverData.regionBaseUrl || '/');
                }
            },
            getCountDown: function () {
                let message;
                let basePath = this.$root.serverData.regionBaseUrl;
                let destinationPath;

                if (this.isLoggedIn) {
                    destinationPath = `${basePath}/account/`;
                    message = sprintf(
                        this.pageAttributes.redirect_message_logged_in,
                        this.countDown--,
                        destinationPath,
                    );
                } else {
                    destinationPath = basePath || '/';
                    let siteAddr = window.location.hostname + basePath;

                    message = this.pageAttributes.redirect_message;

                    // can remove else condition after update pageattrs
                    if (message.indexOf('%s')) {
                        message = sprintf(message, siteAddr, this.countDown--, destinationPath);
                    } else {
                        message = sprintf(message, this.countDown--, destinationPath);
                    }
                }

                if (this.countDown > -1) this.formSubMessage = message;
            },
            textInputErrorCSS: (key) => {
                return 'text-input-error qt-err-' + key;
            },

            cancelRouteTrack(e) {
                tracking.trackAction(ACTION_NAMES.FORGOT_PASSWORD_CANCEL);
            },
        },
        computed: {
            getStepsHeaderCopy() {
                return this.isSuccess
                    ? this.pageAttributes.success_heading
                    : this.pageAttributes.heading;
            },
            isInError() {
                return (key) => {
                    if (this.errors.length === 0) {
                        return false;
                    }
                    const result = this.errors.filter((err) => typeof err[key] !== 'undefined');
                    return result.length !== 0;
                };
            },
            formConfig() {
                return ForgotPassConfig;
            },
            ...mapState('pageAttributes', {
                pageAttributes: (state) => state.attributes,
            }),
        },
        filters: {
            valuesFromObj: (objStr) => {
                return Object.values(objStr).toString();
            },
        },
        components: {
            StepsHeader,
            ButtonCta,
            InputElm,
            LabelInput,
            BackToRendezvousOptions,
        },

        // Lifecycle Hooks
        beforeCreate: function () {
            Object.assign(ForgotPassConfig.hiddenInputList.tk_trp, {
                value: this.$store.state.token,
            });
            const fListner = (e) => {
                if (e.code === 'Enter') {
                    this.enableSubmitButton();
                    this.handleSubmit(e);
                } else {
                    this.handleRequired(e);
                }
            };
            window.addEventListener('keyup', fListner, false);
            window.addEventListener('mousedown', fListner, false);
        },
    };
</script>
