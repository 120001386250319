import { catanAddOnCode } from 'aa/vue/constants/aaBundle';

const TIER_LCP = -1;
const TIER_LC = 0;
const TIER_CF = 1;

export default class Product {
    static get PACKAGE_CODE() {
        return {
            LCP: 'CBS_ALL_ACCESS_LOW_COST_PACKAGE',
            LC: 'CBS_ALL_ACCESS_PACKAGE',
            CF: 'CBS_ALL_ACCESS_AD_FREE_PACKAGE',
            PM: 'PPLUS_INTL_PREMIUM_PACKAGE',
        };
    }

    static get TIER_LC() {
        return TIER_LC;
    }

    static get TIER_LCP() {
        return TIER_LCP;
    }

    static get TIER_CF() {
        return TIER_CF;
    }

    static get PACKAGE_CODE_LCP() {
        return 'CBS_ALL_ACCESS_LOW_COST_PACKAGE';
    }

    static get PACKAGE_CODE_LC() {
        return 'CBS_ALL_ACCESS_PACKAGE';
    }

    static get PACKAGE_CODE_CF() {
        return 'CBS_ALL_ACCESS_AD_FREE_PACKAGE';
    }

    static get TYPE_MONTHLY() {
        return 'monthly';
    }

    static get TYPE_ANNUAL() {
        return 'annual';
    }

    static get TIER_ESSENTIAL() {
        return 'essential';
    }

    static get TIER_BASIC() {
        return 'mobile';
    }

    static get TIER_ADS() {
        return 'ads';
    }

    static get TIER_STANDARD() {
        return 'standard';
    }

    static get TIER_PREMIUM() {
        return 'premium';
    }

    static get ADDON_PARTNER_BUNDLE() {
        return catanAddOnCode();
    }
}
