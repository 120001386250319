<template>
    <div>
        <div class="modal--cta__check"></div>
        <div class="modal--cta__exclamation-mark"></div>
        <div id="modal-switch-to-annual-prompt" class="modal--cta -confirm">
            <p class="modal--content -header" v-html="header"></p>
            <p class="modal--content" v-html="disclaimer"></p>
            <ButtonCta :buttonConfig="submitConfig"></ButtonCta>
            <ButtonCta :buttonConfig="cancelConfig"></ButtonCta>
        </div>
    </div>
</template>

<script>
    import ButtonCta from 'atoms/ButtonCta';
    import BaseAccount from 'aa/vue/components/account/BaseAccount/BaseAccount';

    export default {
        extends: BaseAccount,
        name: 'SwitchPlanPromptModal',
        components: {
            ButtonCta,
        },
        computed: {
            header() {
                return this.$store.state.serverData.multiSubPlanAttributes?.SwitchPlanPromptModal
                    ?.switch_prompt_header;
            },
            disclaimer() {
                let disclaimer =
                    this.$store.state.serverData.multiSubPlanAttributes?.SwitchPlanPromptModal
                        ?.switch_prompt_disclaimer;
                return disclaimer.replace(/(\r\n|\r|\n)/g, '<br/>');
            },
            submitConfig() {
                return {
                    styleClass: ['button', 'primary'],
                    displayText: this.$getLocale('buy_now'),
                    event: this.confirmOnClicked,
                };
            },
            cancelConfig() {
                return {
                    styleClass: ['button', 'secondary'],
                    displayText:
                        this.$store.state.serverData.multiSubPlanAttributes?.SwitchPlanPromptModal
                            ?.switch_prompt_cancel_cta,
                    event: this.cancelOnClicked,
                };
            },
        },
        methods: {
            cancelOnClicked() {
                this.closeModal();
            },
            confirmOnClicked() {
                this.$store.dispatch('modal/setConfirmSwitchPlan', true);
                this.closeModal();
            },
        },
    };
</script>
