<template>
    <div :class="classList" v-if="hasValidCoupon">
        <span class="plan-coupon-svg"></span>
        <span class="coupon-off-text">{{ couponString }}</span>
    </div>
</template>

<script>
    export default {
        name: 'PlanCoupon',
        props: {
            hasValidCoupon: {
                type: Boolean,
                default: false,
            },
            couponString: {
                type: String,
                default: '',
            },
            classList: {
                type: Array,
                default: function () {
                    return ['plan-coupon'];
                },
            },
        },
    };
</script>
