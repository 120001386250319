// this handles the dimming and illuminating of the video and background images

import AAUpsellThresholds from 'aa/components/AAUpsellThresholds';
import Feature from 'helpers/feature';
import { debouncedScroll } from 'helpers/bindScroll';
import Breakpoints from 'helpers/breakpoints';

export default class AAUpsellHero {
    constructor() {
        this.classIlluminate = 'illuminate';
        this.thresholds = new AAUpsellThresholds();
        this.breakpoints = new Breakpoints();
        this.hero = document.querySelector('.hero');
        this.sections = document.querySelectorAll('.aa-section');
        this.heroContentEl = this.hero.querySelector('.showtime-bundle-padded-container');
        this.showtimeBannerEl = this.hero.querySelector('.showtime-bundle-wrapper');
        this.disclaimerEl = this.hero.querySelector('.upsell-disclaimer');
        this.windowWidth = window.innerWidth;

        this.listenerOptions = Feature.supportsPassive()
            ? {
                  capture: true,
                  passive: true,
              }
            : false;

        this.init();
    }

    init() {
        this.setupScrollHandler();

        // invoke opacity changes
        this.handleScroll();
        this.repositionHeroContent();

        this.resizeListener();
    }

    resizeListener() {
        window.addEventListener(
            'resize',
            () => {
                this.windowWidth = window.innerWidth;
                this.repositionHeroContent();
            },
            this.listenerOptions,
        );
    }

    repositionHeroContent() {
        let heroHeight = this.hero?.clientHeight;
        let heroContentHeight = this.heroContentEl?.clientHeight;
        let bannerHeight = this.showtimeBannerEl?.clientHeight;
        let disclaimerHeight = this.disclaimerEl?.clientHeight;

        if (this.windowWidth < this.breakpoints.getMax('tabletPortrait')) {
            if (this.showtimeBannerEl) {
                let result = (heroHeight - heroContentHeight - bannerHeight) / 2 + bannerHeight;
                let myStyle = result + 'px';

                this.setHeroContentBottom(myStyle);
            }
        } else if (this.disclaimerEl) {
            let result = disclaimerHeight + bannerHeight;
            let myStyle = result + 'px';
            this.setHeroContentBottom(myStyle);
        } else {
            this.setHeroContentBottom();
        }

        // adjust disclaimer height if showtime banner present
        if (this.showtimeBannerEl && this.disclaimerEl) {
            let disclaimerElBottom = bannerHeight;
            this.disclaimerEl.style.bottom = disclaimerElBottom + 'px';
        }
    }

    setHeroContentBottom(styleVal = null) {
        if (!this.heroContentEl) {
            return;
        }

        this.heroContentEl.style.bottom = styleVal;
    }

    setupScrollHandler() {
        debouncedScroll(() => this.handleScroll(), this.listenerOptions);
    }

    handleScroll() {
        if (window.pageYOffset <= this.thresholds.getThreshold()) {
            this.dimSections();
            this.illuminateHero();
        } else {
            this.illuminateSections();
            this.dimHero();
        }
    }

    illuminateHero() {
        this.hero.classList.add(this.classIlluminate);
    }

    dimSections() {
        this.sections.forEach((el) => {
            el.classList.remove(this.classIlluminate);
        });
    }

    illuminateSections() {
        this.sections.forEach((el) => {
            el.classList.add(this.classIlluminate);
        });
    }

    dimHero() {
        this.hero.classList.remove(this.classIlluminate);
    }
}
