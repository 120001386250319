<template>
    <div id="modal-exit-terms" class="modal--cta">
        <h3>{{ pageAttr.cant_verified }}</h3>
        <p class="modal--body">{{ pageAttr.card_decline }}</p>
        <ButtonCta :buttonConfig="exitConfig"></ButtonCta>
    </div>
</template>

<script>
    import ButtonCta from 'atoms/ButtonCta';
    import logout from 'user/logout';
    import { mapState } from 'vuex';

    export default {
        name: 'ExitModal',
        components: {
            ButtonCta,
        },
        computed: {
            ...mapState({
                pageAttr(state) {
                    return state.serverData.pageAttributes.AccountPayment
                        ? state.serverData.pageAttributes.AccountPayment
                        : state.pageAttributes.attributes;
                },
            }),
            exitConfig() {
                return {
                    styleClass: ['button'],
                    displayText: this.pageAttr.got_it,
                    event: this.confirmLogout,
                };
            },
        },
        methods: {
            confirmLogout() {
                logout(null);
            },
        },
    };
</script>
