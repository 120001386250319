<template>
    <div :class="getClassList">
        <span class="prop-copy">{{ valueProp.value_prop }}</span>
        <span class="sliced-copy" v-if="hasSlicedCopy" v-html="getSlicedCopy"></span>
    </div>
</template>
<script>
    export default {
        name: 'ValuePropDescription',
        props: {
            valueProp: {
                type: Object,
                required: true,
            },
        },
        computed: {
            hasSlicedCopy() {
                return this.valueProp.value_prop_slice.length > 0;
            },
            getSlicedCopy() {
                let slicedCopy = this.valueProp.value_prop_slice;
                const containsPercent = slicedCopy.indexOf('%') > -1;
                const percentIsEven = (slicedCopy.split('%').length - 1) % 2 === 0;

                if (!containsPercent) {
                    return slicedCopy;
                }

                slicedCopy =
                    containsPercent && percentIsEven
                        ? this.wrapBolderCopy(slicedCopy)
                        : this.removeAllPercentOccurrances(slicedCopy);

                return slicedCopy;
            },
            getClassList() {
                return {
                    'upsell-plans-grid-item': true,
                    'prop-text': true,
                    'multi-line': this.hasSlicedCopy,
                };
            },
        },
        methods: {
            wrapBolderCopy(slicedCopy) {
                slicedCopy = slicedCopy.replace('%', '<strong>').replace('%', '</strong>');
                if (slicedCopy.indexOf('%') > -1) {
                    slicedCopy = this.wrapBolderCopy(slicedCopy);
                }
                return slicedCopy;
            },
            removeAllPercentOccurrances(slicedCopy) {
                while (slicedCopy.indexOf('%') > -1) {
                    slicedCopy = slicedCopy.replace('%', '');
                }
                return slicedCopy;
            },
        },
    };
</script>
