import NotificationBarModel from 'aa/vue/models/NotificationBarModel';

export default {
    namespaced: true,

    state: {
        isOpen: false,
        isClosing: false,
        isPositive: false,
        message: '',
        messageKey: '',
        animationDuration: 300,
        delayToClose: 10000,
        dictionary: null,

        // CBSCOMSUB-4696
        denyClose: false,
        routeNameForMessage: null, // gift purchase error,
        skipOpenUri: [
            'partner_subscribed',
            'gift_third_party_error',
            'partner_pending_cancellation',
        ],

        // CBSCOMSUB-7247 notification below header consolidation
        messageTitle: '',
        spacerHeight: 0,
        numPagesShowedOn: 0,
        showBelowHeader: false,
        success: false,
    },

    mutations: {
        show(state, payload) {
            state.isOpen = true;
            state.isClosing = false;
            state.isPositive = payload.isPositive;
            state.message = payload.message;
            state.messageKey = payload.messageKey;

            // CBSCOMSUB-4696
            state.denyClose = payload.denyClose;
            state.routeNameForMessage = payload.routeNameForMessage;

            // CBSCOMSUB-7247
            state.messageTitle = payload.messageTitle;
            state.spacerHeight = payload.spacerHeight;
            state.showBelowHeader = payload.showBelowHeader;
            state.success = payload.isPositive;
        },

        hide(state) {
            state.isClosing = false;
            state.isOpen = false;
            state.messageKey = '';
            state.routeNameForMessage = null;
            state.spacerHeight = 0;
            state.messageTitle = '';
        },

        isClosing(state) {
            state.isClosing = true;
        },

        parseDictionary(state, payload) {
            state.dictionary = payload.dictionary;
        },
        // CBSCOMSUB-7247 notification below header consolidation
        setSpacerHeight(state, spacerHeight) {
            state.spacerHeight = spacerHeight;
        },

        incrementNumPagesShowedOn(state) {
            state.numPagesShowedOn++;
        },
    },

    actions: {
        open({ commit, dispatch, state }, payload) {
            let notification = {
                isPositive: payload.success,
                message: payload.message,
                messageKey: payload.messageKey,

                // CBSCOMSUB-4696
                denyClose: payload.denyClose,
                routeNameForMessage: payload.routeNameForMessage,

                // CBSCOMSUB-7247
                messageTitle: payload.messageTitle,
                showBelowHeader: payload.showBelowHeader,
            };

            // if notification bar is already open
            // close it nicely
            // then open it nicely
            if (state.isOpen) {
                dispatch('close');

                // and immediately after hide show the new message so it slides down nicely
                setTimeout(() => {
                    commit('show', notification);
                }, state.animationDuration);
            } else {
                // just show the new message
                commit('show', notification);
            }
            /*
            // all positive (blue) messages should auto close
            // while all negative (red) messages should not
            // this insures that
            try {
                payload.__resetAutoClose();
            } catch (e) {
                payload.autoClose = payload.success;
                console.warn(
                    'NotificationBarStore: payload is not a NotificationBarModel object. Cant not reliably override autoClose.'
                );
            }*/
            // auto close after 5 seconds
            if (payload.autoClose) {
                setTimeout(() => {
                    dispatch('close');
                    // this timeout length reflects the animation duration + the time til auto close kicks in
                }, state.animationDuration + state.delayToClose);
            }
        },

        openByUri({ dispatch, state }, uri) {
            if (typeof uri === 'string' && uri !== '') {
                // replace hyphenated route query param with an underscore to match page attributes key format
                const pathKey = uri.replace(/-/g, '_');

                if (
                    typeof state?.dictionary != 'undefined' &&
                    state.dictionary[pathKey] &&
                    state.dictionary[pathKey] !== '' &&
                    !state.skipOpenUri.includes(pathKey)
                ) {
                    const successStatus = !pathKey.includes('error');
                    // show notification
                    let nbm = new NotificationBarModel({
                        success: successStatus,
                        message: state.dictionary[pathKey],
                    });

                    dispatch('open', nbm);
                }
            }
        },

        close({ state, commit }) {
            if (state.isOpen && !state.isClosing) {
                commit('isClosing'); // triggers doCollapse

                setTimeout(() => {
                    commit('hide');
                }, state.animationDuration);
            }
        },

        showOnlyOnXNumPages({ commit, dispatch, state }, maxpages = 1) {
            if (state.isOpen === true) {
                if (state.numPagesShowedOn >= maxpages) {
                    dispatch('close');
                } else {
                    commit('incrementNumPagesShowedOn');
                }
            }
        },

        setSpacerHeight({ commit }, spacerHeight) {
            commit('setSpacerHeight', spacerHeight);
        },

        loadServerData({ state, commit, rootGetters }) {
            return new Promise((resolve, reject) => {
                if (state.dictionary === null) {
                    const pageAttributes = rootGetters.getServerDataByKey('pageAttributes');

                    if (pageAttributes) {
                        commit('parseDictionary', {
                            dictionary: pageAttributes.NotificationBar,
                        });
                    }
                }

                resolve();
            });
        },
    },
};
