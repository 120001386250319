import ForgotPasswordComponent from 'aa/vue/components/ForgotPassword';
import ForgotPasswordDefault from 'aa/vue/models/steps/ForgotPassword';

export default class ForgotPassword extends ForgotPasswordDefault {
    component = ForgotPasswordComponent;
    props = {
        cancelCallback: (component, e) => {
            if (component?.isLoggedIn) {
                window.location.assign('/account/');
            } else {
                component.$router.push({ name: 'SIGNIN' });
            }
        },
    };
}
