<template>
    <ul class="feature-list">
        <li v-if="displayShowtimeIntegrationFirstOption" class="icon-check" v-once>
            <span>{{ showtimeIntegrationFirstOptionText }}</span>
        </li>

        <li
            v-for="(featureDescription, index) in features"
            :key="index"
            :class="getFeatureListClass">
            <span>{{ featureDescription }}</span>
        </li>
        <li
            class="icon-plus"
            v-if="bottomFeatures"
            v-for="(featureDescription, index) in bottomFeatures"
            :key="index">
            <span>{{ featureDescription }}</span>
        </li>
    </ul>
</template>

<script>
    // START TEST PLOCTOPLUS-2035: Simplified Plans Tiles for POPI
    import SimplifiedPlansTilesAbTestPOPI from 'aa/vue/abTestRelated/SimplifiedPlansTilesAbTestPOPI';
    // END TEST PLOCTOPLUS-2035: Simplified Plans Tiles for POPI
    // START TEST PLOCTOPLUS-2036 Pick a Plan: Plan Tiles Above Fold
    import AbTestPlanTilesAboveFoldMixin from 'aa/vue/abTestRelated/AbTestPlanTilesAboveFoldMixin';
    // END TEST PLOCTOPLUS-2036 Pick a Plan: Plan Tiles Above Fold
    import { mapGetters, mapState } from 'vuex';
    export default {
        name: 'FeatureList',

        mixins: [
            // START TEST PLOCTOPLUS-2035: Simplified Plans Tiles for POPI
            SimplifiedPlansTilesAbTestPOPI,
            // END TEST PLOCTOPLUS-2035: Simplified Plans Tiles for POPI

            // START TEST PLOCTOPLUS-2036 Pick a Plan: Plan Tiles Above Fold
            AbTestPlanTilesAboveFoldMixin,
            // END TEST PLOCTOPLUS-2036 Pick a Plan: Plan Tiles Above Fold
        ],
        props: {
            features: {
                type: Array,
                required: true,
            },
            plan: {
                type: Object,
                required: true,
            },
            bottomFeatures: {
                type: Array,
                required: false,
            },
        },
        computed: {
            ...mapGetters({
                featureFlagIsActive: 'featureFlags/isActive',
            }),
            ...mapState('pageAttributes', {
                pageAttributes: (state) => state.attributes,
            }),
            getFeatureListClass() {
                // START TEST PLOCTOPLUS-2035: Simplified Plans Tiles for POPI
                if (this.isSimplifiedPlansPOPI) {
                    return 'icon-check';
                }
                // END TEST PLOCTOPLUS-2035: Simplified Plans Tiles for POPI

                // START TEST PLOCTOPLUS-2036 Pick a Plan: Plan Tiles Above Fold
                if (this.abTestReturnHollow()) {
                    return 'icon-hollow';
                }
                // END TEST PLOCTOPLUS-2036 Pick a Plan: Plan Tiles Above Fold

                if (this.isShowtimeIntegrationEnabled() && this.plan.isLCP) {
                    return 'icon-check';
                }

                if (this.isShowtimeIntegrationEnabled() && this.plan.isCF) {
                    return 'icon-plus';
                }

                return 'icon-default';
            },
            displayShowtimeIntegrationFirstOption() {
                return (
                    this.isShowtimeIntegrationEnabled() &&
                    this.showtimeIntegrationFirstOptionText &&
                    this.plan.isCF &&
                    // START TEST PLOCTOPLUS-2035: Simplified Plans Tiles for POPI
                    !this.isSimplifiedPlansPOPI
                    // END TEST PLOCTOPLUS-2035: Simplified Plans Tiles for POPI
                );
            },
            showtimeIntegrationFirstOptionText() {
                return this.pageAttributes.aa_pap_cf_feature_list_top_item;
            },
        },
        methods: {
            isShowtimeIntegrationEnabled: function () {
                return this.featureFlagIsActive('showtime_integration_enabled');
            },
        },
    };
</script>
