<template>
    <div ref="notificationBelowHeaderWrapper" class="notification-below-header-wrapper">
        <div
            v-show="isOpen && showBelowHeader"
            ref="notificationBelowHeader"
            :class="getHeaderClasses">
            <div class="padded-container" ref="html">
                <h2 class="notification-text" v-if="messageTitle" v-html="messageTitle"></h2>

                <span class="notification-text" v-html="message"></span>
            </div>

            <span class="icon close" @click="doHide"></span>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name: 'NotificationBelowHeader',
        methods: {
            doHide() {
                this.$store.dispatch('notification/close');
            },
            updateSpacerHeight() {
                let notificationElementHeight =
                    this.$refs['notificationBelowHeader'].getBoundingClientRect().height;
                this.$store.dispatch('notification/setSpacerHeight', notificationElementHeight);
            },
            doCollapse() {
                this.$refs['notificationBelowHeader'].style.top = '0px';
            },
        },
        computed: {
            ...mapState('notification', {
                message: (state) => state.message,
                messageTitle: (state) => state.messageTitle,
                isOpen: (state) => state.isOpen,
                isClosing: (state) => state.isClosing,
                showBelowHeader: (state) => state.showBelowHeader,
                success: (state) => state.success,
            }),

            ...mapState('pageAttributes', {
                pageAttributes: (state) => state.attributes,
            }),
            getHeaderClasses() {
                return {
                    'notification-below-header': true,
                    success: this.success,
                    error: !this.success,
                };
            },
        },

        watch: {
            isClosing: function () {
                this.doCollapse();
                window.removeEventListener('resize', this.updateSpacerHeight);
            },
        },

        mounted() {
            window.addEventListener('resize', this.updateSpacerHeight);
        },

        updated() {
            this.updateSpacerHeight();
        },
    };
</script>
